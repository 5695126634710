import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


export const StartState = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });


    const [action, setAction] = useState({
        ...props.state.actions[props.userId][1],
        idPlayer: props.userId
    });


    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez vous l'action " + state.action.name}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                Case départ
            </h2>
            <div style={{margin: '12px 0'}}>
                Vous pouvez gagner 20 or
            </div>
            <Box className={"board-game-build-action"}
                 sx={{
                     width: {xs: "120%", md: "100%"},
                     marginLeft: {xs: "-13%", md: "-3%"}
                 }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    {props.state.actions[props.userId][0].name}
                </div>
                <Button variant="contained"
                        onClick={() => setState(state => ({
                            ...state,
                            action: props.state.actions[props.userId][0],
                            show: true
                        }))}>
                    Choisir
                </Button>
            </Box>
            <div style={{margin: '12px 0'}}>
                Ou vendre des lots de 10 ressources pour 5 points chacun
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    ["wood", "papyrus", "bronze"].map(resource => <div key={resource}
                                                                       style={{
                                                                           flex: 1,
                                                                           display: 'flex',
                                                                           justifyContent: 'center',
                                                                           alignItems: 'center',
                                                                           margin: "20px 0"
                                                                       }}
                    >
                        <Button variant={"contained"} onClick={() => setAction(a => {
                            const resourcesToSell = {...a.resourcesToSell};
                            resourcesToSell[resource.toUpperCase()] -= 1;
                            return {...a, resourcesToSell};
                        })} style={{margin: "0 12px"}} disabled={action.resourcesToSell[resource.toUpperCase()] === 0}>
                            -
                        </Button>
                        {action.resourcesToSell[resource.toUpperCase()]}
                        <img style={{height: '40px', width: 'auto', marginLeft: '12px'}}
                             src={"/public/heliopolis/resource_" + resource + ".png"}/>
                        <Button variant={"contained"} onClick={() => setAction(a => {
                            const resourcesToSell = {...a.resourcesToSell};
                            resourcesToSell[resource.toUpperCase()] += 1;
                            return {...a, resourcesToSell};
                        })} style={{margin: "0 12px"}} disabled={action.resourcesToSell[resource.toUpperCase()] === props.state.players[props.userId][resource]}>
                            +
                        </Button>
                    </div>)
                }
            </div>
            <Button variant="contained" disabled={action.resourcesToSell.WOOD + action.resourcesToSell.PAPYRUS + action.resourcesToSell.BRONZE % 10 === 0}
                    onClick={() => setState(state => ({...state, action, show: true}))}>
                Vendre
            </Button>

            <div style={{height: "75vh"}}>

            </div>
        </div>
    )

};

export default StartState;
