import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";

export default class ActionAddResources extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actions: props.actions[props.userId][0],
            show: false
        };
    }

    componentDidMount() {
    }

    render() {
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={"Confirmez-vous cette action ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        return <div
            style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD",
            }}>
            {modal}
            <div style={{fontSize: "20px", alignItems: "center", display: "flex", marginLeft: "20px"}}>
                Sur quelle carte {this.props.actions[this.props.userId][0].nbResources > 0 ? "ajouter" : "retirer"} des resources ?
            </div>
            <div style={{display: 'inline-flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                {
                    this.props.actions[this.props.userId].map(action =>
                        <div key={action.cardId}
                             style={{height: "100%", display: "flex", flexDirection: 'column', alignItems: 'center', margin: "2vh 12px"}}>
                            <img src={action.cardId ? "/public/terraforming/cards/img_" + action.cardId + ".png" :
                                "/public/terraforming/corpos/corpo_card_" + action.corpoName.lower() + ".png"}
                                 alt={"img " + action.cardId}
                                 style={{width: "35vw", height: "auto"}}>
                            </img>
                            <br/>
                            <Button variant={"contained"}
                                    disabled={!action.ok}
                                    onClick={() => this.setState({action, show: true})}>
                                {action.nbResources > 0 ? "+" : ""}{" " + action.nbResources+ "   "}
                                <img src={"/public/terraforming/icons/resource_" + action.cardResources + ".png"}
                                     style={{height: "20px", width:"auto"}}
                                     alt={action.cardResources}/>
                            </Button>
                        </div>
                    )
                }
            </div>
        </div>
    }
}
