import callApi from "../api/actions";
import buildURLQueryParams from "../utils/buildURLQueryParams";
import {signin} from "../authentication/actions";


export const SET_PLAYER = 'SET_PLAYER';

export function setPlayer(player) {
    return {type: SET_PLAYER, player};
}

export const getPlayer = (user) => {
    return dispatch => {
        let call = '/connectedPlayers/'+user._id;
        return dispatch(
            callApi(call, (player) => dispatch => {
                    dispatch(setPlayer(player));
                }
            )
        );
    };
};

export const postAction = (user, action) => {
    return dispatch => {
        let call = buildURLQueryParams('/useractions', {
            gamekey : user.currentgame.gamekey,
            userId : ""+user._id,
            gameId : user.currentgame.gameId
        });
        let body = {
            ...action
        };
        return dispatch(
            callApi(call,
                () => dispatch => {
                    dispatch(getPlayer(user));
                },
                {
                    method : 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
        );
    }
};

export const postGameAction = (player, action, actionBody) => {
    return dispatch => {
        let {gamekey, gameId} = player.currentgame;
        let call = "/game/"+gamekey+"/"+gameId+"/action";
        let body = {
            action,
            ...actionBody
        };
        return dispatch(
            callApi(call,
                () => dispatch => {
                    dispatch(getPlayer(player));
                },
                {
                    method : 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
        );
    }
};

export const updateGameStatus = (user) => {
    return dispatch => {
        return dispatch(getPlayer(user));
    }
};

export const postStartGame = (user) => {
    return dispatch => {
        let call = "/game/"+user.currentgame.gamekey+"/"+user.currentgame.gameId+"/startgame";
        let body = {};
        return dispatch(
            callApi(
                call,
                () => dispatch => {},
                {
                    method : 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
        )
    }
}
