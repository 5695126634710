import React from "react";

class ConfidentialityPolicy extends React.Component {

    constructor({...props}) {
        super();
    };

    render() {
        return (
            <div style={{padding: "20%", paddingTop: "15px", color: "white"}}>
                <h1>Politique de confidentialité</h1>
                    <div><h2>Introduction</h2><br />
                        Devant le développement des nouveaux outils de communication, il est nécessaire de porter une attention particulière à la protection de la vie privée.
                        C'est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.
                    </div>
                <h2>Collecte des renseignements personnels</h2>
                <div>
                    Nous collectons les renseignements suivants :
                </div>
                <ul>
                    <li>Nom et Prénom</li>
                    <li>Adresse Email</li>
                    <li>Pseudonyme</li>
                </ul>
                <div>
                    Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à
                    l'interactivité établie entre vous et notre site Web.
                    Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux
                    pour réunir des informations vous concernant.
                </div>
                <h2>Formulaires et interactivité:</h2>
                <div>
                    Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :
                </div>
                <ul>
                    <li>Formulaire d'inscription au site Web</li>
                </ul>
                <div>
                    Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :
                </div>
                <ul>
                    <li>Contact</li>
                    <li>Gestion du site Web (présentation, organisation)</li>
                </ul>

                <h2>Droit d'opposition et de retrait</h2>
                <div>
                    Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels.<br />
                    Le droit d'opposition s'entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements
                    personnels soient utilisées à certaines fins mentionnées lors de la collecte.<br />
                </div>
                <div>
                    Le droit de retrait s'entend comme étant la possiblité offerte aux internautes de demander à ce que leurs
                    renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.<br />
                </div>
                <div>
                    Pour pouvoir exercer ces droits, vous pouvez nous contacter via les moyens suivants: <br />
                    Adresse postale : 14 rue Hélène Loiret, 92190 Meudon<br />	Courriel : kevin.bienvenu@battlemythe.net<br />		Section du site web : welcomegames.fr/<br />	</div>
                <h2>Droit d'accès</h2>
                <div>
                    Nous nous engageons à reconnaître un droit d'accès et de rectification aux personnes
                    concernées désireuses de consulter, modifier, voire radier les informations les concernant.<br />

                    L'exercice de ce droit se fera via :<br />
                    Adresse postale : 14 rue Hélène Loiret, 92190 Meudon<br />	Courriel : kevin.bienvenu@battlemythe.net<br />		Section du site web : welcomegames.fr/<br />	</div>
                <h2>Sécurité</h2>
                <div>
                    Les renseignements personnels que nous collectons sont conservés
                    dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.<br />
                    Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :
                </div>
                <ul>
                    <li>Gestion des accès - personne autorisée</li>
                    <li>Gestion des accès - personne concernée</li>
                    <li>Logiciel de surveillance du réseau</li>
                    <li>Identifiant / mot de passe</li>
                    <li>Pare-feu (Firewalls)</li>
                </ul>

                <div>
                    Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d'assurer
                    la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n'offre une sécurité maximale, une part de risque est toujours présente
                    lorsque l'on utilise Internet pour transmettre des renseignements personnels.
                </div>
            </div>
        );
    };

/*

*/

};

export default ConfidentialityPolicy;
