import constants from "../../config/constants";

export const callApiService = (path, options) => {

    const opts = {
        ...options,
        headers: {
            ...options.headers,
            "Access-Control-Request-Headers" : "x-requested-with"
        }
    }
    return fetch(constants.api.basePath + path, opts)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return handleApiError(response);
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
};


const handleApiError = (response) => {
    return Promise.reject(response);
};

export default callApiService;
