import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {getCountryFlagName} from "./utils";

export const ConsumptionPanel = (props) => {

    function getListOfCountriesThatProduceRawMaterial(rawMaterial) {
        return Object.keys(props.world.countries).filter(country => props.world.countries[country].rawMaterial1 === rawMaterial || props.world.countries[country].rawMaterial2 === rawMaterial);
    }

    function isBuying(country) {
        return (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryBuyRawMaterial2[props.country] || 0) > 0;
    }

    return (
        <Box>
            <div>
                Vos commandes :
            </div>
            <div>
                {
                    getListOfCountriesThatProduceRawMaterial(props.neededRawMaterial).map(country => {
                        return <div key={"consumption " + country}
                                    className={(isBuying(country) ? "active " : "") + "consumption-country"}>
                            <div className={"consumption-country-first-row"}>
                                <div style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <img src={"/public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}
                                         alt={country} style={{width: 'auto', height: '30px', margin: '0 2vw'}}/>
                                    {country.substring(0, 3)}.
                                </div>
                                <div style={{flex: 1, display: 'block', textAlign: 'center'}}>
                                    Prix
                                    <br/>
                                    <img style={{height: '16px', width: 'auto'}} src={"/public/anniv/2024/money.png"}/>
                                    {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].rawMaterial1Price : props.world.countries[country].rawMaterial2Price}
                                    {
                                        Math.abs((props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].rawMaterial1Price : props.world.countries[country].rawMaterial2Price) - (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].orderRawMaterial1Price : props.world.countries[country].orderRawMaterial2Price)) > 0.1 &&
                                        <span>
                                         - 
                                    <img style={{height: '16px', width: 'auto'}}
                                         src={"/public/anniv/2024/money_target.png"}/>
                                            {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].orderRawMaterial1Price : props.world.countries[country].orderRawMaterial2Price}
                                        </span>
                                    }
                                </div>
                                <div style={{flex: 1, display: 'block', textAlign: 'center'}}>
                                    Transport
                                    <br/>
                                    <img style={{height: '16px', width: 'auto'}} src={"/public/anniv/2024/money.png"}/>
                                    {props.world.countries[country].transportationPrices[props.country] || 0}
                                </div>
                                <div style={{flex: 1, display: 'block', textAlign: 'center'}}>
                                    Achetée
                                    <br/>
                                    {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryBuyRawMaterial2[props.country] || 0}
                                    /
                                    {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].nbRawMaterialProducted1 || 0 : props.world.countries[country].nbRawMaterialProducted2 || 0}
                                </div>
                            </div>
                            <div className={"consumption-country-second-row"}>
                                {
                                    (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryTargetBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryTargetBuyRawMaterial2[props.country] || 0) !== (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryBuyRawMaterial2[props.country] || 0) && <div>
                                        Qté achetée <img style={{height: '12px', width: 'auto'}} src={"/public/anniv/2024/icon_target.png"}/>
                                        : {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryTargetBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryTargetBuyRawMaterial2[props.country] || 0}
                                        /
                                        {props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].nbRawMaterialProducted1 || 0 : props.world.countries[country].nbRawMaterialProducted2 || 0}
                                    </div>
                                }
                                <div style={{flex: 2, display: 'block', textAlign: 'center'}}>
                                    {
                                        props.editable && <div className="top-line">
                                            {
                                                [-1, 1].map(modification =>
                                                    <button
                                                        disabled={
                                                            (modification < 0 && (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryTargetBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryTargetBuyRawMaterial2[props.country] || 0) <= 0)
                                                            ||
                                                            (modification > 0 && (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryTargetBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryTargetBuyRawMaterial2[props.country] || 0) >= (props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].nbRawMaterialProducted1 : props.world.countries[country].nbRawMaterialProducted2))
                                                        }
                                                        onClick={() => {
                                                            const newValue = props.world.countries[country].rawMaterial1 === props.neededRawMaterial ? props.world.countries[country].countryTargetBuyRawMaterial1[props.country] || 0 : props.world.countries[country].countryTargetBuyRawMaterial2[props.country] || 0;

                                                            props.emitOrderBuy({
                                                                countryBuyerName: props.country,
                                                                countrySellerName: country,
                                                                playerName: props.player,
                                                                rawMaterialName: props.neededRawMaterial,
                                                                quantity: Math.max(0, newValue + modification)
                                                            })
                                                        }}
                                                        key={modification}
                                                        style={{marginRight: "12px"}}
                                                        className={"small-button"}>
                                                        {modification > 0 ? '+' : ''}{modification}
                                                    </button>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </Box>
    )
}
