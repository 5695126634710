import {selectSessionUser} from "../../../../../store/authentication/selectors";
import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import constants from "../../../../../config/constants";
import * as io from "socket.io-client";
import StateIntroMw from "./mainScreens/StateIntroMw";
import StateAuction from "./mainScreens/StateAuction";
import StateBuyResources from "./mainScreens/StateBuyResources";
import StateBuyHouse from "./mainScreens/StateBuyHouse";
import StateConsumption from "./mainScreens/StateConsumption";
import StateRemovePlant from "./mainScreens/StateRemovePlant";
import ConsultCentrale from "./consultScreens/ConsultCentrale";
import ConsultResources from "./consultScreens/ConsultResources";
import ConsultCities from "./consultScreens/ConsultCities";
import ConsultIncome from "./consultScreens/ConsultIncome";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        user: selectSessionUser(state),
    };
};

const mapActionsToDispatch = dispatch => ({});

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps
});


class MegawattHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            state: undefined,
            play: true,
            consult: "centrale",
            checkNewState: false
        };
    }

    socketMegawatt;

    componentDidMount() {
        const {gameId} = this.props.match.params;
        this.socketMegawatt = io.connect(constants.api.socketPath);
        setTimeout(() => {
            this.socketMegawatt.emit("id", {
                type: "PLAYER",
                gameId,
                userId: "" + this.props.user._id,
                username: this.props.user.username,
                color: {r: 0.750, g: 0.75, b: 0.75, a: 1}
            })
        }, 1000);
        let a = this;
        this.socketMegawatt.on('disconnect', function () {
            a.props.history.push("/board-games");
        });
        this.socketMegawatt.on('state', function (data) {
            console.log('new state');
            console.log(data);
            if (data && (data.state !== "INTRO" || !a.state.data)) {
                const newState = {state: data};
                if (a.state.play && (!data.actions || !data.actions[a.props.user._id] || !data.actions[a.props.user._id].length)) {
                    newState.play = false;
                    newState.checkNewState = false;
                } else if (!a.state.play && data.actions && data.actions[a.props.user._id] && data.actions[a.props.user._id].length && !a.state.checkNewState) {
                    newState.play = true;
                    newState.checkNewState = true;
                }
                a.setState(newState);
            }
        });
        this.socketMegawatt.on('state_intro', function (data) {
            console.log('new state intro');
            console.log(data);
            if (!a.state.state || a.state.state.state === "INTRO") {
                a.setState({state: data});
            }
        });
    }

    componentWillUnmount() {
        this.socketMegawatt.close();
    }

    render() {
        let comp = undefined;
        let compConsult = undefined;
        if (!this.state.state) {
            return <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
                color: "#CCC"
            }}>
                Veuillez patienter
            </div>
        }
        const {gameId} = this.props.match.params;
        if (this.state.state) {
            switch (this.state.state.state) {
                case "INTRO":
                    return <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <StateIntroMw
                            state={this.state.state}
                            userId={this.props.user._id}
                            gameId={gameId}
                            username={this.props.user.username}
                            socket={this.socketMegawatt}
                        />
                    </div>;
                case "AUCTION":
                    comp = <StateAuction
                        state={this.state.state}
                        userId={this.props.user._id}
                        gameId={gameId}
                        socket={this.socketMegawatt}
                    >
                    </StateAuction>;
                    break;
                case "BUY_RESOURCES":
                    comp = <StateBuyResources
                        state={this.state.state}
                        userId={this.props.user._id}
                        gameId={gameId}
                        socket={this.socketMegawatt}
                    />;
                    break;
                case "BUY_HOUSE":
                    comp = <StateBuyHouse
                        state={this.state.state}
                        userId={this.props.user._id}
                        gameId={gameId}
                        socket={this.socketMegawatt}
                    />;
                    break;
                case "CONSUMPTION":
                    comp = <StateConsumption
                        state={this.state.state}
                        userId={this.props.user._id}
                        gameId={gameId}
                        socket={this.socketMegawatt}
                    />;
                    break;
                case "REMOVE_PLANT":
                    comp = <StateRemovePlant
                        state={this.state.state}
                        userId={this.props.user._id}
                        gameId={gameId}
                        socket={this.socketMegawatt}
                    />;
                    break;
                default:
                    comp = <div>{JSON.stringify(this.state.state)}</div>;
            }
            switch (this.state.consult) {
                case "centrale":
                    compConsult = <ConsultCentrale
                        futurePlants={this.state.state.futurePlants}
                        availablePlants={this.state.state.availablePlants}/>;
                    break;
                case "resources":
                    compConsult = <ConsultResources
                        phase={this.state.state.phase}
                        newResources={this.state.state.newResources}
                    />;
                    break;
                case "cities":
                    compConsult = <ConsultCities
                        cities={this.state.state.cities[this.props.user._id]}
                    />;
                    break;
                case "income":
                    compConsult = <ConsultIncome
                        income={this.state.state.income}
                    />;
                    break;
            }
        }

        return <div style={{width: "100%", height: "100vh", marginTop: "-1px"}}>
            <div
                style={{
                    width: "100%", height: "12%", display: "flex", justifyContent: "center", alignItems: "center",
                    background: "linear-gradient(0, #DDD 0%, #555 100%)"
                }}>
                <div style={{
                    width: "50%",
                    height: "80%",
                    marginTop: "3px",
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <div style={{
                        flex: 10,
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "5px",
                        color: "#FFF",
                        backgroundColor: this.state.play ? "rgb(91, 192, 222)" : (
                            this.state.state.actions[this.props.user._id] && this.state.state.actions[this.props.user._id].length ?
                                "#4daa70" : "#000"
                        )
                    }} onClick={() => {
                        if (this.state.state.actions[this.props.user._id] && this.state.state.actions[this.props.user._id].length) {
                            this.setState({play: true})
                        }
                    }}>
                        {this.state.state.actions[this.props.user._id] && this.state.state.actions[this.props.user._id].length ? "JOUER" : "EN ATTENTE"}
                    </div>
                    <div style={{flex: 1, height: "100%", justifyContent: "center", alignItems: "center"}}>

                    </div>
                    <div style={{
                        flex: 10,
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "5px",
                        color: "#FFF",
                        backgroundColor: !this.state.play ? "rgb(91, 192, 222)" : "#777"
                    }}
                         onClick={() => this.setState({play: false})}>
                        CONSULTER
                    </div>
                </div>
            </div>
            {
                this.state.play ?
                    <div
                        style={{width: "100%", height: "88%", backgroundColor: "#111", marginTop: "-1px"}}>
                        {
                            comp || JSON.stringify(this.state.state)
                        }
                    </div>
                    : <div
                        style={{width: "100%", height: "88%", backgroundColor: "#111", marginTop: "-1px"}}>
                        <div
                            style={{
                                width: "100%",
                                height: "12%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "linear-gradient(0, #88D 0%, #559 100%)"
                            }}>
                            <div style={{
                                width: "90%",
                                height: "80%",
                                marginTop: "3px",
                                display: "flex",
                                flexDirection: "row"
                            }}>
                                <div style={{
                                    flex: 10,
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    borderRadius: "5px",
                                    color: "#FFF",
                                    backgroundColor: this.state.consult === "centrale" ? "rgb(91, 192, 222)" : "#777"
                                }} onClick={() => {
                                    this.setState({consult: "centrale"})
                                }}>
                                    CENTRALE
                                </div>
                                <div style={{flex: 1, height: "100%", justifyContent: "center", alignItems: "center"}}>

                                </div>
                                <div style={{
                                    flex: 10,
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    borderRadius: "5px",
                                    color: "#FFF",
                                    backgroundColor: this.state.consult === "resources" ? "rgb(91, 192, 222)" : "#777"
                                }} onClick={() => {
                                    this.setState({consult: "resources"})
                                }}>
                                    RESSOURCES
                                </div>
                                <div style={{flex: 1, height: "100%", justifyContent: "center", alignItems: "center"}}>

                                </div>
                                <div style={{
                                    flex: 10,
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    borderRadius: "5px",
                                    color: "#FFF",
                                    backgroundColor: this.state.consult === "cities" ? "rgb(91, 192, 222)" : "#777"
                                }} onClick={() => {
                                    this.setState({consult: "cities"})
                                }}>
                                    VILLES
                                </div>
                                <div style={{flex: 1, height: "100%", justifyContent: "center", alignItems: "center"}}>

                                </div>
                                <div style={{
                                    flex: 10,
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    borderRadius: "5px",
                                    color: "#FFF",
                                    backgroundColor: this.state.consult === "income" ? "rgb(91, 192, 222)" : "#777"
                                }} onClick={() => {
                                    this.setState({consult: "income"})
                                }}>
                                    REVENUS
                                </div>
                            </div>
                        </div>
                        {
                            compConsult
                        }
                    </div>
            }
        </div>
    }
}

export default withRouter(connect(mapStateToProps, mapActionsToDispatch, mergeProps, {pure: false})(MegawattHome))
