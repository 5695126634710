import React from "react";

import "./UltraMythe.css";

export const UltraMythPage = () => {

        let mobile = window.innerWidth < 700;
        let fontSize = (window.innerWidth / (window.innerWidth > 1600 ? 1000 : 700) + (mobile ? window.innerWidth / 1300 : 0)) + "em";
        const imageStyle = {
            width: mobile ? "100%" : "70%",
            padding: mobile ? "0" : "0 15%"
        };
        const imageVertStyle = {
            width: mobile ? "50%" : "20%",
            padding: mobile ? "0 25%" : "0 40%"
        };
        const textStyles = {
            padding: mobile ? "3vh 10%" : "10vh 10%"
        };

        const links = <div className={"button-holders"} style={{marginBottom: mobile ? "5vh" : "5vh"}}>
            <a className={"button-holder"}
               style={{
                   width: mobile ? "40vw" : "20vw",
                   height: mobile ? "15vh" : "35vh",
               }}
               href="https://welcomegames.fr/downloads/WelcomeGames.dmg" download>
                <img src={"../../public/devices/link_apple.png"} alt={"apple store link"}/>
                Macos Launcher
            </a>
            <a className={"button-holder"}
               style={{
                   width: mobile ? "40vw" : "20vw",
                   height: mobile ? "15vh" : "35vh",
               }}
               href="https://welcomegames.fr/downloads/WelcomeGames.msi" download>
                <img src={"../../public/devices/link_windows.png"} alt={"google play link"}/>
                Windows Launcher
            </a>
        </div>;


        return (
            <div style={{color: "#FFF", fontSize}} className={"host"}>
                <div className={"interlude-text"}>
                    <h1>RTS Ultra Myth</h1>
                    <div>
                        <img style={{
                            height: mobile ? "15vh" : "20vh",
                            margin: "1vh 0"
                        }} src="../../public/images/rtsLogo.png"/>
                        <br/>
                        Dive into the Epic Realms of 'RTS Ultra Myth'
                        <br/>
                        <br/>
                        A Lightning-Fast RTS Adventure in a Medieval Fantasy World!
                        Available for thrill-seekers on various platforms, immerse yourself
                        in a world where strategic prowess meets mythical forces.
                        Engage in high-octane battles, micromanage units, and unleash
                        powerful spells in this adrenaline-fueled real-time strategy sensation.
                    </div>
                </div>
                {links}
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/images/pictureCarousel1.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Medieval Fantasy Universe: Step into a visually stunning medieval fantasy universe
                    teeming with mythical creatures, magical landscapes, and epic battles.
                    Explore realms filled with enchanting lore and immerse yourself in an
                    immersive gaming experience.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageVertStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/images/drawingBowman00.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Micromanagement Mastery: Exercise precise control over your units, meticulously commanding
                    their every move. With detailed micromanagement mechanics, strategize and
                    execute tactical maneuvers to gain the upper hand in battle.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/images/pictureCarousel2.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Online Battles: Challenge players worldwide in thrilling online matches.
                    Whether climbing the ranks in competitive ranked matches or indulging
                    in classic matchups, experience the thrill of intense real-time battles
                    against skilled opponents.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageVertStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/images/drawingJackal00.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Fast-Paced RTS Action: Experience the rush of adrenaline in this fast-paced
                    real-time strategy game. Make split-second decisions, adapt to changing scenarios,
                    and outwit opponents in a race against time.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/images/pictureCarousel3.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Experience 'RTS Ultra Myth', the ultimate real-time strategy adventure
                    where the medieval world meets lightning-fast gameplay and online
                    competitiveness. Dominate the battlefield, conquer opponents,
                    and etch your name into the annals of RTS glory!
                    <br/>
                    <br/>
                    {links}
                    <br/>
                    <img style={{
                        height: mobile ? "15vh" : "20vh",
                        margin: "1vh 0"
                    }} src="../../public/images/rtsLogo.png"/>
                </div>
            </div>
        );

    }
;

export default UltraMythPage;
