import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default class ActionCostReduction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: props.actions[props.userId][0],
            show: false
        };
        this.canPayWith = this.canPayWith.bind(this);
        this.canChange = this.canChange.bind(this);
        this.change = this.change.bind(this);
    }

    componentDidMount() {
    }

    canPayWith(variable) {
        for (const action of this.props.actions[this.props.userId]) {
            if (action[variable]) {
                return true;
            }
        }
        return false;
    }

    canChange(variable, change) {
        if (!this.canPayWith(variable)) {
            return false;
        }
        const newAction = {
            heat: this.state.action.heat,
            steel: this.state.action.steel,
            titanium: this.state.action.titanium,
            microbes: this.state.action.microbes,
            flotteur: this.state.action.flotteur
        };
        newAction[variable] = newAction[variable] + change;
        const hash = newAction.heat + 100 * newAction.titanium + 10000 * newAction.steel + 1000000 * newAction.microbes + 100000000 * newAction.flotteur;
        return this.props.actions[this.props.userId].map(a => a.heat + 100 * a.titanium + 10000 * a.steel + 1000000 * a.microbes + 100000000 * a.flotteur).indexOf(hash) > -1;
    }

    change(variable, change) {
        if (this.canChange(variable, change)) {
            const newAction = {...this.state.action};
            newAction[variable] += change;
            this.setState({
                action: this.props.actions[this.props.userId].filter(action => action.heat === newAction.heat &&
                    action.steel === newAction.steel &&
                    action.titanium === newAction.titanium &&
                    action.microbes === newAction.microbes &&
                    action.flotteur === newAction.flotteur
                )[0]
            });
        }
    }

    render() {
        let {action} = this.state;
        let modal = <ConfirmModal
            action={this.state.show ? (this.state.cancel ? {
                cancel: true,
                class: "model.actions.others.ActionCostReduction",
                userId: action.userId
            } : action) : undefined}
            text={this.state.cancel ? "Confirmez-vous l'annulation ?" : "Confirmez-vous le paiement ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;

        let resources = ["steel", "titanium", "heat", "microbes", "flotteur"].filter(r => {
            return this.canChange(r, -1) || this.canChange(r, 1) || this.state.action[r]
        });
        if (resources.length === 1) {
            resources = [" ", ...resources];
        }
        while (resources.length === 2) {
            resources.push(" ");
        }
        return (
            <div style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD"
            }}>
                {modal}
                <h2 style={{width: "100%", textAlign: "center"}}>
                    Comment souhaitez-vous payer ?
                </h2>
                <div style={{
                    width: "100%", alignItems: "center", display: "flex",
                    flexDirection: "row", justifyContent: "center", fontSize: "26px",
                    margin: "3vh 0",
                    fontWeight: "bold"
                }}>
                    {action.megaCredit}
                    <img src={"/public/terraforming/icons/megacredit.png"} alt={"thune"}
                         style={{marginLeft: "8px", width: "auto", height: "100%"}}/>
                </div>
                <div style={{margin: "3vh 0", width: "100%", fontSize: "26px", padding: "auto"}}>
                    {
                        resources
                            .map(variable =>
                                <div key={variable}
                                     style={{
                                         marginRight: "2%",
                                         marginLeft: "2%",
                                         height: "100%",
                                         fontWeight: "bold",
                                         flex: 1,
                                         flexDirection: "row",
                                         display: "flex",
                                         justifyContent: "center",
                                         alignItems: "center"
                                     }}>
                                    {
                                        this.canChange(variable, -1) ?
                                            <div style={{height: '100%', flex: 1}}>
                                                <Button style={{
                                                    minWidth: "unset"
                                                }}
                                                        variant={"outlined"}
                                                        onClick={() => this.change(variable, -1)}>
                                                    <RemoveIcon/>
                                                </Button>
                                            </div> :
                                            <div style={{height: '100%', flex: 1}}>&nbsp;</div>
                                    }
                                    <div style={{
                                        flex: 1, height: "100%",
                                        display: (this.canChange(variable, -1) || this.canChange(variable, 1) || this.state.action[variable]) ? "flex" : "none",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <img src={"/public/terraforming/icons/resource_" + variable + ".png"}
                                             alt={variable}
                                             style={{height: "auto", width: "60%"}}/>
                                    </div>
                                    <div style={{
                                        flex: 1, height: "100%",
                                        display: (this.canChange(variable, -1) || this.canChange(variable, 1) || this.state.action[variable]) ? "flex" : "none",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        {action[variable]}
                                    </div>
                                    {
                                        this.canChange(variable, 1) ?
                                            <div style={{height: '100%', flex: 1}}>
                                                <Button style={{
                                                    minWidth: "unset"
                                                }}
                                                        variant={"outlined"}
                                                        onClick={() => this.change(variable, 1)}>
                                                    <AddIcon/>
                                                </Button>
                                            </div> :
                                            <div style={{height: '100%', flex: 1}}>&nbsp;</div>
                                    }
                                </div>)
                    }
                </div>
                <div style={{
                    width: '100%',
                    marginTop: "5vh",
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around'
                }}>
                    <Button
                        variant={"contained"}
                        onClick={() => this.setState({show: true, cancel: true})}>
                        Annuler
                    </Button>
                    <Button
                        variant={"contained"}
                        onClick={() => this.setState({show: true, cancel: false})}>
                        Valider
                    </Button>
                </div>
            </div>
        )
    }
}
