import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {updateGameStatus} from "../../../store/player/actions";
import {selectIsInGame, selectPlayer} from "../../../store/player/selectors";
import {selectIsLoggedIn, selectSessionUser} from "../../../store/authentication/selectors";
import * as io from "socket.io-client";
import axios from "axios";
import constants from "../../../config/constants";
import useTheme from "@mui/material/styles/useTheme";
import "./BoardGamePage.css";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";


const getProperDate = (score) => {
    let date = "";
    date += (score.day < 10 ? "0" : "") + score.day + "/";
    date += (score.month < 10 ? "0" : "") + (score.month) + "/";
    date += (score.year % 1000);
    return date;
}


export const BoardGamePage = () => {


    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [editingName, setEditingName] = useState(false);
    const [gameIds, setGameIds] = useState({});
    const [ladder, setLadder] = useState([]);

    const player = useSelector(selectPlayer);
    const user = useSelector(selectSessionUser);
    const isInGame = useSelector(selectIsInGame);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    let socketTerraforming;

    function handleConnect(game) {
        if (["terraforming", "megawatt", "throughTheAges", "sevenWonders", "heliopolis"].indexOf(game.gamekey) > -1) {
            history.push("/" + game.gamekey + "/" + game.gameId);
        } else {
            // dispatch(connectGame(game, player));
            history.push("/" + game.gamekey);
        }
    }

    function handleSeePreviousGameDetails(game) {
        let path = "/board-games/previous-game/" + game.gamekey + "/" + game.gameId;
        history.push(path);
    }

    useEffect(() => {
        socketTerraforming = io.connect(constants.api.socketPath, {transports: ['websocket']});
        socketTerraforming.on('games', function (data) {
            setGameIds(data);
        });
        socketTerraforming.connect();
        axios.get(constants.api.basePath + "/ladder/terraformingMars").then(data => {
            setLadder(data.data.results);
        });
        return () => {
            socketTerraforming.close();
            console.log("closing socket");
        }
    }, []);

    useEffect(() => {
        if (user) {
            dispatch(updateGameStatus(user));
        }
    }, [user]);


    let mobile = window.innerWidth < 700;
    const scores = player.games ? player.games : [];
    let gamesExt = [];
    for (let game of Object.keys(gameIds)) {
        for (let gameId of gameIds[game]) {
            gamesExt.push({
                numberPlayer: 1,
                gamekey: game,
                gameId: gameId,
                gameowner: 'Smart TV - ' + gameId.substring(0, 5),
                maxPlayers: 0
            });
        }
    }

    const links = <div className={"button-holders"} style={{marginBottom: mobile ? "5vh" : "5vh"}}>
        <a className={"button-holder"}
           style={{
               width: mobile ? "40vw" : "20vw",
               height: mobile ? "15vh" : "35vh",
               textAlign: "center"
           }}
           href="https://welcomegames.fr/downloads/WelcomeGames.dmg" download>
            <img src={"../../public/devices/link_apple.png"} alt={"apple store link"}/>
            Macos Launcher
        </a>
        <a className={"button-holder"}
           style={{
               width: mobile ? "40vw" : "20vw",
               height: mobile ? "15vh" : "35vh",
               textAlign: "center"
           }}
           href="https://welcomegames.fr/downloads/WelcomeGames.msi" download>
            <img src={"../../public/devices/link_windows.png"} alt={"google play link"}/>
            Windows Launcher
        </a>
    </div>;

    return (
        <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
            <div style={{marginBottom: "3vh"}}>
                <h2>
                    Availables games
                </h2>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="col-xs-4">Game</TableCell>
                                <TableCell className="col-xs-3">Host</TableCell>
                                <TableCell className="col-xs-2"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="bodyAvailablesGames">
                            {
                                gamesExt.filter(game => game.numberPlayer >= 0).length ?
                                    gamesExt.filter(game => game.numberPlayer >= 0)
                                        .map((game, index) => (
                                            <TableRow key={"table-game-" + index}>
                                                <TableCell className="col-xs-4" style={{"verticalAlign": "middle"}}>
                                                    <img
                                                        style={{height: "40px", width: "auto"}}
                                                        src={"../../../public/images/" + game.gamekey + ".png"}/>
                                                </TableCell>
                                                <TableCell className="col-xs-3"
                                                           style={{"verticalAlign": "middle"}}>{game.gameowner}</TableCell>
                                                <TableCell className="col-xs-2" style={{"verticalAlign": "middle"}}>
                                                    {
                                                        isInGame ?
                                                            <Button disabled variant="contained">
                                                                Join
                                                            </Button> :
                                                            <Button variant="contained"
                                                                    onClick={() => handleConnect(game)}>
                                                                Join
                                                            </Button>
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        )) :
                                    <TableRow>
                                        <TableCell colSpan="4" style={{"verticalAlign": "middle"}}>
                                            No game found
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{marginBottom: "3vh"}}>
                <h2>
                    Download Launcher
                </h2>
            </div>
            {links}
            <div style={{marginBottom: '5vh'}}>
                <div>
                    <h2>
                        Terraforming Mars Ladder
                    </h2>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{display: "block"}}>
                                <TableRow style={{width: "100%", display: "flex"}}>
                                    <TableCell style={{width: "40%"}}>Player</TableCell>
                                    <TableCell style={{width: "20%"}}>ELO</TableCell>
                                    <TableCell style={{width: "20%"}}>Rank</TableCell>
                                    <TableCell style={{width: "20%"}}>Games</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="bodyPreviousGames"
                                       style={{
                                           display: "block",
                                           height: "50vh",
                                           overflowX: "hidden",
                                           overflowY: "scroll"
                                       }}>
                                {
                                    ladder.length ?
                                        ladder.map((ladder, index) => (
                                            <TableRow key={ladder._id} style={{width: "100%", display: "flex"}}>
                                                <TableCell className="col-xs-4"
                                                           style={{
                                                               "verticalAlign": "middle",
                                                               width: "40%"
                                                           }}>{ladder.username}</TableCell>
                                                <TableCell className="col-xs-2"
                                                           style={{
                                                               "verticalAlign": "middle",
                                                               width: "20%"
                                                           }}>{Math.floor(ladder.ELO)}</TableCell>
                                                <TableCell className="col-xs-2"
                                                           style={{
                                                               "verticalAlign": "middle",
                                                               width: "20%"
                                                           }}>{index + 1}</TableCell>
                                                <TableCell className="col-xs-2"
                                                           style={{
                                                               "verticalAlign": "middle",
                                                               width: "20%"
                                                           }}>{ladder.nbGame}</TableCell>
                                            </TableRow>
                                        )) :
                                        <TableRow>
                                            <TableCell colSpan="4" style={{"verticalAlign": "middle"}}>
                                                No ladder yet
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div style={{marginBottom: '5vh'}}>
                <div>
                    <h2>
                        Terraforming Mars Statistics
                    </h2>
                </div>
                <div style={{width: "100%", textAlign: "center", margin: "3vh 0"}}>
                    <Button onClick={() => history.push("/board-games/terraforming-mars/stats")} variant="contained">
                        Stats
                    </Button>
                </div>
            </div>
            <div style={{marginBottom: '5vh'}}>
                <div>
                    <h2>
                        My previous games
                    </h2>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{display: "block"}}>
                                <TableRow style={{width: "100%", display: "flex"}}>
                                    <TableCell style={{width: "20%"}}>Game</TableCell>
                                    <TableCell style={{width: "20%"}}>Date</TableCell>
                                    <TableCell style={{width: "20%"}}>Position</TableCell>
                                    <TableCell style={{width: "20%"}}>Score</TableCell>
                                    <TableCell style={{width: "20%"}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody className="bodyPreviousGames"
                                       style={{
                                           display: "block",
                                           height: "50vh",
                                           overflowX: "hidden",
                                           overflowY: "scroll"
                                       }}>
                                {
                                    scores.length ?
                                        scores.sort((a, b) => a.year * 10000 + a.month * 100 + a.day < b.year * 10000 + b.month * 100 + b.day ? 1 : -1)
                                            .map((score, index) => (
                                                <TableRow
                                                    style={{width: "100%", display: "flex"}}
                                                    to={"/previousGame/" + score.gamekey + "/" + score.gameId}
                                                    key={"table-game-" + index}>
                                                    <TableCell className="col-xs-4" style={{"width": "20%", "verticalAlign": "middle"}}>
                                                        <img
                                                            style={{height: "40px", width: "auto"}}
                                                            src={"../../../public/images/" + score.gamekey + ".png"}/>
                                                    </TableCell>
                                                    <TableCell style={{width: "20%", "verticalAlign": "middle"}}>{getProperDate(score)}</TableCell>
                                                    <TableCell style={{width: "20%", "verticalAlign": "middle"}}>{score.position + "/" + score.nbPlayers}</TableCell>
                                                    <TableCell style={{width: "20%", "verticalAlign": "middle"}}>{score.score}</TableCell>
                                                    <TableCell style={{width: "20%", "verticalAlign": "middle"}}>
                                                        <Button variant="contained" onClick={() => handleSeePreviousGameDetails(score)}>
                                                            Details
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                        <TableRow>
                                            <TableCell colSpan="4" style={{"verticalAlign": "middle"}}>
                                                No previous game recorded
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Box>
    )
};


export default BoardGamePage;
