import React from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";

export default class StateConsumption extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            action: false
        };
        this.getPlayer = this.getPlayer.bind(this);
        this.getActions = this.getActions.bind(this);
        this.getCentrales = this.getCentrales.bind(this);
        this.generateComponentForCentrale = this.generateComponentForCentrale.bind(this);
        this.getRc = this.getRc.bind(this);
        this.selectRc = this.selectRc.bind(this);
    }


    componentDidMount() {
        this.setState({action: this.getActions()[0]})
    }

    getPlayer() {
        return this.props.state.networkPlayers.filter(np => np.userId === this.props.userId)[0];
    }

    getActions() {
        return this.props.state.actions[this.props.userId];
    }

    getCentrales() {
        return Object.keys(this.getActions()[0].detailConsumptions).filter(kdc =>
            this.getActions()[0].detailConsumptions[kdc].housePowered === 0 ||
            Object.keys(this.getActions()[0].detailConsumptions[kdc].resources).length
        );
    }

    getActionNbProduction(action){
        if (!action) {
            return 0;
        }
        let sum = 0;
        for (let dc of Object.values(action.detailConsumptions)) {
            sum += dc.housePowered;
        }
        return sum
    }

    getModalText(action) {
        let sum = this.getActionNbProduction(action);
        if (sum === 0) {
            return "Etes-vous sûr de ne vouloir alimenter aucune maison ? ";
        } else if (sum === 1) {
            return "Etes-vous sûr de vouloir alimenter " + sum + " maison ? ";
        } else {
            return "Etes-vous sûr de vouloir alimenter " + sum + " maisons ? ";
        }
    }

    hashRc(rC){
        return (rC.COAL || 0) * 1000 + (rC.OIL || 0) * 100 + (rC.WASTE || 0) * 10 + (rC.URANIUM || 0) * 1;
    }

    getRc(rC, centrale){
        if(!this.state.action){
            return {}
        }
        return this.getActions().filter(a => {
            for(let dC of Object.values(a.detailConsumptions)){
                if("" + dC.powerPlantId === "" + centrale){
                    if(this.hashRc(dC.resources) !== this.hashRc(rC)){
                        return false;
                    }
                } else {
                    if(this.hashRc(dC.resources) !== this.hashRc(this.state.action.detailConsumptions["" + dC.powerPlantId].resources)){
                        return false;
                    }
                }
            }
            return true;
        })[0];
    }

    selectRc(rC, centrale){
        this.setState({action: this.getRc(rC, centrale)});
    }

    generateComponentForCentrale(centrale) {
        let resourcesChoice = [];
        for (let action of this.getActions()) {
            let seen = false;
            let r = action.detailConsumptions["" + centrale].resources;
            for (let rC of resourcesChoice) {
                if (this.hashRc(rC) === this.hashRc(r)) {
                    seen = true;
                    break;
                }
            }
            if (!seen) {
                resourcesChoice.push({...r});
            }
        }
        let aCR = this.state.action ? this.state.action.detailConsumptions["" + centrale].resources : {};
        return <div style={{
            width: "100%",
            paddingTop: "25px",
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {
                resourcesChoice.map(rC => <div
                    onClick={() => this.getRc(rC, centrale) ? this.selectRc(rC, centrale) : undefined}
                    key={this.hashRc(rC)}
                    style={{
                        borderRadius: "5px",
                        width: "60%",
                        maxWidth: "200px",
                        backgroundColor: (this.hashRc(rC) === this.hashRc(aCR)) ? "#5bc0de" : (this.getRc(rC, centrale) ? "#777" : "#222"),
                        color: "white",
                        display: "flex",
                        marginBottom: "7px",
                        height: "60px",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    {[...Array(rC.COAL || 0).keys()].map(
                        k => <img src={"../../../../public/megawatt/images/coal.png"}/>
                    )}
                    {[...Array(rC.OIL || 0).keys()].map(
                        k => <img src={"../../../../public/megawatt/images/oil.png"}/>
                    )}
                    {[...Array(rC.WASTE || 0).keys()].map(
                        k => <img src={"../../../../public/megawatt/images/waste.png"}/>
                    )}
                    {[...Array(rC.URANIUM || 0).keys()].map(
                        k => <img src={"../../../../public/megawatt/images/uranium.png"}/>
                    )}
                    {Object.keys(rC).length === 0 ?
                        "NE PAS UTILISER"
                        : undefined}
                </div>)
            }
        </div>;
    }

    render() {
        let modal = ConfirmModal(this.state.show ? this.state.action : undefined,
            this.getModalText(this.state.action),
            this.props.socket, () => this.setState({show: false}));
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            {modal}
            <div style={{
                width: "100%",
                paddingTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <div style={{
                    marginBottom: "20px",
                    color: "white",
                    fontSize: "20px"
                }}>
                    Choisissez comment consommer
                </div>
                {
                    this.getCentrales().map(centrale =>
                        <div
                            key={centrale}
                            style={{
                                width: "70%",
                                marginLeft: "15%",
                                marginTop: "25px",
                                display: "flex",
                                backgroundColor: "gray",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "1px solid gray",
                                marginBottom: "25px",
                                alignItems: "center"
                            }}>
                            <img
                                style={{
                                    width: "80%",
                                    maxWidth: "220px",
                                    height: "auto"
                                }}
                                alt={"centrale " + centrale}
                                src={"../../../../public/megawatt/centrales/smalls/centrale_small_" + centrale + ".png"}
                            />
                            {this.generateComponentForCentrale(centrale)}
                        </div>
                    )
                }
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "70px"}}>
                    <div onClick={() => this.setState({
                        show: true
                    })}
                         style={{
                             backgroundColor: "#5bc0de",
                             color: "white",
                             display: "flex",
                             fontSize: "23px",
                             justifyContent: "center",
                             alignItems: "center",
                             borderRadius: "5px",
                             height: "100%",
                             width: "60%"
                         }}>
                        Produire {this.getActionNbProduction(this.state.action)} d'énergie
                    </div>
                </div>
            </div>
        </div>
    }
}
