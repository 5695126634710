import React from "react";
import Box from "@mui/material/Box";

export default class ObjectiveScreenComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objOrRec: "obj",
            obj: undefined,
            rec: undefined
        }
    }

    componentDidMount() {
        this.setState({
            obj: Object.keys(this.props.state.objectivesStatus)[0],
            rec: Object.keys(this.props.state.recompensesStatus)[0]
        });
    }


    render() {

        return <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
            <div style={{
                width: "50%", marginLeft: "25%", borderRadius: "5px",
                display: "flex", flexDirection: "row",
                height: "50px", color: "white", marginBottom: "40px"
            }}>
                <div
                    onClick={() => {
                        this.setState({objOrRec: "obj"});
                    }}
                    style={{
                        flex: 1, borderRadius: "5px 0 0 5px",
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        backgroundColor: this.state.objOrRec === "obj" ? "#1f70ea" : "#10346f"
                    }}>
                    OBJ
                </div>
                <div
                    onClick={() => {
                        this.setState({objOrRec: "rec"});
                    }}
                    style={{
                        flex: 1, borderRadius: "0 5px 5px 0",
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        backgroundColor: this.state.objOrRec === "rec" ? "#1f70ea" : "#10346f"
                    }}>
                    REC
                </div>
            </div>
            {
                this.state.objOrRec === "obj" ?
                    Object.keys(this.props.state.objectivesStatus).map(obj => {
                        return <div key={obj}>
                            <div
                                onClick={() => this.setState({obj})}
                                style={{
                                    width: "120%",
                                    height: "80px",
                                    marginLeft: "-10%",
                                    borderRadius: "5px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    color: "white",
                                    backgroundColor: this.state.obj === obj ? "#1f70ea" : "#10346f"
                                }}>
                                {obj.toUpperCase()}
                                <img alt="obj" style={{height: "80%", width: "auto", marginLeft: "12px"}}
                                     src={"/public/terraforming/icons/objective_" + obj.toLowerCase() + ".png"}/>
                                {this.props.state.objectivesStatus[obj] ? "PRIS" : "OUVERT"}
                            </div>
                            {
                                this.state.obj === obj ?
                                    <div style={{
                                        marginBottom: "20px",
                                        width: "66%",
                                        color: "white",
                                        marginLeft: "33%",
                                        fontSize: "20px",
                                    }}>
                                        {
                                            Object.values(this.props.state.players).map(p => <div key={p.userId}>
                                                {p.username}
                                                &nbsp;-&nbsp;
                                                {this.props.state.objectivesPlayerNumbers[this.state.obj][p.userId].value}
                                            </div>)
                                        }
                                    </div>
                                    : undefined
                            }
                        </div>
                    })
                    : undefined
            }
            {
                this.state.objOrRec === "rec" ?
                    Object.keys(this.props.state.recompensesStatus).map(rec => {
                        return <div key={rec}>
                            <div
                                onClick={() => this.setState({rec})}
                                style={{
                                    width: "120%",
                                    height: "80px",
                                    marginLeft: "-10%",
                                    borderRadius: "5px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    color: "white",
                                    backgroundColor: this.state.rec === rec ? "#1f70ea" : "#10346f"
                                }}>
                                {rec.toUpperCase()}
                                <img alt="obj" style={{height: "80%", width: "auto", marginLeft: "12px"}}
                                     src={"/public/terraforming/icons/recompense_" + rec.toLowerCase() + ".png"}/>
                                {this.props.state.recompensesStatus[rec] ? "OUVERT" : "DISPO"}
                            </div>
                            {
                                this.state.rec === rec ?
                                    <div style={{
                                        marginBottom: "20px",
                                        width: "100%",
                                        color: "white",
                                        marginLeft: "33%",
                                        fontSize: "20px",
                                    }}>
                                        {
                                            Object.values(this.props.state.players).map(p => <div key={p.userId}>
                                                {p.username}
                                                &nbsp;-&nbsp;
                                                {this.props.state.recompensesPlayerNumbers[this.state.rec][p.userId].value}
                                            </div>)
                                        }
                                    </div>
                                    : undefined
                            }
                        </div>
                    })
                    : undefined
            }
        </Box>
    }

}
