import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {RawMaterialBlock} from "./RawMaterialBlock";
import {ConsumptionPanel} from "./ConsumptionPanel";
import {getCountryFlagName} from "./utils";
import {CountryInvestPage} from "./CountryInvestPage";

export const ContinentHomePage = (props) => {

    const getPartyColor = (party) => {
        switch (party) {
            case "COMMUNISM":
                return "#ff0000";
            case "SOCIALISM":
                return "#ff7777";
            case "LIBERALISM":
                return "#7700ff";
            case "CONSERVATISM":
                return "#0077ff";
            default:
                return "#000077";
        }
    }

    const [selectedCountry, setSelectedCountry] = useState(undefined);

    const getCountriesFromContinent = () => {
        return Object.keys(props.world.countries).filter(country => props.world.countries[country].continentName === props.continent);
    }

    return (
        <div>
            {
                !selectedCountry && <Box>
                    <h2>
                        Statistiques
                    </h2>
                    <div className="statistics-box">
                        <div className="statistics-box-item">
                            <span>Niveau d'infrastructure</span>
                            <span>{props.world.continents[props.continent].infrastuctureLevel}</span>
                        </div>
                        <div className="statistics-box-item">
                            <span>Argent Investi</span>
                            <span>{props.world.continents[props.continent].accumulatedInfrastructureMoney}</span>
                        </div>
                        <div style={{height: '12px', width: '12px'}}>

                        </div>
                        <div className="statistics-box-item">
                            <span>Niveau de population</span>
                            <span>{props.world.continents[props.continent].populationLevel}</span>
                        </div>
                        <div className="statistics-box-item">
                            <span>Argent Investi</span>
                            <span>{props.world.continents[props.continent].accumulatedPopulationMoney}</span>
                        </div>
                        <div style={{height: '12px', width: '12px'}}>

                        </div>
                        <div>
                            Parti au pouvoir
                        </div>
                        <div className="party-box" style={{backgroundColor: getPartyColor(props.world.continents[props.continent].politicalLeaderParty)}}>
                            {props.world.continents[props.continent].politicalLeaderParty}
                        </div>
                    </div>
                    <div>
                        Liste des pays
                    </div>
                    <div>
                        {
                            getCountriesFromContinent().map(country => {
                                return <div onClick={() => setSelectedCountry(country)}
                                            key={country} className="country-button">
                                    <img style={{height: '90%', width: 'auto', marginRight: '24px'}}
                                         src={"/public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}/>
                                    {country}
                                </div>
                            })
                        }
                    </div>
                </Box>
            }
            {
                selectedCountry && <Box>
                    <div className="top-line" style={{marginTop: '24px'}}>
                        <button className={"big-button"} onClick={() => setSelectedCountry(undefined)}>
                            Retour à l'{props.continent}
                        </button>
                        <h2>
                            <img
                                style={{height: '34px', width: 'auto', marginRight: '24px'}}
                                src={"/public/anniv/2024/flags/" + getCountryFlagName(selectedCountry) + ".png"}/>
                            Pays : {selectedCountry}
                        </h2>
                    </div>
                    <CountryInvestPage
                        editable={props.world.countries[selectedCountry].playerName === props.player}
                        world={props.world}
                        player={props.player}
                        emitOrderBuy={props.emitOrderBuy}
                        emitOrderCountry={props.emitOrderCountry}
                        country={selectedCountry}/>
                </Box>
            }
        </div>

    );
}
