import React, {useState} from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


export const RemoveGoldChoice = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        action: false,
        currentAction: false
    });

    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];


    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Confirmez-vous votre choix de défausser " + state.action.gold + " pièce" + (state.action.gold > 1 ? 's' : '') + " d'or et de prendre " + state.action.debt + " jeton" + (state.action.debt > 1 ? 's' : '') + ' dette ?'}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                Perte d'or
            </h2>
            <div>
                On vous demande de choisir entre défausser de l'or ou vous endetter !
            </div>
            <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                {
                    actions.map((a, i) => <Box className={"board-game-build-action"}
                                               sx={{
                                                   width: {xs: "120%", md: "100%"},
                                                   marginLeft: {xs: "-13%", md: "-3%"}
                                               }}
                                               key={i}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}>
                            <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                Payer
                            </div>
                            <div className="board-game-build-action-container">
                                <div style={{
                                    display: "flex", height: '90%', width: '40px',
                                    margin: '5% 0 5% 18px',
                                    padding: '0 12px',
                                    flexDirection: "column",
                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                    borderRadius: '12px',
                                    justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <div style={{
                                        flex: 1,
                                        width: '100%',
                                        height: '60%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {a.gold}&nbsp;<img src={"../../../public/7wonders/images/piece.png"}
                                                            style={{width: "80%", height: "auto"}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="board-game-build-action-container">
                                <div style={{
                                    display: "flex", height: '90%', width: '40px',
                                    margin: '5% 0 5% 18px',
                                    padding: '0 12px',
                                    flexDirection: "column",
                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                    borderRadius: '12px',
                                    justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <div style={{
                                        flex: 1,
                                        width: '100%',
                                        height: '60%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {a.debt}&nbsp;<img src={"../../../public/7wonders/images/debt.png"}
                                                           style={{width: "80%", height: "auto"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button variant="contained" disabled={!a.ok}
                                onClick={() => setState(state => ({...state, action: a, show: true}))}>
                            Choisir
                        </Button>
                    </Box>)
                }
            </div>

            <div style={{height: "75vh"}}>

            </div>
        </div>
    )

};

export default RemoveGoldChoice;
