import {
    SET_NON_READY_PLAYERS,
    ADD_PLAYERS,
    SET_GAME,
    RESET_ACTIONS,
    ADD_PLAYER_ACTION, UNSET_GAME
} from "./actions";

export default (state = {players : [], nonReadyPlayers : [], actions : {}}, action = {}) => {
    switch (action.type) {
        case SET_NON_READY_PLAYERS:
            return {
                ...state,
                nonReadyPlayers: action.nonReadyPlayers
            };
        case ADD_PLAYERS:
            let players = [...(state.players)];
            action.players.forEach(
                player => {
                    let index = players.map(p => p.userId).indexOf(player.userId);
                    if(index === -1){
                        players = [...players, player];
                    } else {
                        players[index] = player;
                    }
                }
            );
            return {
                ...state,
                players
            };
        case UNSET_GAME:
            return {players : [], nonReadyPlayers : [], actions : {}};
        case SET_GAME:
            return {
                ...state,
                game : action.game
            };
        case ADD_PLAYER_ACTION:
            let actions = {
                ...(state.actions)
            };
            actions[action.userId] = action.action;
            return {
                ...state,
                actions
            };
        case RESET_ACTIONS:
            return {
                ...state,
                actions : {}
            };
        default:
            return state;
    }
};
