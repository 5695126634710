import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

export const RawMaterialBlock = (props) => {

    function emitOrderRawMaterialPrice(modification) {
        const newPrice = Math.max(0, props.orderRawMaterialPrice + modification);
        console.log("emitting order country");
        props.emitOrderCountry(newPrice);
        props.setNewPrice(newPrice);
    }

    return (
        <div style={{borderRadius: '12px', boxSizing: 'border-box', width: '100%', padding: '2vh', backgroundColor: 'rgba(0,0,0,0.5)'}}>
            <div style={{marginBottom: '2vh'}}>
                <div style={{fontSize: '18px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <img style={{height: '30px', width: 'auto', marginRight: '8px'}}
                         src={"/public/anniv/2024/money.png"}/>
                    Prix actuel du {props.rawMaterial} : {props.rawMaterialPrice}
                </div>
                <br/>
                {
                    Math.abs(props.rawMaterialPrice - props.orderRawMaterialPrice) > 0.1 &&
                    <div style={{fontSize: '18px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <img style={{height: '30px', width: 'auto', marginRight: '8px'}}
                             src={"/public/anniv/2024/money_target.png"}/>
                        Prix cible : {props.orderRawMaterialPrice}
                    </div>
                }
            </div>
            {
                props.editable && <div className={"production-order-bar"}>
                    {
                        [-5, -1, 1, 5].map(modification =>
                            <button
                                disabled={modification < 0 && props.orderRawMaterialPrice <= 0}
                                onClick={() => emitOrderRawMaterialPrice(modification)}
                                key={modification}
                                className={"small-button"}>
                                {modification > 0 ? '+' : ''}{modification}
                            </button>
                        )
                    }
                </div>
            }
            {
                Math.abs(props.rawMaterialPrice - props.orderRawMaterialPrice) > 0.1 && <div>
                    Le prix atteindra la cible dans {Math.abs(props.orderRawMaterialPrice - props.rawMaterialPrice) / props.world.constants.deltaRawMaterialPrice} min
                </div>
            }
        </div>
    )

}
