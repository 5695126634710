import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router";
import axios from "axios";
import constants from "../../../config/constants";
import useTheme from "@mui/material/styles/useTheme";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions/CardActions";


export const ChangePassword = () => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [userId, setUserId] = useState("");
    const [verifyToken, setVerifyToken] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [response, setResponse] = useState(false);
    const [error, setError] = useState(false);
    const mobile = window.innerWidth < 700;

    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const setters = {
        password: setPassword,
        confirmPassword: setConfirmPassword
    };

    useEffect(() => {
        let params = {};
        location.search.substring(1).split("&").forEach(str => {
            params[str.split("=")[0]] = str.split("=")[1]
        });
        if (!params.userId || !params.verifyToken) {
            history.push("/");
        }
        setUserId(params.userId);
        setVerifyToken(params.verifyToken);
    }, []);


    function handleChange(event) {
        const {name, value} = event.target;
        setters[name](value);
        setError(undefined);
    }

    function handleChangePassword(event) {
        event.preventDefault();
        setSubmitted(true);
        if (password && password === confirmPassword) {
            axios.patch(constants.api.basePath + "/userinfo/" + userId, {
                password, tempPassword: verifyToken
            }).then(
                response => {
                    setResponse(response.data);
                }
            ).catch(error => {
                if (error.response) {
                    if (error.response.data.err === "UserNotFound") {
                        history.push("/");
                    }
                    setError(error.response.data.err);
                }
            });
        }
    }

    let passwordError = undefined;
    let confirmPasswordError = undefined;
    if (submitted) {
        if (!password) {
            passwordError = "Password is required";
        }
        if (password !== confirmPassword) {
            confirmPasswordError = "Passwords don't match";
        }
    }
    return (
        <div style={{marginBottom: "30vh"}}>
            {
                submitted && response ?
                    <div style={{}}>
                        <Card style={{
                            margin: "2vh",
                        }}>
                            <CardContent>
                                Your password has been successfully been changed
                            </CardContent>
                            <CardActions>
                                <Button size="small" href="/login" variant="contained">
                                    Login
                                </Button>
                            </CardActions>
                        </Card>
                        <div style={{
                            width: "100%",
                            height: "90vh"
                        }}/>
                    </div> :
                    <Box sx={{width: {xs: "80%", md: "500px"}, margin: "auto"}}>
                        <div className={"auth-form"}>
                            <h2>Change your password</h2>
                            <div style={{fontSize: 15, margin: "15px", textAlign: "center"}}>
                                You forgot your password, it happens to anyone. <br/>
                                Fill in the form below to change it right away.
                            </div>
                            <form name="form">
                                <div className={'form-group' + (passwordError ? ' has-error' : '')}>
                                    <label htmlFor="password">Password</label>
                                    <TextField type="password" name="password"
                                               hiddenLabel
                                               size="small"
                                               style={mobile ? {width: "100%"} : {}}
                                               variant="outlined" value={password}
                                               onChange={handleChange}/>
                                </div>
                                <div className={'form-group' + (confirmPasswordError ? ' has-error' : '')}>
                                    <label htmlFor="confirmPassword">Confirm password</label>
                                    <TextField type="password" name="confirmPassword"
                                               hiddenLabel
                                               size="small"
                                               style={mobile ? {width: "100%"} : {}}
                                               variant="outlined" value={confirmPassword}
                                               onChange={handleChange}/>
                                </div>
                                <Box className="error-holder" style={{color: theme.palette.error.main}}>
                                    {
                                        passwordError && <div className="help-block">{passwordError}</div>
                                    }
                                    {
                                        confirmPasswordError &&
                                        <div className="help-block">{confirmPasswordError}</div>
                                    }
                                </Box>
                                <div style={{textAlign: "center", marginTop: "3vh"}}>
                                    <Button
                                        variant="contained"
                                        disabled={passwordError || confirmPasswordError}
                                        onClick={handleChangePassword}>
                                        Confirm
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Box>
            }
        </div>
    );
}

export default ChangePassword;
