import callApi from "../api/actions";
import buildURLQueryParams from "../utils/buildURLQueryParams";

export const SET_NON_READY_PLAYERS = 'SET_NON_READY_PLAYERS';
export const ADD_PLAYERS = 'ADD_PLAYERS';
export const SET_GAME = 'SET_GAME';
export const UNSET_GAME = 'UNSET_GAME';
export const ADD_PLAYER_ACTION = 'ADD_PLAYER_ACTION';
export const RESET_ACTIONS = 'RESET_ACTIONS';

export function setNonReadyPlayers(nonReadyPlayers) {
    return {type: SET_NON_READY_PLAYERS, nonReadyPlayers};
}
export function addPlayers(players) {
    return {type: ADD_PLAYERS, players};
}
export function setGame(game){
    return {type: SET_GAME, game};
}
export function unsetGame(){
    return {type: UNSET_GAME};
}
export function addPlayerAction(userId, action){
    return {type: ADD_PLAYER_ACTION, userId, action}
}
export function resetActions(){
    return {type: RESET_ACTIONS};
}

export const getPlayers = (gamekey, gameId) => {
    return dispatch => {
        let call = buildURLQueryParams('/connectedPlayers', {gamekey, gameId});
        return dispatch(
            callApi(call, (players) => dispatch => {
                    dispatch(addPlayers(players));
                }
            )
        );
    };
};


export const getGame = (gameId) => {
    return dispatch => {
        let call = buildURLQueryParams('/gameslist/'+gameId);
        return dispatch(
            callApi(call, (game) => dispatch => {
                    dispatch(setGame(game));
                }, {},
                (game) => dispatch => {dispatch(unsetGame())}
            )
        );
    };
};

export const updateGame = (gameId) => {
    return dispatch => {
        let call = "/game/sevenWonders/"+gameId+'/update';
        return dispatch(
            callApi(call, () => dispatch => {}, {
                method : 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({})
            })
        )
    }
};

export const handleEndAge = (gameId) => {
    console.log("handleEndOfAge");
    return dispatch => {
        let call = "/game/sevenWonders/"+gameId+'/endage';
        return dispatch(
            callApi(call, () => dispatch => {}, {
                method : 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({})
            })
        )
    }
};
