import React from "react";
import {Modal} from "react-bootstrap";
import Button from "@mui/material/Button";
import ConfirmModal from "../utils/Modal";

export default class Others extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "STANDARD_PROJECT",
            action: undefined,
            objOrRec: "obj",
            name: ""
        };
    }

    render() {
        let modal = <ConfirmModal
            action={this.state.action}
            text={"Confirmez-vous ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({action: false})}
            open={!!this.state.action}
        />;

        return <div style={{
            margin: "0", width: "100%", height: "100%"
        }}>
            {modal}
            <div style={{
                width: "50%", marginLeft: "25%", borderRadius: "5px",
                display: "flex", flexDirection: "row",
                height: "50px", color: "white", marginBottom: "40px"
            }}>
                <div
                    onClick={() => {
                        this.setState({objOrRec: "obj"});
                    }}
                    style={{
                        flex: 1, borderRadius: "5px 0 0 5px",
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        backgroundColor: this.state.objOrRec === "obj" ? "#1f70ea" : "#10346f"
                    }}>
                    OBJ
                </div>
                <div
                    onClick={() => {
                        this.setState({objOrRec: "rec"});
                    }}
                    style={{
                        flex: 1, borderRadius: "0 5px 5px 0",
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        backgroundColor: this.state.objOrRec === "rec" ? "#1f70ea" : "#10346f"
                    }}>
                    REC
                </div>
            </div>
            {
                this.state.objOrRec === "obj" ?
                    <div style={{
                        flex: 3,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        {
                            this.props.actions.filter(action => action.class === "model.actions.main.ActionObjective").map(action =>
                                <div key={action.objectives} style={{
                                    flex: 1,
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <div style={{
                                        margin: "2px",
                                        paddingLeft: "2px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        width: "98%",
                                        height: "85%",
                                        display: "flex",
                                        marginBottom: "2vh",
                                        flexDirection: "row",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        borderRadius: "5px",
                                        color: action.ok ? '#fff': '#777',
                                        backgroundColor: "#1f2136"
                                    }}>
                                        <div style={{
                                            flex: 1,
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <img style={{width: "100%", height: "auto", flex: 1}}
                                                 alt="icon"
                                                 src={"/public/terraforming/icons/objective_" + action.objectives.toLowerCase() + ".png"}/>
                                        </div>
                                        <div style={{flex: 3, textAlign: "left", display: "block", alignItems: "center"}}>
                                            {action.objectives}<br/>
                                            <div style={{fontSize: "12px", fontWeight: '100'}}>{action.description}</div>
                                        </div>
                                        <div style={{flex: 2, textAlign: "center", display: "flex", alignItems: "center"}}>
                                            <Button variant={"contained"}
                                                    disabled={!action.ok}
                                                    onClick={() => this.props.socket.emit('action', action)}>
                                                Jouer
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>:
                    <div style={{
                        flex: 3,
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        {
                            this.props.actions.filter(action => action.class === "model.actions.main.ActionRecompense").map(action =>
                                <div key={action.recompenses} style={{
                                    flex: 1,
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <div style={{
                                        margin: "2px",
                                        paddingLeft: "2px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        marginBottom: "2vh",
                                        width: "98%",
                                        height: "85%",
                                        color: action.ok ? '#fff': '#777',
                                        display: "flex",
                                        flexDirection: "row",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        borderRadius: "5px",
                                        backgroundColor: "#1f2136"
                                    }}>
                                        <div style={{
                                            flex: 1,
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <img style={{width: "100%", height: "auto", flex: 1}}
                                                 alt="icon"
                                                 src={"/public/terraforming/icons/recompense_" + action.recompenses.toLowerCase() + ".png"}/>
                                        </div>
                                        <div style={{flex: 3, textAlign: "left", display: "block", alignItems: "center"}}>
                                            {action.recompenses}<br/>
                                            <div style={{fontSize: "12px"}}>{action.description}</div>
                                        </div>
                                        <div style={{flex: 2, textAlign: "center", display: "flex", alignItems: "center"}}>
                                            <Button variant={"contained"}
                                                    disabled={!action.ok}
                                                    onClick={() => this.props.socket.emit('action', action)}>
                                                Ouvrir
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
            }
        </div>
    }
}
