import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../store/authentication/actions";
import {useHistory} from "react-router";
import {selectIsLoggedIn} from "../../../store/authentication/selectors";


export const LogoutPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    dispatch(logout());
    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(
        () => {
            if (!isLoggedIn) {
                history.push("/");
            }
        }
    );

    return (
        <div>Loging out</div>
    )
};

export default LogoutPage;
