import React from "react";

export default class ConsultResources extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }

    render() {
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <h4>Ressources ajoutées à la fin de chaque tour</h4>
            <div style={{
                width: "80%",
                height: "50vh",
                marginLeft: "10%",
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                marginTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <div style={{
                    flex: 1,
                    display: "flex",
                    backgroundColor: "black",
                    flexDirection: "column",
                    height: "100%"
                }}>
                    <div style={{
                        flex: 1,
                        border: "1px solid gray",
                        display: "flex",
                        fontWieght: "bold",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        AGE
                    </div>
                    <div style={{
                        flex: 1,
                        border: "1px solid gray",
                        display: "flex",
                        backgroundColor: this.props.phase === 1 ? "#444" : "black",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        I
                    </div>
                    <div style={{
                        flex: 1,
                        border: "1px solid gray",
                        display: "flex",
                        backgroundColor: this.props.phase === 2 ? "#444" : "black",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        II
                    </div>
                    <div style={{
                        flex: 1,
                        border: "1px solid gray",
                        display: "flex",
                        backgroundColor: this.props.phase === 3 ? "#444" : "black",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        III
                    </div>
                </div>
                {
                    ["coal", "oil", "waste", "uranium"].map(r => <div
                        key={r}
                        style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%"
                    }}>
                        <div style={{
                            flex: 1,
                            border: "1px solid gray",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <img alt={r} src={"../../../../public/megawatt/images/" + r + ".png"}/>
                        </div>
                        <div style={{
                            flex: 1,
                            border: "1px solid gray",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: this.props.phase === 1 ? "#444" : "black",
                            alignItems: "center"
                        }}>
                            {this.props.newResources[1][r.toUpperCase()]["value"]}
                        </div>
                        <div style={{
                            flex: 1,
                            border: "1px solid gray",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: this.props.phase === 2 ? "#444" : "black",
                            alignItems: "center"
                        }}>
                            {this.props.newResources[2][r.toUpperCase()]["value"]}
                        </div>
                        <div style={{
                            flex: 1,
                            border: "1px solid gray",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: this.props.phase === 3 ? "#444" : "black",
                            alignItems: "center"
                        }}>
                            {this.props.newResources[3][r.toUpperCase()]["value"]}
                        </div>
                    </div>)
                }
            </div>
        </div>
    }
}
