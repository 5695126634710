import React from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";

export default class StateBuyResources extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            action: false,
            nbCoal: 0,
            nbOil: 0,
            nbWaste: 0,
            nbUranium: 0,
            currentAction: false
        };
        this.getPlayer = this.getPlayer.bind(this);
        this.getActions = this.getActions.bind(this);
        this.getAction = this.getAction.bind(this);
        this.getResource = this.getResource.bind(this);
        this.addResource = this.addResource.bind(this);
        this.canChangeResource = this.canChangeResource.bind(this);
    }

    componentDidMount() {

    }

    getResource(r) {
        switch (r) {
            case "coal":
                return this.state.nbCoal;
            case "oil":
                return this.state.nbOil;
            case "waste":
                return this.state.nbWaste;
            case "uranium":
                return this.state.nbUranium;
        }
    }

    getPlayer() {
        return this.props.state.networkPlayers.filter(np => np.userId === this.props.userId)[0];
    }

    getActions() {
        return this.props.state.actions[this.props.userId];
    }

    getAction(nbCoal, nbOil, nbWaste, nbUranium) {
        return this.getActions().filter(a =>
            a.nbCoal === nbCoal &&
            a.nbOil === nbOil &&
            a.nbWaste === nbWaste &&
            a.nbUranium === nbUranium)[0];
    }

    canChangeResource(r, m) {
        switch (r) {
            case "coal":
                return !!this.getAction(this.state.nbCoal + m, this.state.nbOil, this.state.nbWaste, this.state.nbUranium);
            case "oil":
                return !!this.getAction(this.state.nbCoal, this.state.nbOil + m, this.state.nbWaste, this.state.nbUranium);
            case "waste":
                return !!this.getAction(this.state.nbCoal, this.state.nbOil, this.state.nbWaste + m, this.state.nbUranium);
            case "uranium":
                return !!this.getAction(this.state.nbCoal, this.state.nbOil, this.state.nbWaste, this.state.nbUranium + m);
        }
    }

    addResource(r, m) {
        if (!this.canChangeResource(r, m)) {
            return;
        }
        switch (r) {
            case "coal":
                this.setState({nbCoal: this.state.nbCoal + m});
                break;
            case "oil":
                this.setState({nbOil: this.state.nbOil + m});
                break;
            case "waste":
                this.setState({nbWaste: this.state.nbWaste + m});
                break;
            case "uranium":
                this.setState({nbUranium: this.state.nbUranium + m});
                break;
        }
    }

    getModalText(action) {
        return "Etes-vous sûr de dépenser " + action.price + " en ressources ?";
    }

    render() {
        let modal = ConfirmModal(this.state.show ? this.state.action : undefined,
            this.getModalText(this.state.action),
            this.props.socket, () => this.setState({show: false}));
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            overflow: "hidden", width: "90%", height: "96%", color: "#DDD"
        }}>
            {modal}
            <div style={{
                width: "100%",
                paddingTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <div style={{
                    marginBottom: "20px",
                    color: "white",
                    fontSize: "20px"
                }}>
                    C'est à vous d'acheter des resources
                </div>
                <div style={{
                    width: "100%",
                    marginTop: "25px"
                }}>
                    <div style={{
                        display: "flex",
                        height: "40px",
                        marginBottom: "12px",
                        flexDirection: "row"
                    }}>
                        {
                            ["coal", "oil", "waste", "uranium"].map(r =>
                                <div key={r}
                                     style={{
                                         flex: 1,
                                         display: "flex",
                                         justifyContent: "center",
                                         alignItems: "center",
                                         height: "100%;"
                                     }}>
                                    <div onClick={() => this.addResource(r, 1)}
                                         style={{
                                             backgroundColor: this.canChangeResource(r, 1) ? "#5bc0de" : "#777",
                                             borderRadius: "5px",
                                             color: "white",
                                             width: "50%",
                                             height: "70%",
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "center"
                                         }}>
                                        +
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div style={{
                        display: "flex",
                        height: "40px",
                        marginBottom: "12px",
                        flexDirection: "row"
                    }}>
                        {
                            ["coal", "oil", "waste", "uranium"].map(r =>
                                <div key={r}
                                     style={{
                                         flex: 1,
                                         display: "flex",
                                         justifyContent: "center",
                                         alignItems: "center",
                                         height: "100%;"
                                     }}>
                                    {this.getResource(r)}
                                    <img src={"../../../../public/megawatt/images/" + r + ".png"} alt={r}/>
                                </div>
                            )
                        }
                    </div>
                    <div style={{
                        display: "flex",
                        height: "40px",
                        marginBottom: "12px",
                        flexDirection: "row"
                    }}>
                        {
                            ["coal", "oil", "waste", "uranium"].map(r =>
                                <div
                                    key={r}
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%;"
                                    }}>
                                    <div onClick={() => this.addResource(r, -1)}
                                         style={{
                                             backgroundColor: this.canChangeResource(r, -1) ? "#5bc0de" : "#777",
                                             borderRadius: "5px",
                                             color: "white",
                                             width: "50%",
                                             height: "70%",
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "center"
                                         }}>
                                        -
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            fontSize: "22px"
                        }}>
                            COÛT: {this.getAction(this.state.nbCoal, this.state.nbOil, this.state.nbWaste, this.state.nbUranium).price}&nbsp;&nbsp;
                        </div>
                        <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                            <img
                                alt={"money"}
                                style={{height: "50%", width: "auto"}}
                                src={"../../../../public/megawatt/images/money.png"}/>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "40px"}}>
                        <div onClick={() => this.setState({
                            show: true,
                            action: this.getAction(this.state.nbCoal, this.state.nbOil, this.state.nbWaste, this.state.nbUranium)
                        })}
                             style={{
                                 backgroundColor: "#5bc0de",
                                 color: "white",
                                 display: "flex",
                                 fontSize: "26px",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 borderRadius: "5px",
                                 width: "50%",
                                 height: "80%"
                             }}>
                            Acheter
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
