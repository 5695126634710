import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import {CountryInvestPage} from "./CountryInvestPage";
import {getCountryFlagName} from "./utils";
import constants from "../../../config/constants";
import {Chart} from "react-charts";
import {ContinentHomePage} from "./ContinentHomePage";
import {BudgetPage} from "./BudgetPage";
import WarningIcon from '@mui/icons-material/Warning';
import {CountryProdPage} from "./CountryProdPage";
import {CountryConsoPage} from "./CountryConsoPage";

const queries = {
    "moneyPlayer": {parameters: ["player"]}
}

const COUNTRY = "country";
const MONEY = "money";
const CONTINENT = "continent";

export const PlayerHomePage = (props) => {

    const [selectedCountry, setSelectedCountry] = useState(undefined);
    const [selectedCountryPanel, setSelectedCountryPanel] = useState(undefined);
    const [selectedContinent, setSelectedContinent] = useState(undefined);
    const [display, setDisplay] = useState(COUNTRY);

    const [query, setQuery] = useState({title: '', image: ''});
    const [data, setData] = useState(undefined);

    const [open, setOpen] = useState(false);

    const primaryAxis = React.useMemo(() => ({
        getValue: datum => datum.time
    }), []);
    const secondaryAxes = React.useMemo(() => [{
        getValue: datum => datum.value
    }], []);

    const getCountryTopBarComponent = (country) => {
        return <div className={"country"}>
            <div className={"country-name"}>
                <img src={"/public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}/>
                {country}
            </div>
            <div className={"button-row"}>
                <div
                    onClick={() => selectCountry(country, "PRODUCTION")}
                    className={"button" + (selectedCountry === country && selectedCountryPanel === "PRODUCTION" ? ' selected' : '')}>
                    <div>PROD</div>
                    {
                        props.world.players[props.player].events.filter(event => event.countryName === country && event.target === "PRODUCTION").length > 0 &&
                        <div className={"icon"}>
                            <WarningIcon/>
                        </div>
                    }
                </div>
                <div
                    onClick={() => selectCountry(country, "CONSUMPTION")}
                    className={"button" + (selectedCountry === country && selectedCountryPanel === "CONSUMPTION" ? ' selected' : '')}>
                    <div>CONSO</div>
                    {
                        props.world.players[props.player].events.filter(event => event.countryName === country && event.target === "CONSUMPTION").length > 0 &&
                        <div className={"icon"}>
                            <WarningIcon/>
                        </div>
                    }
                </div>
                <div
                    onClick={() => selectCountry(country, "INVESTMENT")}
                    className={"button" + (selectedCountry === country && selectedCountryPanel === "INVESTMENT" ? ' selected' : '')}>
                    <div>INVEST</div>
                    {
                        props.world.players[props.player].events.filter(event => event.countryName === country && event.target === "INVESTMENT").length > 0 &&
                        <div className={"icon"}>
                            <WarningIcon/>
                        </div>
                    }
                </div>
            </div>

        </div>
    }

    const fetchQuery = (newQuery, title, image) => {
        setQuery({title, image});
        setData(undefined);
        axios.get(constants.api.basePath + "/anniv2024/historics?projection=" + newQuery).then(response => {
            setData([{
                label: title,
                data: response.data.results.sort(k => k.time).map(k => {
                    const time = k.time;
                    for (let key of newQuery.split(".")) {
                        k = k[key];
                    }
                    return {time, value: k};
                })
            }]);
            setOpen(true);
        });
    };
    const checkMoneyHistory = () => {
        fetchQuery("players." + props.player + ".money", "Argent de " + props.player, "/public/anniv/2024/money.png");
    }
    const checkPointsHistory = () => {
        fetchQuery("players." + props.player + ".points", "Points de " + props.player, "/public/anniv/2024/points.png");
    }

    const selectCountry = (country, target) => {
        setSelectedCountry(undefined);
        setSelectedCountryPanel(undefined);
        setDisplay(COUNTRY);
        setTimeout(() => {
            setSelectedCountry(country);
            setSelectedCountryPanel(target);
        }, 5)
    }

    return (
        <div>
            <div className={"top-bar-anniv-2024"}>
                <div className={"first-line"}>
                    <div className={"player"} onClick={() => {
                        setDisplay(COUNTRY);
                        setSelectedContinent(undefined);
                        setSelectedCountry(undefined);
                    }}>
                        {props.player}
                    </div>
                    <div className={"budget"} onClick={() => setDisplay(MONEY)}>
                        <img src={"/public/anniv/2024/money_target.png"}/>
                        <span>
                            {props.world.players[props.player].targetBudget}
                        </span>
                    </div>
                    <div className={"budget"}>
                        {props.world.players[props.player].points}
                        <img src={"/public/anniv/2024/points.png"}/>
                    </div>
                </div>
                <div className={"second-line"}>
                    {getCountryTopBarComponent(props.world.players[props.player].countries[0])}
                    {getCountryTopBarComponent(props.world.players[props.player].countries[1])}
                </div>
            </div>
            <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}} style={{marginTop: '165px'}}>
                {
                    display === COUNTRY && !selectedCountry && <div>
                        <div>
                            Vos pays:
                        </div>
                        <div>
                            {
                                props.world.players[props.player].countries.map(country => {
                                    return <div key={"keycountry " + country} style={{margin: '32px 0'}}>
                                        <button className={"country-button"}
                                                onClick={() => selectCountry(country, "PRODUCTION")}
                                        >
                                            <img
                                                style={{height: '90%', width: 'auto', marginRight: '24px'}}
                                                src={"/public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}/>
                                            {country}
                                            <img
                                                style={{height: '90%', width: 'auto', marginLeft: '24px'}}
                                                src={"/public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}/>
                                        </button>
                                        {
                                            props.world.players[props.player].events.filter(event => event.countryName === country).map(event =>
                                                <div className="warning-event" key={event.message}>
                                                    <div className={"icon"}>
                                                        <WarningIcon/>
                                                    </div>
                                                    <div className={"message"}>
                                                        {event.message}
                                                    </div>
                                                    <div className={"icon"} style={{padding: "0 12px"}}>
                                                        <button onClick={() => selectCountry(country, event.target)}>GO
                                                        </button>
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            Les continents:
                        </div>
                        <div>
                            {
                                Object.keys(props.world.continents).map(continent => {
                                    return <button className={"country-button"}
                                                   onClick={() => {
                                                       setDisplay(CONTINENT);
                                                       setSelectedContinent(continent);
                                                   }}
                                                   key={continent}>
                                        {continent}
                                    </button>
                                })
                            }
                        </div>
                    </div>
                }
                {
                    display === COUNTRY && selectedCountry && <div>
                        {
                            selectedCountryPanel === "PRODUCTION" &&
                            <CountryProdPage
                                editable={true}
                                world={props.world}
                                player={props.player}
                                target={selectedCountryPanel}
                                emitOrderBuy={props.emitOrderBuy}
                                emitOrderCountry={props.emitOrderCountry}
                                country={selectedCountry}/>
                        }
                        {
                            selectedCountryPanel === "CONSUMPTION" &&
                            <CountryConsoPage
                                editable={true}
                                world={props.world}
                                player={props.player}
                                target={selectedCountryPanel}
                                emitOrderBuy={props.emitOrderBuy}
                                emitOrderCountry={props.emitOrderCountry}
                                country={selectedCountry}/>
                        }
                        {
                            selectedCountryPanel === "INVESTMENT" &&
                            <CountryInvestPage
                                editable={true}
                                world={props.world}
                                player={props.player}
                                target={selectedCountryPanel}
                                emitOrderBuy={props.emitOrderBuy}
                                emitOrderCountry={props.emitOrderCountry}
                                country={selectedCountry}/>
                        }
                    </div>
                }
                {
                    display === MONEY &&
                    <div style={{marginTop: '24px'}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <h2>
                                Votre budget
                            </h2>
                            <button className={"big-button"} style={{float: 'right'}}
                                    onClick={() => setDisplay(COUNTRY)}>
                                Retour
                            </button>
                        </div>
                        <BudgetPage world={props.world} player={props.player}>
                        </BudgetPage>
                    </div>
                }
                {
                    display === CONTINENT && selectedContinent && <div>
                        <div className="top-line" style={{marginTop: '24px'}}>
                            <button className={"big-button"} onClick={() => {
                                setSelectedContinent(undefined);
                                setDisplay(COUNTRY);
                            }}>
                                Retour
                            </button>
                            <h2>
                                Continent : {selectedContinent}
                            </h2>
                        </div>
                        <ContinentHomePage
                            world={props.world}
                            player={props.player}
                            continent={selectedContinent}/>
                    </div>
                }
                <Modal
                    open={open}
                    className="modal-graph"
                    onClose={() => setOpen(false)}
                >
                    <Box>
                        <h2 style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                            {query.title}
                            <img style={{height: '30px', width: 'auto'}} src={query.image}/>
                        </h2>
                        <div className="chart-holder">
                            {
                                data && data.length ?
                                    <Chart
                                        options={{
                                            data,
                                            primaryAxis,
                                            secondaryAxes,
                                            dark: true,
                                        }}
                                    /> :
                                    undefined
                            }
                        </div>
                    </Box>
                </Modal>
            </Box>
        </div>
    )
}
