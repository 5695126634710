import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router";
import axios from "axios";
import constants from "../../../config/constants";
import {setVerifiedEmail} from "../../../store/authentication/actions";


export const VerifyEmail = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        let params = {};
        if (location.search) {
            location.search.substring(1).split("&").forEach(str => {
                params[str.split("=")[0]] = str.split("=")[1];
            });
        }
        verifyToken(params.userId, params.verifyToken);
    }, []);

    function verifyToken(userId, verifyToken) {
        axios.post(constants.api.basePath + "/userinfo/verifyEmail", {
            userId,
            verifyToken
        }).then(
            response => {
                dispatch(setVerifiedEmail);
                history.push("/");
            }
        ).catch(error => {
            console.log("what ?");
            console.log(error);
        });
    }

    return (
        <div>Verifying email</div>
    )
};

export default VerifyEmail;
