import React, {useEffect, useState} from "react";


export const BudgetPage = (props) => {

    const [panel, setPanel] = useState("Cible");

    const getPlayerTotalExpenses = () => {
        return Object.values(getExpenses()).reduce((a, b) => a + b, 0);
    }

    const getPlayerTotalIncome = () => {
        return Object.values(getIncomes()).reduce((a, b) => a + b, 0);
    }

    const getIncomes = () => {
        return panel === 'Cible' ? props.world.players[props.player].targetIncome : props.world.players[props.player].income;
    }

    const getExpenses = () => {
        return panel === 'Cible' ? props.world.players[props.player].targetExpenses : props.world.players[props.player].expenses
    }

    return <div>
        <div className={"buttonPanelRow"}>
            <div className={panel === "Cible" ? "buttonPanelSelected" : "buttonPanel"}
                 onClick={() => setPanel("Cible")}>
                Cible
            </div>
            <div className={panel === "Actuel" ? "buttonPanelSelected" : "buttonPanel"}
                 onClick={() => setPanel("Actuel")}>
                Actuel
            </div>
        </div>
        <div className={"top-player-info-bar"}>
            <div className={"top-player-info-bar-box"}>
                <img src={"/public/anniv/2024/money" + (panel === 'Cible' ? '_target': '') +".png"}/>
                {props.world.players[props.player][panel === 'Cible' ? 'targetBudget' : 'budget']}
                <img src={"/public/anniv/2024/money" + (panel === 'Cible' ? '_target': '') +".png"}/>
            </div>
        </div>
        <div className={"top-player-info-details"}>
            <div className={"top-player-info-details-element"}>
                <span style={{color: 'darkgreen'}}>+{getPlayerTotalIncome()}</span>
            </div>
            <div className={"top-player-info-details-element"}>
                <span style={{color: 'red'}}>-{getPlayerTotalExpenses()}</span>
            </div>
        </div>
        <div className="income-box">
            <div className="income-box-header">
                <span>Vos dépenses {panel==='Cible' ? ' cibles' : 'actuelles'}</span>
                <span>{-getPlayerTotalExpenses()}</span>
            </div>
            <div className="income-box-content">
                {
                    Object.keys(getExpenses()).map(expense => {
                        return <div className="income-box-line" key={expense}>
                            <span>{expense}</span>
                            <span style={{color: 'red'}}>
                                                -{getExpenses()[expense]}
                                            </span>
                        </div>
                    })
                }
                {
                    Object.keys(getExpenses()).length === 0 && <div>
                        Vous n'avez pas de dépenses
                    </div>
                }
            </div>
        </div>
        <div className="income-box">
            <div className="income-box-header">
                <span>Vos revenus {panel==='Cible' ? ' cibles' : 'actuels'}</span>
                <span>+{getPlayerTotalIncome()}</span>
            </div>
            <div className="income-box-content">
                {
                    Object.keys(getIncomes()).map(income => {
                        return <div className="income-box-line" key={income}>
                            <span>{income}</span>
                            <span style={{color: 'green'}}>+{getIncomes()[income]}</span>
                        </div>
                    })
                }
                {
                    Object.keys(getIncomes()).length === 0 && <div>
                        Vous n'avez pas de revenu
                    </div>
                }
            </div>
        </div>
    </div>
}
