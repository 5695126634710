import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";

export default class ActionConsultDeck extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actions: props.actions[props.userId][0],
            show: false
        };
    }

    componentDidMount() {
    }
    styleButton = {
        backgroundColor: "#444",
        padding: "10px",
        height: "23%",
        borderRadius: "8px",
        marginTop: "6px",
        marginBottom: "12px",
        width: "60%"
    };

    render() {
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={"Confirmez-vous cette action ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        return <div
            style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD",
                display: "flex", flexDirection: "column"
            }}>
            {modal}
            <div style={{flex: 1, fontSize: "20px", alignItems: "center", display: "flex", marginLeft: "20px"}}>
                Souhaitez-vous acheter cette carte ?
            </div>
            <div style={{flex: 5, width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <Button onClick={() => this.setState({action: this.props.actions[this.props.userId][0], show: true})}
                            variant={"contained"}
                    >
                        Acheter
                    </Button>
                </div>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center", height: "100%"}}>
                    <img
                        style={{height: "auto", width: "80%"}}
                        src={"/public/terraforming/cards/img_"+this.props.actions[this.props.userId][0].cardId+".png"} alt="card"/>
                </div>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <Button onClick={() => this.setState({action: this.props.actions[this.props.userId][1], show: true})}
                            variant={"contained"}
                    >
                        Défausser
                    </Button>
                </div>
            </div>
        </div>
    }
}
