import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {signin} from "../../../store/authentication/actions";
import {useHistory, useLocation} from "react-router";
import {selectIsLoggedIn, selectSessionError} from "../../../store/authentication/selectors";
import axios from "axios";
import constants from "../../../config/constants";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        error: selectSessionError(state),
        isLoggedIn: selectIsLoggedIn(state)
    };
};

const mapActionsToDispatch = dispatch => ({
    signin: (email, password, callbackError) => dispatch(signin(email, password, callbackError))
});

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps
});


export const SignupPage = () => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [error, setError] = useState("");
    const [submitted, setSubmitted] = useState("");

    const theme = useTheme();

    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);

    const setters = {
        email: setEmail,
        password: setPassword,
        username: setUsername
    };

    function handleChange(event) {
        event.preventDefault();
        const {name, value} = event.target;
        setters[name](value);
        setError(undefined);
    }

    function handleSignup(event) {
        event.preventDefault();
        setSubmitted(true);
        if (email && username && password) {
            axios.post(constants.api.basePath + "/userinfo/signup", {
                email,
                username,
                password
            }).then(
                response => {
                    dispatch(signin(email, password, handleError))
                }
            ).catch(error => {
                if (error.response) {
                    setError(error.response.data.err);
                }
            });
        }
    }

    function handleError(error) {
        if (error.response) {
            setError(error.response.data.err);
        }
    }

    const history = useHistory();
    const location = useLocation();
    const mobile = window.innerWidth < 700;

    useEffect(
        () => {
            if (isLoggedIn) {
                history.push('/');
            }
        }, [isLoggedIn]
    );

    let emailError = undefined;
    let usernameError = undefined;
    let passwordError = undefined;
    if (submitted) {
        if (!email) {
            emailError = "Email is required";
        } else if (error === "EmailAddressAlreadyTaken") {
            emailError = "Email address already taken";
        } else if (error === "InvalidEmail") {
            emailError = "Invalid Email";
        }
        if (!username) {
            usernameError = "Username is required";
        } else if (error === "UsernameAlreadyTaken") {
            usernameError = "Username already taken";
        }
        if (!password) {
            passwordError = "Password is required";
        }
    }
    return (
        <Box sx={{width: {xs: "80%", md: "500px"}, margin: "auto"}}>
            <div className={"auth-form"}>
                <h2>Sign up</h2>
                <form name="form">
                    <div className={'form-group' + (emailError ? ' has-error' : '')}>
                        <label htmlFor="username">Email</label>
                        <TextField type="text" name="email"
                                   hiddenLabel
                                   size="small"
                                   style={mobile ? {width: "100%"} : {}}
                                   variant="outlined" value={email}
                                   onChange={handleChange}/>
                    </div>
                    <div className={'form-group' + (usernameError ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <TextField type="text" name="username"
                                   hiddenLabel
                                   size="small"
                                   style={mobile ? {width: "100%"} : {}}
                                   variant="outlined" value={username}
                                   onChange={handleChange}/>
                    </div>
                    <div className={'form-group' + (passwordError ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <TextField type="password" name="password"
                                   hiddenLabel
                                   size="small"
                                   variant="outlined" value={password}
                                   onChange={handleChange}/>
                    </div>
                    <Box className="error-holder" style={{color: theme.palette.error.main}}>
                        {
                            emailError && <div className="help-block">{emailError}</div>
                        }
                        {
                            usernameError && <div className="help-block">{usernameError}</div>
                        }
                        {
                            passwordError && <div className="help-block">{passwordError}</div>
                        }
                    </Box>
                    <div style={{textAlign: "center", marginTop: "3vh"}}>
                        <Button
                            variant="contained"
                            disabled={emailError || usernameError || passwordError}
                            onClick={handleSignup}>
                            Sign up
                        </Button>

                    </div>
                </form>
            </div>
        </Box>
    );
};

export default SignupPage;
