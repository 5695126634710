import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetError, signin} from "../../../store/authentication/actions";
import {useHistory, useLocation} from "react-router";
import {selectIsLoggedIn} from "../../../store/authentication/selectors";
import "./auth.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";


export const LoginPage = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const isLoggedIn = useSelector(selectIsLoggedIn);
    const dispatch = useDispatch();

    const theme = useTheme();

    const history = useHistory();
    const location = useLocation();
    const mobile = window.innerWidth < 700;

    const setters = {
        email: setEmail,
        password: setPassword
    };

    function handleChange(event) {
        const {name, value} = event.target;
        setters[name](value);
        setError(undefined);
        dispatch(resetError());
    }

    function handleSignin(event) {
        event.preventDefault();
        setSubmitted(true);
        if (email && password) {
            dispatch(signin(email, password, (error) => handleError(error)));
        }
    }

    function handleError(error) {
        if (error.response) {
            setError(error.response.data.err);
        }
    }

    useEffect(
        () => {
            if (isLoggedIn) {
                if (location.state && location.state.from) {
                    history.push(location.state.from);
                } else {
                    history.push('/');
                }
            }
        }
        , [isLoggedIn]
    );

    let emailError = undefined;
    let passwordError = undefined;
    if (submitted) {
        if (!email) {
            emailError = "Email is required";
        } else if (!password) {
            passwordError = "Password is required";
        } else if (error === "InvalidCredentials" || error === "UserNotFound") {
            passwordError = "Invalid Credentials";
        } else if (error) {
            passwordError = "BattleMythe Unreachable";
        }
    }
    return (
        <Box sx={{width: {xs: "80%", md: "500px"}, margin: "auto"}}>
            <div className={"auth-form"}>
                <h2>Login</h2>
                <form name="form">
                    <div className={'form-group' + (emailError ? ' has-error' : '')}>
                        <label htmlFor="email">Email / Username</label>
                        <TextField type="text" name="email"
                                   hiddenLabel
                                   size="small"
                                   style={mobile ? {width: "100%"}: {}}
                                   variant="outlined" value={email}
                               onChange={handleChange}/>

                    </div>
                    <div className={'form-group' + (passwordError ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <TextField type="password" name="password"
                                   hiddenLabel
                                   size="small"
                                   variant="outlined" value={password}
                                   onChange={handleChange}/>
                    </div>
                    <Box className="error-holder" style={{color: theme.palette.error.main}}>
                        {
                            emailError && <div className="help-block">{emailError}</div>
                        }
                        {
                            passwordError && <div className="help-block">{passwordError}</div>
                        }
                    </Box>
                    <div style={{textAlign: "center", marginTop: "3vh"}}>
                        <Button
                            variant="contained"
                            disabled={emailError || passwordError}
                            onClick={handleSignin}>
                            Sign in
                        </Button>

                    </div>
                </form>
            </div>
            <div className="auth-additional-infos">
                <h2>
                    Don't have an account yet ?
                </h2>
                <Button
                    variant="contained"
                    href='/signup'
                >
                    Sign up
                </Button>
            </div>
            <div className="auth-additional-infos">
                <h2>
                    Forgotten Password ?
                </h2>
                <Button
                    variant="contained"
                    href='/resetPassword'
                >
                    Reset
                </Button>
            </div>
            <div style={{
                width: "100%",
                height: "30vh"
            }}/>
        </Box>
    )
};

export default LoginPage;
