import React, {useEffect, useState} from "react";

import {CardCarousel} from "../components/CardCarousel";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


export const LeaderChoice = (props) => {

    const {userId, socket} = props;

    const [state, setState] = useState({
        init: false,
        show: false,
        action: undefined
    });

    useEffect(() => {
        if(!state.init){
            handleChangeActiveCard(0);
            setState(state => ({...state, init: true}));
        }
    }, [state]);


    function handleChangeActiveCard(index) {
        setState(state => ({...state, action: getActions()[index]}));
    }

    function getActions() {
        return props.state.actions[userId];
    }

    if (!state.action) {
        return <div/>;
    }
    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={"Etes-vous sûr de vouloir choisir le leader " + state.action.leader.building.name + " ?"}
                socket={socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                Choix du Leader
            </h2>
            <CardCarousel activeIndex={getActions().map(a => a.leader.building.idname).indexOf(state.action.leader.building.idname)}
                          cards={getActions().map(a => a.leader.building)}
                          onSelect={handleChangeActiveCard} key={"carouselleader"}/>
            <div style={{width: "100%", textAlign: "center"}}>
                <Button variant="contained" onClick={() => setState(state => ({...state, show: true}))}
                        style={{marginTop: 10}}>
                    Choisir
                </Button>
            </div>
            <Card className="panel panel-primary" style={{marginTop: 10, marginBottom: 20}}>
                <CardContent className="panel-heading">
                    <div className={"card-title"}>
                        Description : {state.action.leader.building.name}
                    </div>
                    <div className="panel-body">
                        {state.action.leader.building.description}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default LeaderChoice;
