import React from "react";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

export const getAlertInGame = function (isInGame, history) {
    return (isInGame ?
            <Card style={{
                margin: "2vh",
            }}>
                <CardContent>
                    You already are in a game
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => history.push("/" + isInGame.gamekey)} variant="contained">
                        Play
                    </Button>
                </CardActions>
            </Card> :
            undefined
    )
};
