import React, {useState} from "react";
import Box from "@mui/material/Box";
import {BrowserView, MobileView} from "react-device-detect";
import CorpoChoiceComponent from "../CorpoChoiceComponent";
import ObjectiveScreenComponent from "../mainScreens/ObjectiveScreenComponent";


export const ConsultScreen = (props) => {

    const consults = [];
    if (props.state && props.state.availableCorpos.length > 0) {
        consults.push("CORPOS");
    } else {
        consults.push("MAIN");
    }
    consults.push("DRAFT");
    consults.push("JOUEES");
    consults.push("OBJ/REC");
    if (props.state.preludes && props.state.preludes[props.userId] && props.state.preludes[props.userId].length) {
        consults.push("PRELUDES");
    }

    const [consult, setConsult] = useState(consults[0]);
    const [state, setState] = useState({card: false});

    const player = props.state.players[props.userId];

    const stateDict = {
        "MAIN": "hands",
        "DRAFT": "drafted",
        "JOUEES": "played"
    };

    console.log(stateDict[consult]);
    let cards = [];
    console.log(props.state);
    console.log(props.userId);
    console.log(props.state[stateDict[consult]]);
    if(props.state[stateDict[consult]] && props.state[stateDict[consult]][props.userId]){
        cards = props.state[stateDict[consult]][props.userId];
    }
    let compConsult = <div
        style={{
            width: "100%", height: "88%",
            marginTop: "-1px"
        }}>
        <MobileView style={{height: "100%"}}>
            <div style={{
                height: "88%",
                width: "100%",
                paddingTop: "2%",
                display: "inline-block",
                whiteSpace: "nowrap"
            }}>
                {
                    consult === "CORPOS" && props.state && props.state.availableCorpos.length > 0 ?
                        <CorpoChoiceComponent blocked={true} state={props.state}
                                              userId={props.userId}
                        /> :
                        (
                            consult === "OBJ/REC" ?
                                <ObjectiveScreenComponent state={props.state}
                                                          userId={props.userId}>

                                </ObjectiveScreenComponent> :
                                (consult === "PRELUDES" ?
                                        props.state.preludes[props.userId].map(prelude =>
                                            <div key={prelude.value}>
                                                <img
                                                    src={"/public/terraforming/preludes/prelude_" + prelude.value + ".png"}
                                                    alt={"img " + prelude.value}
                                                    style={{
                                                        width: "90%",
                                                        height: "auto",
                                                        marginLeft: "5%"
                                                    }}>
                                                </img>
                                            </div>)
                                        :
                                        <div style={{width: '100%', overflow: 'auto'}}>

                                            {cards.map(action =>
                                            <div key={action.value}
                                                 style={{
                                                     height: "100%",
                                                     display: "inline-block",
                                                     marginLeft: "8px"
                                                 }}>
                                                <img
                                                    src={"/public/terraforming/cards/img_" + action.value + ".png"}
                                                    alt={"img " + action.value}
                                                    style={{width: "auto", height: "70vh"}}/>
                                            </div>
                                            )}
                                        </div>
                                )

                        )
                }
            </div>
        </MobileView>
        <BrowserView style={{height: "100%"}}>
            <div style={{
                height: "88%",
                width: "100%",
                paddingTop: "2%",
                display: "inline-block"
            }}>
                {
                    consult === "CORPOS" && props.state && props.state.availableCorpos.length > 0 ?
                        <CorpoChoiceComponent blocked={true} state={props.state}
                                              userId={props.userId}
                        /> :
                        (
                            consult === "OBJ/REC" ?
                                <ObjectiveScreenComponent state={props.state}
                                                          userId={props.userId}>

                                </ObjectiveScreenComponent> :
                                (consult === "PRELUDES" ?
                                        props.state.preludes[props.userId].map(prelude =>
                                            <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}
                                                 key={prelude.value}>
                                                <img
                                                    src={"/public/terraforming/preludes/prelude_" + prelude.value + ".png"}
                                                    alt={"img " + prelude.value}
                                                    style={{
                                                        width: "90%",
                                                        height: "auto",
                                                        marginLeft: "5%"
                                                    }}>
                                                </img>
                                            </Box>)
                                        :
                                        cards.map(action =>
                                            <div key={action.value}
                                                 style={{
                                                     height: "100%",
                                                     display: "inline-block",
                                                     marginLeft: "8px"
                                                 }}>
                                                <img
                                                    src={"/public/terraforming/cards/img_" + action.value + ".png"}
                                                    alt={"img " + action.value}
                                                    style={{width: "auto", height: "50vh"}}/>
                                            </div>
                                        )
                                )
                        )
                }
            </div>
        </BrowserView>
    </div>;


    return (
        <div style={{width: "100%"}}>
            <div className="board-game-home-top-bar" style={{height: "8vh"}}>
                {
                    consults.map(c => <div key={c}
                                           className={"board-game-home-top-button board-game-home-top-button-" + (consult === c ? "active" : "inactive")}
                                           onClick={() => {
                                               setConsult(c)
                                           }}>
                        {c}
                    </div>)
                }
            </div>
            {
                compConsult
            }
        </div>
    )

};

export default ConsultScreen;
