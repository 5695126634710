
export const dictCases = {
    RUBY: "Rubis",
    MARKET: "Marché",
    PRODUCTION: "Production",
    TEMPLE: "Temple",
    CONSTRUCTION: "Construction",
    ECLIPSE: "Eclipse",
    BARRACKS: "Caserne",
    REJOUER: "Rejouer",
    SACRIFICE: "Sacrifice",
    START: "Départ",
};
