import React from "react";
import {Redirect, Route} from "react-router";
import {useSelector} from "react-redux";
import {selectCanPlayBoardGames, selectIsLoggedIn} from "../../store/authentication/selectors";


export const PrivateRoute = (props) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const canPlayBoardGames = useSelector(selectCanPlayBoardGames);
    const {component: Component, boardGame, admin, ...rest} = props;
    return (
        <Route {...rest} render={props => {
            if(admin) {
                if (isLoggedIn && admin) {
                    return <Component {...props} />
                } else {
                    return <Redirect to={{pathname: "/login", state: {from: props.location.pathname}}}/>
                }
            } else if (boardGame) {
                if (isLoggedIn && canPlayBoardGames) {
                    return <Component {...props} />
                } else {
                    return <Redirect to={{pathname: "/login", state: {from: props.location.pathname}}}/>
                }
            } else {
                return isLoggedIn
                    ? <Component {...props} />
                    : <Redirect to={{pathname: "/login", state: {from: props.location.pathname}}}/>
            }
        }}
        />
    )
};

export default PrivateRoute
