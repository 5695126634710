import callApiService from "./services";
import * as selectors from './selectors';

export const CALLING_API  = 'CALLING_API';
export const CALLING_API_FAILED  = 'CALLING_API_FAILED';
export const CALLING_API_SUCCEDED  = 'CALLING_API_SUCCEDED';

export const callingApi = (key) => ({type: CALLING_API, key});
export const callingApiSucceded = (key) => ({type: CALLING_API_SUCCEDED, key});
export const callingApiFailed = (key,error) => ({type: CALLING_API_FAILED, key, error});

export const callApi = (path, responseTransform = ((data) => () => data), options = {}, errorHandling = (data) => () => data) => {

    return (dispatch, getState) => {
        let method = options.method ? options.method : "GET";
        const key = method + path;
        if(!selectors.isLoadingForKey(getState(), key)){
            dispatch(callingApi(key));
            return callApiService(path,options)
                .then((data) => responseTransform(data)(dispatch))
                .then(() => dispatch(callingApiSucceded(key)))
                .catch((data) => {
                    errorHandling(data)(dispatch);
                    dispatch(callingApiFailed(key,"api call failed"));
                });
        } else {
            dispatch(callingApiFailed(key, "api call already in progress"));
        }
    };
};

export default callApi;
