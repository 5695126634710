import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {thunk} from 'redux-thunk';
import api from './api/reducers';
import session from './authentication/reducers';
import games from './games/reducers';
import player from './player/reducers';
import monitorGame from './monitorGame/reducers';
import data from './data/reducers';
import reduxCatch from 'redux-catch';

const rootReducer = combineReducers({api, session, games, player, data, monitorGame});

const initialState = {};

const reduxMiddleware = function(errorHandler) {
    if (errorHandler) {
        return compose(
            applyMiddleware(reduxCatch(errorHandler)),
            applyMiddleware(thunk)
        );
    }

    return compose(
        applyMiddleware(thunk)
        // ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
};

// export a store creator factory with initial state if present
// modif to see the pipeline launch in web and mobile
// new useless comment
export default(errorHandler) => createStore(rootReducer, initialState, reduxMiddleware(errorHandler));
