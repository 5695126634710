import React, {useState} from "react";


import WonderFacePresentation from "../components/WonderFacePresentation";
import {ConfirmModal} from "../../terraforming/utils/Modal";


export const WonderFaceChoice = (props) => {

    let [show, setShow] = useState(false);
    let [action, setAction] = useState(false);
    let [face, setFace] = useState("A");
    let [currentAction, setCurrentAction] = useState(false);

    const {state, userId, gameId, socket} = props;

    function getPlayer() {
        return state.players[userId];
    }

    function getActions() {
        return state.actions[userId];
    }

    function selectWonderFace(face) {
        for (let action of getActions()) {
            if (action.face === face) {
                setAction(action);
                setShow(true);
            }
        }
    }

    function switchWonderFace(face) {
        setFace(face);
    }

    let wonder = getPlayer().wonder;
    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal open={show}
                          action={action}
                          text={"Etes-vous sûr de vouloir choisir la face " + action.face + "?"}
                          socket={socket}
                          onDismiss={() => {
                              setShow(false);
                              setAction(false);
                          }}/>
            <div>
                <h2>
                    Choix de la Merveille
                </h2>
                <h4 style={{textAlign: "center"}}>
                    {wonder.name}
                </h4>
            </div>
            <WonderFacePresentation
                key={"1" + face}
                wonder={wonder}
                face={face}
                action={() => selectWonderFace(face)}
                switchWonderFace={switchWonderFace}/>
        </div>
    )
};

export default WonderFaceChoice;
