import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";

export default class ActionMainDoeuvreRobotique extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actions: props.actions[props.userId][0],
            show: false
        };
    }

    componentDidMount() {
    }
    styleButton = {
        backgroundColor: "#444",
        padding: "5px",
        borderRadius: "3px",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "10%",
        width: "80%"
    };

    render() {
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={ "Confirmez-vous le choix de cette carte ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        return <div
            style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD",
                display: "flex", flexDirection: "column"
            }}>
            {modal}
            <div style={{flex: 1, fontSize: "20px", alignItems: "center", display: "flex", marginLeft: "20px"}}>
                De quelle carte souhaitez-vous copier la production ?
            </div>
            <div style={{flex: 5, overflowX: "scroll", whiteSpace: "nowrap", marginLeft: "5%", width: "95%"}}>
                {
                    this.props.actions[this.props.userId].map(action =>
                        <div key={action.cardId}
                             style={{height: "100%", display: "inline-block", marginLeft: "12px"}}>
                            <img src={action.cardId ? "/public/terraforming/cards/img_" + action.cardId + ".png" :
                                "/public/terraforming/corpos/corpo_card_" + action.corpoName.lower() + ".png"}
                                 alt={"img " + action.cardId}
                                 style={{width: "auto", height: "80%"}}>
                            </img>
                            <br/>
                            <Button variant={"contained"}
                                    disabled={!action.ok}
                                    onClick={() => this.setState({action, show: true})}>
                                Copier
                            </Button>
                        </div>
                    )
                }
            </div>
        </div>
    }
}
