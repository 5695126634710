import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";

export default class ActionBacteriesSulfoOxydantes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: props.actions[props.userId][1],
            show: false
        };
        this.canIncreaseValue = this.canIncreaseValue.bind(this);
        this.canDecreaseValue = this.canDecreaseValue.bind(this);
        this.increaseValue = this.increaseValue.bind(this);
        this.decreaseValue = this.decreaseValue.bind(this);
    }

    componentDidMount() {
    }

    canIncreaseValue(){
        let value = this.state.action.value;
        return this.props.actions[this.props.userId].some(a => a.value === value + 1);
    }

    canDecreaseValue(){
        let value = this.state.action.value;
        return this.props.actions[this.props.userId].some(a => a.value === value - 1);
    }

    increaseValue(){
        let value = this.state.action.value;
        let newAction = this.props.actions[this.props.userId].filter(a => a.value === value + 1)[0];
        if(newAction){
            this.setState({action: newAction});
        }
    }

    decreaseValue(){
        let value = this.state.action.value;
        let newAction = this.props.actions[this.props.userId].filter(a => a.value === value - 1)[0];
        if(newAction){
            this.setState({action: newAction});
        }
    }

    styleButton = {
        backgroundColor: "#444",
        padding: "10px",
        borderRadius: "8px",
        marginTop: "6px",
        marginBottom: "12px",
        width: "60%"
    };

    render() {

        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={"Voulez-vous échanger " + this.state.action.value + " microbes ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        let modal2 = <ConfirmModal
            action={this.state.show2 ? this.props.actions[this.props.userId][0] : undefined}
            text={"Voulez-vous ajouter un microbe à cette carte ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show2: false})}
            open={this.state.show2}
        />;
        
        return <div
            style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD",
                display: "flex", flexDirection: "column"
            }}>
            {modal}
            {modal2}
            <div style={{flex: 1, fontSize: "20px", alignItems: "center", display: "flex", marginLeft: "20px"}}>
                Souhaitez-vous échanger des microbes contre des megacredits ?
            </div>
            <div style={{flex: 1, width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <Button onClick={() => this.decreaseValue()}
                            disabled={!this.canDecreaseValue()}
                            variant={"contained"}
                    >
                        -
                    </Button>
                </div>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center", height: "100%"}}>
                    {this.state.action.value}
                    <img
                        style={{height: "auto", width: "20%"}}
                        src={"/public/terraforming/icons/resource_microbes.png"} alt="card"/>
                </div>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <Button onClick={() => this.increaseValue()}
                            disabled={!this.canIncreaseValue()}
                            variant={"contained"}
                    >
                        +
                    </Button>
                </div>
            </div>
            <div style={{flex: 1, fontSize: "20px", alignItems: "center", display: "flex", justifyContent: "center"}}>
                <Button onClick={() => this.setState({show: true})}
                        variant={"contained"}
                >
                    Echanger
                </Button>
            </div>
            <div style={{flex: 1, fontSize: "20px", alignItems: "center", display: "flex", marginLeft: "20px"}}>
                Ou ajouter un microbe à cette carte ?
            </div>
            <div style={{flex: 1, fontSize: "20px", alignItems: "center", display: "flex", justifyContent: "center"}}>
                <Button onClick={() => this.setState({show2: true})}
                        variant={"contained"}
                >
                    Ajouter un microbe
                </Button>
            </div>
        </div>
    }
}
