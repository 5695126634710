import React from "react";
import {ConfirmModal} from "./utils/Modal";
import Button from "@mui/material/Button";

export default class PreludeComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            action: undefined,
            kept: []
        };
        this.getChoice = this.getChoice.bind(this);
    }


    getChoice() {
        if (!this.props.state.actions[this.props.userId] || !this.props.state.actions[this.props.userId]) {
            return <div/>;
        }
        let action = this.props.state.actions[this.props.userId][0];
        return action.preludes.map(preludeId =>
            <div key={preludeId}>
                <img src={"/public/terraforming/preludes/prelude_" + preludeId + ".png"}
                     alt={"img " + preludeId}
                     style={{width: "90%", height: "auto", marginLeft: "5%"}}>
                </img>
                <br/>
                <div style={{width: '100%', textAlign: 'center'}}>
                    {
                        this.state.kept.indexOf(preludeId) > -1 ?
                            <Button style={{marginBottom: "7vh"}}
                                    variant={"contained"}
                                    onClick={() => this.setState((prevState) => {
                                        let kept = prevState.kept;
                                        if (kept.indexOf(preludeId) > -1) {
                                            kept.splice(kept.indexOf(preludeId), 1);
                                        }
                                        return {
                                            kept
                                        }
                                    })}>
                                Gardée
                            </Button> :
                            <Button style={{marginBottom: "7vh"}}
                                    variant={"outlined"}
                                    disabled={action.nbPrelude <= this.state.kept.length}
                                    onClick={() => this.setState((prevState) => {
                                        let kept = prevState.kept;
                                        if (kept.indexOf(preludeId) === -1 && action.nbPrelude > kept.length) {
                                            kept.push(preludeId);
                                        }
                                        return {
                                            kept
                                        }
                                    })}>
                                {action.nbPrelude > this.state.kept.length ? "Choisir" : "Indisp."}
                            </Button>
                    }
                </div>
            </div>
        )
    }


    render() {
        let gameState = this.props.state;
        let modal = <ConfirmModal action={this.state.show ? this.state.action : undefined}
                                  text={'Vous êtes sûr de garder ces ' + this.state.kept.length + ' prélude' + (this.state.kept.length > 1 ? 's' : '') + ' ?'}
                                  socket={this.props.socket}
                                  onDismiss={() => this.setState({show: false})}
                                  open={this.state.show}
        />;
        let buttonIsOk = this.state.kept.length === this.props.state.actions[this.props.userId][0].nbPrelude;
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%",
            overflow: "auto", width: "90%", height: "96%", color: "#DDD"
        }}>
            {modal}
            <div style={{width: "100%"}}>
                <h3>
                    Phase de Choix de Préludes
                </h3>
                <h4>
                    {gameState.actions[this.props.userId] ? "Merci de choisir vos préludes" : "Vous avez déjà choisi"}
                </h4>
            </div>
            <div style={{margin: "2vh 0", textAlign: "center"}}>
                {
                    gameState.actions[this.props.userId] ?
                        <Button variant={"contained"}
                                disabled={!buttonIsOk}
                                onClick={() => {
                                    this.setState({
                                        show: true,
                                        action: {
                                            preludes: this.state.kept,
                                            nbPrelude: 2,
                                            userId: this.props.userId,
                                            class: "model.actions.draft.PreludeChoiceAction"
                                        }
                                    });
                                }}>
                            Garder {this.state.kept.length} prélude{this.state.kept.length > 1 ? 's' : ''}
                        </Button> :
                        undefined
                }
            </div>
            <div style={{
                width: "100%",
                height: "100%"
            }}>
                {this.getChoice()}
            </div>
        </div>
    }
}
