import React, {useEffect, useState} from "react";


import {CardCarousel} from "../components/CardCarousel";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Carousel} from "react-responsive-carousel";


export const ConsultScreen = (props) => {

    const [consult, setConsult] = useState("Leaders");
    const [state, setState] = useState({card: false});

    const player = props.state.players[props.userId];
    const cards = player.leaderToChoose;
    const wonders = Object.keys(props.state.players).filter(idPlayer => props.state.players[idPlayer].wonderFace).map(idPlayer => {
       return {
           idname: props.state.players[idPlayer].wonder.idname,
           username: props.state.players[idPlayer].username,
           wonderName: props.state.players[idPlayer].wonder.name,
           wonderFace: props.state.players[idPlayer].wonderFace,
           wonderFloors: props.state.players[idPlayer].wonder["face" + props.state.players[idPlayer].wonderFace].floors,
           wonderFloorBuilt: props.state.players[idPlayer].wonderFloorBuilt
       }
    });

    useEffect(() => {
        if (!state.card && cards && cards.length) {
            handleChangeActiveCard(0);
        }
    }, [state]);


    function handleChangeActiveCard(index) {
        setState(state => ({
            ...state,
            card: cards[index]
        }));
    }
    let compConsult = undefined;
    const consults = [
        "Leaders",
        "Wonders"
    ];
    if(consult === "Leaders"){
        if(!state.card){
            compConsult = <div/>;
        } else {
            compConsult = <div>
                <h2>
                    Vos leaders en main
                </h2>
                <CardCarousel
                    activeIndex={cards.map(c => c.building).indexOf(state.card.building)}
                    cards={cards.map(c => c.building)}
                    onSelect={handleChangeActiveCard} key={"carouselCards"}/>
                <Card className="panel panel-primary" style={{marginTop: 10, marginBottom: 20}}>
                    <CardContent className="panel-heading">
                        <div className={"card-title"}>
                            Description : {state.card.building.name}
                        </div>
                        <div className="panel-body">
                            <p>{state.card.building.description}</p>
                        </div>
                    </CardContent>
                </Card>
            </div>
        }
    } else if(consult === "Wonders"){
        compConsult = <div>
            <h2>
                Les merveilles
            </h2>
            <div style={{width: "100%", height: "100%"}}>
                <Carousel showThumbs={false}>
                    {
                        wonders.map((wonder, index) => {
                            return (
                                <div key={"wonder_" + index}>
                                    <h4>
                                        {wonder.username}
                                    </h4>
                                    <h4>
                                        {wonder.wonderName}
                                    </h4>
                                    <div>
                                        {wonder.wonderFloors.map((floor, iFloor) => <div key={"floor_" + iFloor}>
                                            <img key={index} style={{width: "90%", height: "auto"}} alt={wonder.wonderName + " floor " + iFloor}
                                                 src={"../../../public/7wonders/images/wonders/" + wonder.idname + wonder.wonderFace + (iFloor + 1) + ".png"}/>
                                        </div>)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    }

    return (
        <div style={{width: "100%"}}>
            <div className="board-game-home-top-bar" style={{height: "8vh"}}>
                {
                    consults.map(c => <div key={c}
                                           className={"board-game-home-top-button board-game-home-top-button-" + (consult === c ? "active" : "inactive")}
                                           onClick={() => {
                                               setConsult(c)
                                           }}>
                        {c}
                    </div>)
                }
            </div>
            <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
            {
                compConsult
            }
            </Box>
        </div>
    )

};

export default ConsultScreen;
