import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";

export default class ActionRevendicationTerrain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: undefined,
            row: undefined,
            column: undefined
        };
        this.getAction = this.getAction.bind(this);
        this.isLineOk = this.isLineOk.bind(this);
        this.isColumnOk = this.isColumnOk.bind(this);
    }

    componentDidMount() {

    }

    getAction(row, column) {
        return this.props.actions[this.props.userId].filter(action =>
            action.ok &&
            action.rowNumber === "" + "ABCDEFGHI".indexOf(row) &&
            action.columnNumber === "" + column)[0];
    }

    isLineOk(row) {
        return this.props.actions[this.props.userId].filter(action =>
            action.ok &&
            action.rowNumber === "" + "ABCDEFGHI".indexOf(row)).length > 0;
    }

    isColumnOk(column) {
        return this.props.actions[this.props.userId].filter(action =>
            action.ok &&
            action.rowNumber === "" + "ABCDEFGHI".indexOf(this.state.row) &&
            action.columnNumber === "" + column).length > 0;
    }

    styleButton = {
        backgroundColor: "#444",
        padding: "5px",
        borderRadius: "3px",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "25%",
        width: "50%"
    };

    styleTouch = {
        width: "80%",
        height: "80%",
        margin: "10% 10%",
        border: "1px solid #444",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    render() {
        let {action, row, column} = this.state;
        let modal = <ConfirmModal
            action={action}
            text={"Voulez vous réserver la tuile en " + row + ", " + column + " ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({action: false})}
            open={!!this.state.action}
        />;
        return (
            <div style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD",
            }}>
                {modal}
                <div style={{flex: 1, fontSize: "18px", width: "100%", textAlign: "center"}}>
                    Quelle tuile réserver ?
                </div>
                <div style={{flex: 1, fontSize: "20px", width: "100%", textAlign: "center"}}>
                    {row ? row : '?'}, {(typeof column !== 'undefined') ? column : '?'}
                </div>
                <h4>
                    Ligne
                </h4>
                <div style={{flex: 2, display: "flex", flexDirection: "column"}}>
                    {
                        [["A", "B", "C"], ["D", "E", "F"], ["G", "H", "I"]].map(ligne =>
                            <div key={ligne}
                                 style={{display: "flex", flex: 1, flexDirection: "row", width: "100%"}}>
                                {
                                    ligne.map(value =>
                                        <div key={value} style={{flex: 1, height: "100%"}}>
                                            <div
                                                onClick={() =>
                                                    this.setState({
                                                        row: this.isLineOk(value) ? value : undefined,
                                                        column: undefined
                                                    })}
                                                style={{
                                                    ...this.styleTouch,
                                                    height: "40px",
                                                    backgroundColor: this.state.row === value ? "#337ab7" :
                                                        (this.isLineOk(value) ? "#333" : "#622")
                                                }}>
                                                {value}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <h4>
                    Colonne
                </h4>
                <div style={{flex: 2, display: "flex", flexDirection: "column"}}>
                    {
                        this.state.row ?
                            [[0, 1, 2], [3, 4, 5], [6, 7, 8]].map(ligne =>
                                <div key={ligne}
                                     style={{display: "flex", flex: 1, flexDirection: "row", width: "100%"}}>
                                    {
                                        ligne.map(value =>
                                            <div key={value} style={{flex: 1, height: "100%"}}>
                                                <div
                                                    onClick={() =>
                                                        this.setState({column: this.isColumnOk(value) ? value : undefined})}
                                                    style={{
                                                        ...this.styleTouch,
                                                        height: "40px",
                                                        backgroundColor: this.state.column === value ? "#337ab7" :
                                                            (this.isColumnOk(value) ? "#333" : "#622")
                                                    }}>
                                                    {value}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ) :
                            undefined
                    }
                </div>
                <div style={{width: "100%", marginBottom: "40vh", textAlign: 'center', marginTop: "3vh"}}>
                    <Button
                        variant={"contained"}
                        onClick={() => this.setState({action: this.getAction(this.state.row, this.state.column)})}
                        disabled={!this.state.row || (typeof this.state.column === 'undefined')}>
                        Valider
                    </Button>
                </div>
            </div>
        )
    }
}
