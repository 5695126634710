import React from "react";

class LegalTerms extends React.Component {

    constructor({...props}, context) {
        super();
    };

    render() {
        return (
            <div style={{padding: "20%", paddingTop: "15px", color: "white"}}>
                <h1>Mentions légales</h1>
                <div>
                    <h2>Article 1. Préambule et opposabilité</h2>
                    <div>
                        <p>
                            1. Ce site est la propriété exclusive de Kévin Bienvenu, exploitant du site welcomegames.fr.
                            <br/>
                            2. L’internaute qui souhaite utiliser ce site est supposé avoir pris connaissance et avoir accepté sans réserve les présentes mentions.
                            <br/>
                            3. Il est tenu, à chaque visite, de prendre connaissance d’éventuelles évolutions des présentes mentions.
                            <br/>
                            4. Ces mentions ne sont ni des conditions générales de service, ni des conditions générales de vente. Elles ont vocation à constituer la règle que doit respecter tout internaute visiteur.
                        </p>
                    </div>
                    <h2>Article 2. Mentions d’identification</h2>
                    <div>
                        <p>
                            5. Conformément aux dispositions de l’article 6-III 1e de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, nous vous informons que :
                            <br/>
                            - le présent site est la propriété de Kévin Bienvenu, dont l'adresse physique est située au 14 rue Hélène Loiret, 92190 Meudon, France. Son numéro de téléphone est +33 6 13 59 79 34.
                            <br/>
                            - l’hébergement du site est assuré par OVH, dont l’adresse est OVH 2 rue Kellerman, 59100 Roubaix, France. Son numéro de téléphone est : +1 (866) 278-1349.
                        </p>
                    </div>
                    <h2>Article 3. Assistance technique – Réclamations</h2>
                    <div>
                        <p>
                            6. Welcome Games met tout en œuvre pour apporter un service de qualité et met à la disposition des internautes un service client à même de répondre à tous les renseignements nécessaires à l’utilisation du site welcomegames.fr.
                            <br/>
                            7. En conséquence :
                            <br/>
                            - si vous éprouvez des difficultés ou des insatisfactions liées à l’utilisation du site ;
                            <br/>
                            - si vous souhaitez signaler un contenu abusif ou illégal présent sur le site ;
                            <br/>
                            - si vous êtes victime d’une usurpation d’identité, de manière personnelle ou dans l’administration du compte de votre entreprise :
                            <br/>
                            vous avez la possibilité d’en faire part à welcomegames.fr :
                            <br/>
                            - Par courrier électronique : <email-address address="reclamations"><a href="kevin.bienvenu@battlemythe.net">kevin.bienvenu@battlemythe.net</a></email-address>
                            <br/>
                            - Par téléphone au +33 6 13 59 79 34
                            <br/>
                            8. Welcome Games mettra tout en œuvre pour répondre à votre demande dans les plus brefs délais.
                        </p>
                    </div>
                </div>
            </div>
        );
    };

/*

*/

};

export default LegalTerms;
