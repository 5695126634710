import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {selectPlayer} from "../../../../store/player/selectors";
import {selectIsLoggedIn, selectSessionUser} from "../../../../store/authentication/selectors";
import axios from "axios";
import constants from "../../../../config/constants";
import "./stats.css"
import {withRouter} from "react-router";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
// import * as userStats from "./user.json";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        user: selectSessionUser(state),
        player: selectPlayer(state),
    };
};

const mapActionsToDispatch = dispatch => ({});

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps
});

const dicPlayerStats = [
    {key: 'maxPoints', name: 'Max points'},
    {key: 'minPoints', name: 'Min points'},
    {key: 'maxNt', name: 'Max NT'},
    {key: 'maxBanquier', name: 'Max M€ prod'},
    {key: 'maxScientific', name: 'Max science badges'},
    {key: 'maxThermalist', name: 'Max heat stock'},
    {key: 'maxMiner', name: 'Max Steel & Tit. stock'},
    {key: 'maxZigouigoui', name: 'Max zigouigoui'},
    {key: 'mostPlayedCards', name: 'Max played cards'},
    {key: 'quickestPlayer', name: 'Quickest player'},
    {key: 'slowestPlayer', name: 'Slowiest player'},
];

const dicOtherStats = [
    {key: 'totalTime', name: 'Total game time'},
    {key: 'shortestGame', name: 'Shortest game'},
    {key: 'longestGame', name: 'Longest game'},
    {key: 'totalNbForest', name: 'Total forests'},
    {key: 'totalNbCity', name: 'Total cities'},
];

const dicPlayerGlobal = [
    {key: 'nbGames', name: 'Total games'},
    {key: 'nbWins', name: 'Total wins'},
    {key: 'averagePosition', name: 'Average position'},
    {key: 'averageTime', name: 'Average thinking time'},
    {key: 'totalTimeInGame', name: 'Total game time'},
];

const dicPlayerOther = [
    {key: "mostPlayedCorpos", name: "Most played Corpo"},
    {key: "numberPlayedCorpos", name: "Total played Corpo"},
    {key: "totalPlayableCorpos", name: "Total playable Corpo"},
    {key: "mostPlayedCard", name: "Most played cards"},
    {key: "numberPlayedCard", name: "Total played cards"},
    {key: "numberPlayableCards", name: "Total playable cards"},
];

const dicPlayerBest = [
    {key: 'bestScore', name: 'Max Points'},
    {key: 'bestNT', name: 'Max NT'},
    {key: 'bestBanquier', name: 'Max M€ prod'},
    {key: 'bestScientifique', name: 'Max science badges'},
    {key: 'bestMiner', name: 'Max steel & tit. stock'},
    {key: 'bestThermalist', name: 'Max heat stock'},
];

const dicPlayerTotal = [
    {key: 'totalCardPlayed', name: 'Cards played'},
    {key: 'totalZigouigoui', name: 'Zigouigoui'},
    {key: 'totalBadges', name: 'Badges'},
    {key: 'totalNT', name: 'NT'},
    {key: 'totalTiles', name: 'Tiles'},
    {key: 'totalCity', name: 'Cities'},
    {key: 'totalForest', name: 'Forests'},
];


export const Stats = () => {
    const [stats, setStats] = useState({});
    const [statsUsers, setStatsUsers] = useState({});
    const [userId, setUserId] = useState("");
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectSessionUser);

    useEffect(() => {
        axios.get(constants.api.basePath + "/terraforming/stats").then(data => {
            setStats(data.data);
            selectUser({userId: user._id + "", username: user.username});
        });
    }, [isLoggedIn]);

    function selectUser(user) {
        handleCloseMenu();
        setStatsUsers({});
        setUserId(user.username);
        axios.get(constants.api.basePath + "/terraforming/stats/user/" + user.userId).then(data => {
            setStatsUsers(data.data);
            setUserId(user.username);
        });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    if (!stats || !stats.playerStats) {
        return <div style={{width: "100%", height: "90vh"}}>
            LOADING STATISTICS
        </div>
    }
    let corpos = [];
    if (statsUsers && statsUsers.corpos) {
        corpos = Object.keys(statsUsers.corpos);
        corpos = corpos.sort((c1, c2) => statsUsers.corpos[c1].nbWin / statsUsers.corpos[c1].nbPlayed >
        statsUsers.corpos[c2].nbWin / statsUsers.corpos[c2].nbPlayed ?
            -1 : (statsUsers.corpos[c1].nbWin / statsUsers.corpos[c1].nbPlayed <
            statsUsers.corpos[c2].nbWin / statsUsers.corpos[c2].nbPlayed ? 1 :
                statsUsers.corpos[c1].nbPlayed > statsUsers.corpos[c2].nbPlayed ? -1 : 1));
    }
    const users = [...stats.users];
    users.sort((u1, u2) => u1.username.toLowerCase() < u2.username.toLowerCase() ? -1 : 1);
    return <Box className="board-game main" sx={{width: {xs: "80%", md: "50%"}}} style={{fontSize: Math.min(window.innerWidth * 0.052, 30) + "px"}}>
        <h2>General Statistics</h2>
        <div className="stats-table">
            <div className="stats-row" style={{marginBottom: "12px"}}>
                <div className="stats-key">
                    Total games played
                </div>
                <div className="stats-value">
                    {stats.nbGames}
                </div>
            </div>
            <div className="stats-row">
                <div className="stats-key">
                    Player with most games
                </div>
                <div className="stats-value">
                    {stats.userMostGameUsername}
                </div>
                <div className="stats-value">
                    {stats.userMostGameNbGame}
                </div>
            </div>
            <div className="stats-row" style={{marginBottom: "12px"}}>
                <div className="stats-key">
                    Player with most wins
                </div>
                <div className="stats-value">
                    {stats.userMostWinsUsername}
                </div>
                <div className="stats-value">
                    {stats.userMostWinsNbGame}
                </div>
            </div>
            <div className="stats-row">
                <div className="stats-key">
                    Most played corporation
                </div>
                <div className="stats-value">
                    {stats.corpoMostGameCorpo}
                </div>
                <div className="stats-value">
                    {stats.corpoMostGameNbGame}
                </div>
            </div>
            <div className="stats-row" style={{marginBottom: "12px"}}>
                <div className="stats-key">
                    Most wins corporation
                </div>
                <div className="stats-value">
                    {stats.corpoMostWinsUsername}
                </div>
                <div className="stats-value">
                    {stats.corpoMostWinsNbGame}
                </div>
            </div>
        </div>
        <h2>Records</h2>
        <div className="stats-table">
            {
                dicPlayerStats.map(element => <div className="stats-row" key={element.key}>
                    <div className="stats-key">
                        {element.name}
                    </div>
                    <div className="stats-value">
                        {stats.playerStats[element.key].username}
                    </div>
                    <div className="stats-value">
                        {stats.playerStats[element.key].value}
                    </div>
                </div>)
            }
        </div>
        <h2>Other Stats</h2>
        <div className="stats-table">
            {
                dicOtherStats.map(element => <div className="stats-row" key={element.key}>
                    <div className="stats-key">
                        {element.name}
                    </div>
                    <div className="stats-value">
                        {stats.otherStats[element.key]}
                    </div>
                </div>)
            }
        </div>
        <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <h2>
                Players
            </h2>
            <Button
                variant={"contained"}
                id={"button-user"} onClick={handleOpenMenu}>
                {userId}
            </Button>
            <Menu
                id={"menu-user"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                style={{
                    float: "right",
                    marginTop: "12px",
                    marginRight: "6vw",
                    maxHeight: "80vg",
                    overflowY: "scroll"
                }}
            >
                {
                    users.map(user =>
                        <MenuItem key={user.username} onClick={() => selectUser(user)}>
                            {user.username}
                        </MenuItem>
                    )
                }
            </Menu>
        </div>
        {
            statsUsers && statsUsers.global ?
                <div>
                    <div className="stats-table">
                        {
                            dicPlayerGlobal.map(element => <div className="stats-row" key={element.key}>
                                <div className="stats-key">
                                    {element.name}
                                </div>
                                <div className="stats-value">
                                    {element.key === "averagePosition" ?
                                        statsUsers.global[element.key].toFixed(3) : statsUsers.global[element.key]}
                                </div>
                            </div>)
                        }
                    </div>
                    <div className="stats-table">
                        {
                            dicPlayerOther.map(element => <div className="stats-row" key={element.key}>
                                <div className="stats-key">
                                    {element.name}
                                </div>
                                <div className="stats-value">
                                    {statsUsers.other[element.key]}
                                </div>
                            </div>)
                        }
                    </div>
                    <div className="stats-table">
                        <div className="stats-row">
                            <div className="stats-key">
                            </div>
                            <div className="stats-value">
                                LAST
                            </div>
                            <div className="stats-value">
                                BEST
                            </div>
                        </div>
                        {
                            dicPlayerBest.map(element => <div className="stats-row" key={element.key}>
                                <div className="stats-key">
                                    {element.name}
                                </div>
                                <div className="stats-value">
                                    {(statsUsers.best["last" + element.key.substring(4, element.key.length)])}
                                </div>
                                <div className="stats-value">
                                    {(statsUsers.best[element.key])}
                                </div>
                            </div>)
                        }
                    </div>
                    <div className="stats-table">
                        <div className="stats-row">
                            <div className="stats-key">
                            </div>
                            <div className="stats-value">
                                LAST
                            </div>
                            <div className="stats-value">
                                AVG
                            </div>
                        </div>
                        {
                            dicPlayerTotal.map(element => <div className="stats-row" key={element.key}>
                                <div className="stats-key">
                                    {element.name}
                                </div>
                                <div className="stats-value">
                                    {statsUsers.last[element.key]}
                                </div>
                                <div className="stats-value">
                                    {(statsUsers.total[element.key] / statsUsers.global.nbGames).toFixed(1)}
                                </div>
                            </div>)
                        }
                    </div>
                    <div className="stats-table">
                        <div className="stats-row">
                            <div className="stats-key">
                            </div>
                            <div className="stats-value">
                                GAMES
                            </div>
                            <div className="stats-value">
                                WINS
                            </div>
                        </div>
                        {
                            corpos.map(corpo => <div className="stats-row" key={corpo}>
                                <div className="stats-key">
                                    {corpo}
                                </div>
                                <div className="stats-value">
                                    {(statsUsers.corpos[corpo].nbPlayed)}
                                </div>
                                <div className="stats-value">
                                    {(statsUsers.corpos[corpo].nbWin)}
                                </div>
                            </div>)
                        }
                    </div>
                    <div className="stats-table">
                        {
                            statsUsers.cards.map(element => <div className="stats-row" key={element.name}>
                                <div className="stats-key">
                                    {element.name}
                                </div>
                                <div className="stats-value">
                                    {element.count}
                                </div>
                            </div>)
                        }
                    </div>
                </div>
                : undefined
        }
    </Box>
}


export default Stats;
