import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import Routes from './routes';
import {createBrowserHistory} from 'history';
import getStore from './store';
import './index.css';
import {ThemeProvider , createTheme} from "@mui/material/styles";
import {TopBar} from "./app/utils/TopBar/TopBar";
import {BottomBar} from "./app/utils/BottomBar/BottomBar";
import ScrollToTop from "./app/utils/ScrollToTop";

const store = getStore();

const history = createBrowserHistory();

const theme = createTheme({
    palette: {
        primary: {
            main: '#1f70ea',
        },
        secondary: {
            main: '#EA991F'
        },
        success: {
            main: '#70EA1F'
        },
        error: {
            main: '#EA1F70'
        },
        info : {
            main: '#29b6f6',
        },
        background: {
            default: '#191b31'
        },
        action: {
            disabledBackground: "rgba(0, 0, 0, 0.3)",
            disabled: 'gray'
        }
    },
    typography: {
        fontFamily: "Play"
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "white",
                    border: "1px solid #1f2136",
                    backgroundColor: "black"
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "#10346f",
                    color: "white",
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: "black 0 0 10px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: "#1f2136",
                    color: "white",
                }
            }
        },
        MuiTableHead: {styleOverrides: {root: {backgroundColor: "#10346f"}}},
        MuiPaper: {styleOverrides: {root: {backgroundColor: "#1f2136", color: "white"}}},
    }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store} style={{backgroundColor: "#000"}}>
                <Router history={history}>
                    <ScrollToTop/>
                    {Routes()}
                </Router>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);
