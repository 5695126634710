import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSessionUser, selectUsers} from "../../../store/authentication/selectors";
import {fetchUsers, patchCanPlay7wonders, setCanPlay7wonders} from "../../../store/authentication/actions";
import TableContainer from "@mui/material/TableContainer";
import {Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";


export const AdminPage = () => {

    const caller = useSelector(selectSessionUser);
    const users = useSelector(selectUsers);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    function handleCanPlay7wonders(user, event){
        dispatch(patchCanPlay7wonders(caller, user, event.target.checked));
        dispatch(setCanPlay7wonders(user._id, event.target.checked));
    }

    return <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
        <div style={{marginBottom: "3vh"}}>
            <h2>
                Availables games
            </h2>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>BoardGame</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="bodyAvailablesGames">
                        {
                            users.map((user, index) => (
                                <TableRow key={"table-game-" + index}>
                                    <TableCell style={{"verticalAlign": "middle"}}>
                                        {user.username}
                                    </TableCell>
                                    <TableCell style={{"verticalAlign": "middle"}}>
                                        {user.email}
                                    </TableCell>
                                    <TableCell className="col-xs-2" style={{"verticalAlign": "middle"}}>
                                        <Checkbox checked={user.canPlay7wonders} onChange={(event) => handleCanPlay7wonders(user, event)}/>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </Box>
};

export default AdminPage;
