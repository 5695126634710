import {RESET_DATA_COLLECTION, ADD_TO_DATA_COLLECTION} from "./actions";

export default (state = {}, action = {}) => {
    const data = {...state};
    switch (action.type) {
        case RESET_DATA_COLLECTION:
            data[action.collection] = [];
            return data;
        case ADD_TO_DATA_COLLECTION:
            data[action.collection] = [...(data[action.collection] ? data[action.collection] : []), action.dataContent];
            return data;
        default:
            return state;
    }
};
