import React from "react";
import "./BottomBar.css";

import {selectShouldRefresh} from "../../../store/player/selectors";
import {selectIsLoggedIn, selectSessionUser} from "../../../store/authentication/selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        isLoggedIn: selectIsLoggedIn(state),
        selectShouldRefresh: selectShouldRefresh(state),
        user: selectSessionUser(state)
    };
};

const mapActionsToDispatch = dispatch => ({});

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps
});


export const BottomBar = () => {

    if (window.location.pathname.startsWith("/monitor") ||
        window.location.pathname.startsWith("/terraforming") ||
        window.location.pathname.startsWith("/throughTheAges") ||
        window.location.pathname.startsWith("/megawatt") ||
        window.location.pathname.startsWith("/7Wonders")) {
        return undefined;
    }

    return (
        <div className={"navbar"}>
            <div>
                <a href="/killing-zeus">
                    Killing&nbsp;Zeus
                </a> - <a href="/ultra-myth">
                    RTS&nbsp;Ultra&nbsp;Myth
                </a> - <a href="/confidentiality">
                    Confidentiality&nbsp;policy
                </a> - <a href="/legalterms">
                    Legal&nbsp;terms
                </a> - <a href="/">
                    welcomegames.fr
                </a>
            </div>
            <div style={{marginTop: "5px"}}>
                Welcome Games - December 2023
            </div>
        </div>
    );
};

export default BottomBar;
