import React from "react";

export default class StateDefaultTTA extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    getPlayer() {
        return this.props.state.networkPlayers.filter(np => np.userId === this.props.userId)[0];
    }

    render() {
        let actions = this.props.state.actions[this.props.userId];
        return <div style={{
            width: "100%", height: "100%", di1splay: "flex", flexDirection: "column", padding: "10px"
        }}>
            {
                actions.map(action => <div
                    onClick={() => this.emitState(action)}
                    style={{
                    width: "80%",
                    maxWidth: "200px",
                    height: "60px",
                    backgroundColor: action.ok ? "#0089b3" : "#723b32",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "15px 20%",
                    color: "white",
                    cursor: "pointer"
                }}
                    key={action.name}>
                    {action.name}
                </div>)
            }
        </div>
    }


    emitState(action) {
        this.props.socket.emit("action", action);
    }


}
