import {
    SET_USER,
    SIGNIN_ERROR,
    RESET_ERROR,
    ADD_PREVIOUS_GAME,
    SET_EMAIL_VERIFIED,
    SET_USERS,
    SET_CAN_PLAY_7_WONDERS
} from "./actions";

export default (state = {user:{}, users: []}, action = {}) => {
    switch (action.type) {
        case SET_EMAIL_VERIFIED:
            return {
                ...state,
                user: {
                    ...state.user,
                    verifiedEmail: true
                }
            };
        case SET_USER:
            return {
                ...state,
                user: action.user ? action.user : {}
            };
        case SIGNIN_ERROR:
            return {
                ...state,
                error: action.error
            };
        case SET_USERS:
            return {
                ...state,
                users: action.users
            };
        case SET_CAN_PLAY_7_WONDERS:
            return {
                ...state,
                users: state.users.map(user => {
                    if(user._id === action.userId){
                        return {...user, canPlay7wonders: action.canPlay7wonders};
                    } else {
                        return {...user}
                    }
                })
            };
        case RESET_ERROR:
            let newState = {...state};
            delete newState.error;
            return newState;
        default:
            return state;
    }
};
