import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export default class ActionVenteBrevet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kept: [],
            show: false,
            action: undefined
        };
    }

    componentDidMount() {

    }

    styleButton = {
        backgroundColor: "#444",
        padding: "5px",
        borderRadius: "3px",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "25%",
        width: "50%"
    };

    render() {
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={"Voulez-vous vendre ces cartes ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            overflow: "hidden", width: "90%", height: "96%", color: "#DDD"
        }}>
            {modal}
            <div style={{
                width: "100%",
                marginTop: "5%",
                display: 'inline-flex',
                flexWrap: 'wrap'
            }}>
                {
                    this.props.cards.map(c => c.value).map(cardId =>
                        <div key={cardId}
                             style={{height: "100%", display: "flex", flexDirection: 'column', alignItems: 'center', margin: "2vh 4px"}}>
                            <img src={"/public/terraforming/cards/img_" + cardId + ".png"}
                                 alt={"img " + cardId}
                                 style={{width: "20vw", height: "auto"}}>
                            </img>
                            <br/>
                            {
                                this.state.kept.indexOf(cardId) > -1 ?
                                    <Button variant={"contained"}
                                            onClick={() => this.setState((prevState) => {
                                                let kept = prevState.kept;
                                                if (kept.indexOf(cardId) > -1) {
                                                    kept.splice(kept.indexOf(cardId), 1);
                                                }
                                                return {
                                                    kept
                                                }
                                            })}>
                                        <TaskAltIcon/>
                                    </Button> : <Button variant={"outlined"}
                                                        onClick={() => this.setState((prevState) => {
                                                            let kept = prevState.kept;
                                                            if (kept.indexOf(cardId) === -1) {
                                                                kept.push(cardId);
                                                            }
                                                            return {
                                                                kept
                                                            }
                                                        })}>
                                        <RadioButtonUncheckedIcon/>
                                    </Button>
                            }
                        </div>
                    )
                }
            </div>
            <div style={{width: '100%', textAlign: 'center', marginTop: '3vh'}}>
                <Button variant={"contained"}
                        disabled={!this.state.kept.length}
                        onClick={() => {
                            this.setState({
                                show: true, action: {
                                    soldCardIds: this.state.kept,
                                    ok: true,
                                    userId: this.props.userId,
                                    class: 'model.actions.main.ActionVenteDeBrevet'
                                }
                            });
                        }}>
                    Vendre {this.state.kept.length} carte{this.state.kept.length > 1 ? 's' : ''}
                </Button>
            </div>
        </div>
    }
}
