import React, {useEffect, useState} from "react";


import {CardCarousel} from "../components/CardCarousel";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {PayMoney} from "../components/PayMoney";
import Box from "@mui/material/Box";
import WonderFacePresentation from "../components/WonderFacePresentation";


export const CardChoice = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        card: false,
        action: false,
        currentAction: false,
        wonder: false
    });

    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];
    const cards = [];
    for (let action of actions) {
        if (cards.map(c => c.building.idname).indexOf(action.card.building.idname) === -1) {
            cards.push(action.card);
        }
    }


    useEffect(() => {
        if(!state.action || !state.card && !state.init){
            handleChangeActiveCard(0);
        }
    }, [state]);


    function clickOnDiscard() {
        setState(state => ({
            ...state,
            action: actions.filter(a => a.card.building.idname === state.card.building.idname && a.class === "action.mainturn.ActionDiscardCard")[0],
            show: true
        }));
    }

    function handleChangeActiveCard(index) {
        setState(state => ({
            ...state,
            action: actions.filter(a => a.card.building.idname === cards[index].building.idname && a.class === (state.wonder ? "action.mainturn.ActionBuildWonderFloorWithCard" : "action.mainturn.ActionBuildCard"))[0],
            card: cards[index],
            init: true
        }));
    }

    function handleSwitchWonder(wonder) {
        setState(state => ({
            ...state,
            action: actions.filter(a => a.card.building.idname === state.card.building.idname && a.class === (wonder ? "action.mainturn.ActionBuildWonderFloorWithCard" : "action.mainturn.ActionBuildCard"))[0],
            wonder
        }));
    }

    function getBuildComponent(action){
        if(!action.ok){
            if(action.reasonNotPossible === "ALREADY_BUILT"){
                return <div>Vous possédez déjà ce bâtiment</div>
            }
            return <div>Vous ne pouvez pas construire {state.wonder ? "cet étage" : "ce bâtiment"}</div>
        }
        const payMoney = action.payMoney;
        if(action.reasonForFree && action.reasonForFree === "FREE_CHAINAGE"){
            return <div>Vous pouvez <b>chaîner</b> pour construire ce bâtiment gratuitement</div>
        } else if(action.reasonForFree && action.reasonForFree === "FREE_BUILDING"){
            return <div>Utiliser votre <b>pouvoir</b> et construire ce bâtiment gratuitement</div>
        } else if(action.reasonForFree && action.reasonForFree === "FREE_CITIES"){
            return <div>Utiliser votre <b>pouvoir</b> et construire cette carte noire gratuitement</div>
        } else if(action.reasonForFree){
            return <div>Vous pouvez construire ce bâtiment <b>gratuitement</b></div>
        }
        if(payMoney.nbCoinLeft + payMoney.nbCoinRight + payMoney.nbCoinBank === 0){
            return <div>Vous avez toutes les ressources pour construire {state.wonder ? "cet étage" : "ce bâtiment"}</div>
        } else {
            return <PayMoney payMoney={payMoney}/>
        }
    }

    if (!state.card) {
        return <div></div>;
    }
    let currentActionName = "?";
    if (state.action.class === "action.mainturn.ActionDiscardCard") {
        currentActionName = "Êtes-vous sûr de vouloir défausser la carte " + state.card.building.name + " ?";
    } else if (state.action.class === "action.mainturn.ActionBuildCard") {
        currentActionName = "Êtes-vous sûr de vouloir construire la carte " + state.card.building.name + " ?";
    } else {
        currentActionName = <span>Êtes-vous sûr de vouloir construire un étage de merveille avec la carte <b>{state.card.building.name}</b> ?</span>;
    }
    const wonderActions = actions.filter(action => action.class === "action.mainturn.ActionBuildWonderFloorWithCard" && action.card.building.idname === state.card.building.idname);
    const buildingActions = actions.filter(action => action.class === "action.mainturn.ActionBuildCard" && action.card.building.idname === state.card.building.idname);
    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={currentActionName}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                Tour principal
            </h2>
            <div style={{
                width: "100%",
                textAlign: "center",
                margin: "2vh 0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "7vh",
                justifyContent: "center"
            }}>
                <div
                    className={"board-game-home-top-button board-game-home-top-button-" + (!wonderActions.length ? "disabled" : (state.wonder ? "active" : "inactive"))}
                    onClick={() => wonderActions.length ? handleSwitchWonder(true): undefined}>
                    Merveille
                </div>
                <div
                    className={"board-game-home-top-button board-game-home-top-button-" + (state.wonder ? "inactive" : "active")}
                    onClick={() => handleSwitchWonder(false)}>
                    Carte
                </div>
            </div>
            <div style={{height: "40vh"}}>
                {
                    state.wonder ?
                        <div style={{width: "100%", textAlign: "center", position: 'relative', display: 'flex', height: '100%', flexDirection: 'column'}}>
                            {
                                player.wonder['face' + player.wonderFace].floors.map((floor, i) => <img key={i}
                                            src={"../../../public/7wonders/images/wonders/" + player.wonder.idname + player.wonderFace + (i + 1) + ".png"}
                                            style={{height: 100 / player.wonder['face' + player.wonderFace].floors.length + '%', width: "auto", margin: "auto", opacity: i === player.wonderFloorBuilt ? '100%' : '30%'}}/>
                                )
                            }
                            <img alt={"building"}
                                src={"../../../public/7wonders/images/cartes/" + state.card.building.idname + ".png"}
                                style={{
                                    position: 'absolute', zIndex: -1000,
                                    height: 100 / player.wonder['face' + player.wonderFace].floors.length + '%',
                                    width: 'auto', left: '-10vw', top: 100 * player.wonderFloorBuilt / player.wonder['face' + player.wonderFace].floors.length + '%'}}>
                            </img>
                        </div>:
                        <CardCarousel activeIndex={cards.map(c => c.building.idname).indexOf(state.card.building.idname)}
                                      cards={cards.map(c => c.building)}
                                      onSelect={handleChangeActiveCard} key={"carouselCards"}/>
                }
            </div>
            <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                {
                    buildingActions[0] && buildingActions[0].payMoney && buildingActions[0].payMoney.cheaper ?
                        <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                            Vous payez {buildingActions[0].payMoney.cheaper} ressource{buildingActions[0].payMoney.cheaper > 1 ? 's' :''} en moins pour {state.wonder ? ' cet étage': ' ce bâtiment'}
                        </div>:
                        undefined
                }
                {
                    buildingActions[0] && buildingActions[0].payMoney && buildingActions[0].payMoney.cheaperCoin ?
                        <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                            Vous payez {buildingActions[0].payMoney.cheaperCoin} pièce{buildingActions[0].payMoney.cheaperCoin > 1 ? 's' :''} d'or en moins pour {state.wonder ? ' cet étage': ' cette carte'}
                        </div>:
                        undefined
                }
                {
                    (state.wonder ? wonderActions : buildingActions).map((a, i) => <Box className={"board-game-build-action"}
                                                       sx={{width: {xs: "120%", md: "100%"}, marginLeft: {xs: "-13%", md: "-3%"}}}
                                                       key={i}>
                        <div className="board-game-build-action-container">
                            {getBuildComponent(a)}
                        </div>
                        <Button variant="contained" disabled={!a.ok} onClick={() => setState(state => ({...state, action: a, show: true}))}>
                            Construire
                        </Button>
                    </Box>)
                }

            </div>

            <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                <Button disabled={state.wonder} variant="contained" onClick={() => clickOnDiscard()}>
                    Défausser
                </Button>
            </div>
            {
                state.wonder ?
                    <Card className="panel panel-primary" style={{marginTop: 10, marginBottom: 20}}>
                        <CardContent className="panel-heading">
                            <div className={"card-title"}>
                                Description : Un étage de merveille
                            </div>
                            <div className="panel-body">
                                En utilisant la carte {state.card.building.name}
                            </div>
                        </CardContent>
                    </Card> :
                    <Card className="panel panel-primary" style={{marginTop: 10, marginBottom: 20}}>
                        <CardContent className="panel-heading">
                            <div className={"card-title"}>
                                Description : {state.card.building.name}
                            </div>
                            <div className="panel-body">
                                <p>{state.card.building.description}</p>
                            </div>
                        </CardContent>
                    </Card>
            }
            <div style={{height: "25vh"}}>

            </div>
        </div>
    )

};

export default CardChoice;
