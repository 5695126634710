
export const cyan = "rgb(81, 171, 158)";
export const green = "rgb(102, 132, 69)";
export const red = "rgb(183, 106, 96)";
export const blue = "rgb(119, 127, 176)";
export const yellow = "rgb(185, 162, 80)";
export const magenta = "rgb(173, 147, 173)";

export const dicCities = {
    CALAIS: {color: green, name: "CALAIS"},
    LILLE: {color: green, name: "LILLE"},
    AMIENS: {color: green, name: "AMIENS"},
    LE_HAVRE: {color: magenta, name: "LE HAVRE"},
    PARIS_1: {color: blue, name: "PARIS"},
    PARIS_2: {color: blue, name: "PARIS"},
    PARIS_3: {color: blue, name: "PARIS"},
    ROUEN: {color: magenta, name: "ROUEN"},
    METZ: {color: green, name: "METZ"},
    REIMS: {color: green, name: "REIMS"},
    CAEN: {color: magenta, name: "CAEN"},
    BREST: {color: magenta, name: "BREST"},
    RENNES: {color: magenta, name: "RENNES"},
    NANTES: {color: red, name: "NANTES"},
    ANGERS: {color: magenta, name: "ANGERS"},
    LE_MANS: {color: magenta, name: "LE MANS"},
    TOURS: {color: blue, name: "TOURS"},
    ORLEANS: {color: blue, name: "ORLEANS"},
    NANCY: {color: green, name: "NANCY"},
    STRASBOURG: {color: green, name: "STRASBOURG"},
    MULHOUSE: {color: yellow, name: "MULHOUSE"},
    DIJON: {color: yellow, name: "DIJON"},
    BESANCON: {color: yellow, name: "BESANCON"},
    LA_ROCHELLE: {color: red, name: "LA ROCHELLE"},
    LIMOGES: {color: blue, name: "LIMOGES"},
    CLERMONT_FERRAND: {color: blue, name: "CLERMONT FER."},
    LYON: {color: yellow, name: "LYON"},
    CHAMONIX: {color: yellow, name: "CHAMONIX"},
    SAINT_ETIENNE: {color: yellow, name: "SAINT ETIENNE"},
    GRENOBLE: {color: yellow, name: "GRENOBLE"},
    MARSEILLE: {color: cyan, name: "MARSEILLE"},
    AIX_EN_PROVENCE: {color: cyan, name: "AIX EN PRO."},
    NICE: {color: cyan, name: "NICE"},
    NIMES: {color: cyan, name: "NIMES"},
    TOULON: {color: cyan, name: "TOULON"},
    MONTPELLIER: {color: cyan, name: "MONTPELLIER"},
    BORDEAUX: {color: red, name: "BORDEAUX"},
    TOULOUSE: {color: red, name: "TOULOUSE"},
    BIARRITZ: {color: red, name: "BIARRITZ"},
    LOURDES: {color: red, name: "LOURDES"},
    CARCASSONE: {color: red, name: "CARCASSONE"},
    PERPIGNAN: {color: cyan, name: "PERPIGNAN"}
}
