import React from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import {dicCities} from "../Utils";

export default class StateBuyHouse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            action: false
        };
        this.getPlayer = this.getPlayer.bind(this);
        this.getActions = this.getActions.bind(this);
        this.getBuyActions = this.getBuyActions.bind(this);
    }


    componentDidMount() {

    }

    getPlayer() {
        return this.props.state.networkPlayers.filter(np => np.userId === this.props.userId)[0];
    }

    getActions() {
        return this.props.state.actions[this.props.userId];
    }

    getBuyActions() {
        let a = this.getActions().filter(a => a.class === "action.buyHouse.ActionBuyHouse");
        a.sort((a, b) => a.price < b.price ? -1 : (a.price > b.price ? 1 : a.cities < b.cities ? -1 : 1));
        return a;
    }

    getModalText(action) {
        if (action.class === "action.buyHouse.ActionBuyHouse") {
            return "Etes-vous sûr de d'acheter une maison à " + dicCities[action.cities].name + " pour " + action.price + " ?";
        } else {
            return "Etes-vous sûr de ne plus acheter de maison ?";
        }
    }

    render() {
        let modal = ConfirmModal(this.state.show ? this.state.action : undefined,
            this.getModalText(this.state.action),
            this.props.socket, () => this.setState({show: false}));
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            {modal}
            <div style={{
                width: "100%",
                paddingTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <div style={{
                    marginBottom: "20px",
                    color: "white",
                    fontSize: "20px"
                }}>
                    C'est à vous d'acheter une maison
                </div>
                <div style={{
                    width: "100%",
                    marginTop: "25px",
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <div style={{flex: 1}}>
                        <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                            {
                                this.getBuyActions().slice(0, Math.floor(this.getBuyActions().length / 2)).map(a => <div
                                    onClick={() => {
                                        this.setState({action: a, show: true});
                                    }}
                                    key={a.class + a.cities}
                                    style={{
                                        height: "40px",
                                        borderRadius: "5px",
                                        backgroundColor: dicCities[a.cities].color,
                                        color: "white",
                                        marginBottom: "5px",
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    <div style={{
                                        flex: 4,
                                        display: "flex",
                                        height: "100%",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        paddingLeft: "12px"
                                    }}>
                                        {dicCities[a.cities].name}
                                    </div>
                                    <div style={{
                                        flex: 1,
                                        display: "flex",
                                        height: "100%",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        paddingRight: "12px"
                                    }}>
                                        {a.price}
                                    </div>

                                </div>)
                            }
                        </div>
                    </div>
                    <div style={{flex: 1}}>
                        <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                            {
                                this.getBuyActions().slice(Math.floor(this.getBuyActions().length / 2), this.getBuyActions().length).map(a =>
                                    <div
                                        onClick={() => {
                                            this.setState({action: a, show: true});
                                        }}
                                        key={a.class + a.cities}
                                        style={{
                                            height: "40px",
                                            borderRadius: "5px",
                                            backgroundColor: dicCities[a.cities].color,
                                            color: "white",
                                            marginBottom: "5px",
                                            display: "flex",
                                            flexDirection: "row"
                                        }}
                                    >
                                        <div style={{
                                            flex: 4,
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            fontWeight: "bold",
                                            paddingLeft: "12px"
                                        }}>
                                            {dicCities[a.cities].name}
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            fontWeight: "bold",
                                            paddingRight: "12px"
                                        }}>
                                            {a.price}
                                        </div>

                                    </div>)
                            }
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => this.setState({
                        action: this.getActions()[this.getActions().length - 1],
                        show: true
                    })}
                    style={{
                    height: "40px",
                    borderRadius: "5px",
                    backgroundColor: "#777",
                    color: "white",
                    marginBottom: "5px",
                    padding: "5%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    Ne plus acheter de maisons
                </div>
            </div>
        </div>
    }
}
