import React from "react";
import {getCountryFlagName} from "./utils";
import {PartyComponent} from "./PartyComponent";

export const CountryInvestPage = (props) => {

    function emitOrderFactoryInvestment(modification) {
        const newOrderFactoryInvestment = Math.max(0, props.world.countries[props.country].orderFactoryInvestment + modification);
        const newOrder = {
            countryName: props.country,
            playerName: props.player,
            orderFactoryInvestment: newOrderFactoryInvestment
        };
        props.emitOrderCountry(newOrder);
        props.world.countries[props.country].orderFactoryInvestment = newOrderFactoryInvestment;
    }

    function emitOrderInfrastructureInvestment(modification) {
        const newOrderInvestment = Math.max(0, props.world.countries[props.country].orderInfrastructureInvestment + modification);
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderInfrastructureInvestment: newOrderInvestment
        });
        props.world.countries[props.country].orderInfrastructureInvestment = newOrderInvestment;
    }

    function emitOrderPopulationInvestment(modification) {
        const newOrderInvestment = Math.max(0, props.world.countries[props.country].orderPopulationInvestment + modification);
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderPopulationInvestment: newOrderInvestment
        });
        props.world.countries[props.country].orderPopulationInvestment = newOrderInvestment;
    }

    function emitOrderPoliticalInvestment(party, modification) {
        const newOrderPoliticalFinances = {};
        newOrderPoliticalFinances[party] = Math.max(0, ((props.world.countries[props.country].orderPoliticalInvestment[party] || 0) + modification));
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderPoliticalInvestment: newOrderPoliticalFinances
        });
        props.world.countries[props.country].orderPoliticalInvestment[party] = newOrderPoliticalFinances[party]
    }

    return (
        <div>
            <h2 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img
                    style={{height: '34px', width: 'auto', marginRight: '24px'}}
                    src={"/public/anniv/2024/flags/" + getCountryFlagName(props.country) + ".png"}/>
                {props.country}
            </h2>
            <div>
                <h2>
                    {props.editable ? "Vous investissez" : "Le pays investit"}
                </h2>
                <div>
                    <div className={"investment-box-title"}>
                        Dans les usines du pays ({props.country})
                    </div>
                    <div className={"invest-box"}>
                        <div className={"invest-box-left"}>
                            <div></div>
                            <img src={"/public/anniv/2024/factory.png"}/>
                            <div>{props.world.countries[props.country].nbFactories}</div>
                        </div>
                        <div className={"invest-box-right"}>
                            <div className={"invest-first-row"}>
                                        <span>
                                            Argent accumulé:
                                        </span>
                                <span>
                                            {props.world.countries[props.country].accumulatedFactoryMoney} / {props.world.countries[props.country].priceNextFactory}
                                        </span>
                            </div>
                            <div className={"invest-row"}>
                                        <span>
                                            Investissement:
                                        </span>
                                <span>
                                            {props.world.countries[props.country].factoryInvestment}
                                        </span>
                            </div>
                            <div className={"invest-row"}>
                                        <span>
                                            Investissment cible:
                                        </span>
                                <span>
                                            {props.world.countries[props.country].orderFactoryInvestment}
                                        </span>
                            </div>
                            {
                                props.editable && <div className={"invest-row"}>
                                    {
                                        [-5, -1, 1, 5].map(modification =>
                                            <button
                                                disabled={(modification < 0 && props.world.countries[props.country].orderFactoryInvestment <= 0)}
                                                onClick={() => emitOrderFactoryInvestment(modification)}
                                                key={modification}
                                                className={"small-button"}>
                                                {modification}
                                            </button>
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        Augmenter votre nombre d'usines augmente votre nombre de ressources produites et votre consommation maximale. Mais attention, c'est au détriment des autres joueurs.
                        <br/>
                        <br/> 
                    </div>
                    <div className={"investment-box-title"}>
                        Dans l'infrastructure du continent
                        ({props.world.countries[props.country].continentName})
                    </div>
                    <div className={"invest-box"}>
                        <div className={"invest-box-left"}>
                            <div></div>
                            <img src={"/public/anniv/2024/infrastructure.png"}/>
                            <div>x{1 + 0.1 * props.world.continents[props.world.countries[props.country].continentName].infrastuctureLevel}</div>
                        </div>
                        <div className={"invest-box-right"}>
                            <div className={"invest-first-row"}>
                                        <span>
                                            Argent accumulé:
                                        </span>
                                <span>
                                            {props.world.continents[props.world.countries[props.country].continentName].accumulatedInfrastructureMoney} / {props.world.continents[props.world.countries[props.country].continentName].priceNextInfrastructureLevel}
                                        </span>
                            </div>
                            <div className={"invest-row"}>
                                        <span>
                                            Investissement:
                                        </span>
                                <span>
                                            {props.world.countries[props.country].infrastructureInvestment}
                                        </span>
                            </div>
                            <div className={"invest-row"}>
                                        <span>
                                            Investissment cible:
                                        </span>
                                <span>
                                            {props.world.countries[props.country].orderInfrastructureInvestment}
                                        </span>
                            </div>
                            {
                                props.editable && <div className={"invest-row"}>
                                    {
                                        [-5, -1, 1, 5].map(modification =>
                                            <button
                                                disabled={(modification < 0 && props.world.countries[props.country].orderInfrastructureInvestment <= 0)}
                                                onClick={() => emitOrderInfrastructureInvestment(modification)}
                                                key={modification}
                                                className={"small-button"}>
                                                {modification}
                                            </button>
                                        )
                                    }
                                </div>
                            }
                            <br/>
                        </div>
                    </div>
                    <div>
                        Investir dans l'infrastructure du continent augmente le nombre de ressources que chaque pays du continent produit.
                        <br/>
                        <br/>
                    </div>
                    <div className={"investment-box-title"}>
                        Dans la population du continent
                        ({props.world.countries[props.country].continentName})
                    </div>
                    <div className={"invest-box"}>
                        <div className={"invest-box-left"}>
                            <div></div>
                            <img src={"/public/anniv/2024/population.png"}/>
                            <div>x{1 + 0.1 * props.world.continents[props.world.countries[props.country].continentName].populationLevel}</div>
                        </div>
                        <div className={"invest-box-right"}>
                            <div className={"invest-first-row"}>
                                        <span>
                                            Argent accumulé:
                                        </span>
                                <span>
                                            {props.world.continents[props.world.countries[props.country].continentName].accumulatedPopulationMoney} / {props.world.continents[props.world.countries[props.country].continentName].priceNextPopulationLevel}
                                        </span>
                            </div>
                            <div className={"invest-row"}>
                                        <span>
                                            Investissement:
                                        </span>
                                <span>
                                            {props.world.countries[props.country].populationInvestment}
                                        </span>
                            </div>
                            <div className={"invest-row"}>
                                        <span>
                                            Investissment cible:
                                        </span>
                                <span>
                                            {props.world.countries[props.country].orderPopulationInvestment}
                                        </span>
                            </div>
                            {
                                props.editable && <div className={"invest-row"}>
                                    {
                                        [-5, -1, 1, 5].map(modification =>
                                            <button
                                                disabled={(modification < 0 && props.world.countries[props.country].orderPopulationInvestment <= 0)}
                                                onClick={() => emitOrderPopulationInvestment(modification)}
                                                key={modification}
                                                className={"small-button"}>
                                                {modification}
                                            </button>
                                        )
                                    }
                                </div>
                            }
                            <br/>
                        </div>
                    </div>
                    <div>
                        Investir dans la population du continent augmente le nombre de points et d'argent que tous les pays du continent génèrent via la consommation, ainsi que la consommation maximale
                        <br/>
                        <br/>
                    </div>
                    <PartyComponent
                        country={props.country}
                        world={props.world}
                        emitOrderPoliticalInvestment={emitOrderPoliticalInvestment}
                        editable={props.editable}/>
                </div>
            </div>
        </div>
    )
}
