import React from "react";

import {BrowserView, MobileView} from 'react-device-detect';
import {ConfirmModal} from "./utils/Modal";
import Button from "@mui/material/Button";

export default class EndDraftComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: props.state.actions[props.userId] ? "Choisir" : "Ma Main",
            show: false,
            action: undefined,
            kept: []
        };
        this.setView = this.setView.bind(this);
        this.getChoice = this.getChoice.bind(this);
    }

    componentDidMount() {

    }

    setView(view) {
        this.setState({view});
    }

    styleButton = {
        backgroundColor: "#444",
        padding: "5px",
        borderRadius: "3px",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "25%",
        width: "50%"
    };

    getChoice() {
        if (!this.props.state.actions[this.props.userId] || !this.props.state.actions[this.props.userId]) {
            return <div/>;
        }
        let action = this.props.state.actions[this.props.userId][0];
        return action.keptCardIds.map(cardId =>
            <div key={cardId}
                 style={{height: "100%", width: '70vw', maxWidth: '200px', display: "inline-block", marginLeft: "12px"}}>
                <img src={"/public/terraforming/cards/img_" + cardId + ".png"}
                     alt={"img " + cardId}
                     style={{width: "100%", height: "auto"}}>
                </img>
                <div style={{width: '100%', textAlign: 'center'}}>
                    {
                        this.state.kept.indexOf(cardId) > -1 ?
                            <Button
                                variant={"contained"}
                                onClick={() => this.setState((prevState) => {
                                    let kept = prevState.kept;
                                    if (kept.indexOf(cardId) > -1) {
                                        kept.splice(kept.indexOf(cardId), 1);
                                    }
                                    return {
                                        kept
                                    }
                                })}>
                                Gardée
                            </Button> :
                            <Button
                                variant={"outlined"}
                                disabled={action.maxCards <= this.state.kept.length}
                                onClick={() => this.setState((prevState) => {
                                    let kept = prevState.kept;
                                    if (action.maxCards > kept.length && kept.indexOf(cardId) === -1) {
                                        kept.push(cardId);
                                    }
                                    return {
                                        kept
                                    }
                                })}>
                                {action.maxCards > this.state.kept.length ? "Choisir" : "Plus de $$"}
                            </Button>
                    }

                </div>

            </div>
        )
    }

    render() {
        let gameState = this.props.state;
        let {view} = this.state;
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={(this.state.kept.length === 0 ? 'Vous êtes sûr de ne garder aucune carte ?' : 'Vous êtes sûr de garder ' + this.state.kept.length + ' carte' + (this.state.kept.length > 1 ? 's' : '') + ' ?')}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%",
            overflow: "hidden", width: "90%", height: "96%", color: "#DDD"
        }}>
            {modal}
            <div style={{width: "100%"}}>
                <h3>
                    Fin de Draft
                </h3>
                <h4>
                    {gameState.actions[this.props.userId] ? "Merci de choisir les cartes que vous souhaitez garder" : "Vous avez déjà choisi"}
                </h4>
            </div>
            <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                {
                    gameState.actions[this.props.userId] ?
                        <Button variant={"contained"}
                                onClick={() => {
                                    this.setState({
                                        show: true,
                                        action: {
                                            keptCardIds: this.state.kept,
                                            maxCards: 0,
                                            userId: this.props.userId,
                                            class: "model.actions.draft.EndDraftAction"
                                        }
                                    });
                                }}>
                            Garder {this.state.kept.length} carte{this.state.kept.length > 1 ? 's' : ''}
                        </Button> :
                        undefined
                }
            </div>
            <BrowserView style={{height: "90%"}}>
                <div style={{
                    width: "100%",
                    height: "60%",
                    overflow: "auto"
                }}>
                    {
                        this.getChoice()
                    }

                </div>
            </BrowserView>
            <MobileView style={{height: "90%"}}>
                <div style={{
                    width: "100%",
                    height: "60%",
                    overflow: "auto",
                    whiteSpace: "nowrap"
                }}>
                    {
                        this.getChoice()
                    }

                </div>
            </MobileView>

        </div>
    }
}
