import {selectSessionUser} from "../../../../../store/authentication/selectors";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {useSelector} from "react-redux";
import constants from "../../../../../config/constants";
import * as io from "socket.io-client";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../BoardGame.css";
import {StateIntro} from "./intro/StateIntro";
import ConsultScreen from "./consult/ConsultScreen";
import MainState from "./main/MainState";
import MarketState from "./main/MarketState";
import BarrackState from "./main/BarracksState";
import {SacrificeState} from "./main/SacrificeState";
import StartState from "./main/StartState";
import ConstructionState from "./main/ConstructionState";
import PickFromRiverState from "./main/PickFromRiverState";


export const HeliopolisHome = () => {

    const [state, setState] = useState({state: undefined, play: true, checkNewState: false});

    const [connected, setConnected] = useState(false);

    const user = useSelector(selectSessionUser);

    const history = useHistory();
    const location = useLocation();

    const gameId = location.pathname.split("/")[2];

    let [socket, setSocket] = useState(undefined);

    function updateState(newState) {
        setState(state => {
            if (newState && (newState.state !== "INTRO" || !state.state)) {
                let {play, checkNewState} = state;
                if (play && (!newState.actions || !newState.actions[user._id] || !newState.actions[user._id].length)) {
                    play = false;
                    checkNewState = false;
                } else if (!play && newState.actions && newState.actions[user._id] && newState.actions[user._id].length && !checkNewState) {
                    play = true;
                    checkNewState = true;
                }
                return {...state, state: newState, play, checkNewState};
            }
            return state;
        });
    }

    function updateStateIntro(newState) {
        setState(state => !state.state || state.state.state === "INTRO" ? {...state, state: newState} : state);
    }

    useEffect(() => {
        const tempSocket = io.connect(constants.api.socketPath);
        setTimeout(() => {
            tempSocket.emit("id", {
                type: "PLAYER",
                gameId,
                userId: "" + user._id,
                username: user.username,
                color: {r: 1, g: 1, b: 1, a: 1}
            })
        }, 1000);
        tempSocket.on('disconnect', function () {
            history.push("/board-games");
        });
        tempSocket.on('state', function (data) {
            console.log('new state');
            console.log(data);
            updateState(data);
        });
        tempSocket.on('state_intro', function (data) {
            console.log("received state intro");
            console.log(data);
            updateStateIntro(data);
        });
        setSocket(tempSocket);
        return () => {
            tempSocket.close();
        }
    }, []);


    let comp = undefined;

    if (!state.state || !socket) {
        return <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            marginTop: "15vh",
            marginBottom: "80vh",
            color: "#CCC"
        }}>
            Veuillez patienter
            <div style={{width: "100%", marginTop: "5vh", textAlign: 'center'}}>
                <Button variant={"contained"} onClick={() => history.push("/board-games")}>
                    Retour
                </Button>
            </div>
        </div>
    }
    switch (state.state.state) {
        case "MAIN_STATE":
        case "FAITH_STATE":
        case "PRODUCTION_STATE":
        case "THEFT_STATE":
        case "RUBY_STATE":
        case "HORSE_BACK_RIDING_STATE":
            comp = <MainState
                state={state.state}
                userId={user._id}
                gameId={gameId}
                username={user.username}
                socket={socket}
            />;
            break;
        case "CONSTRUCTION_STATE":
            comp = <ConstructionState
                state={state.state}
                userId={user._id}
                gameId={gameId}
                username={user.username}
                socket={socket}
            />;
            break;
        case "MARKET_STATE":
            comp = <MarketState
                state={state.state}
                userId={user._id}
                gameId={gameId}
                username={user.username}
                socket={socket}
            />;
            break;
        case "BARRACK_STATE":
            comp = <BarrackState
                state={state.state}
                userId={user._id}
                gameId={gameId}
                username={user.username}
                socket={socket}
            />;
            break;
        case "PICK_FROM_RIVER":
            comp = <PickFromRiverState
                state={state.state}
                userId={user._id}
                gameId={gameId}
                username={user.username}
                socket={socket}
            />;
            break;
        case "SACRIFICE_STATE":
            comp = <SacrificeState
                state={state.state}
                userId={user._id}
                gameId={gameId}
                username={user.username}
                socket={socket}/>;
            break;
        case "START_STATE":
            comp = <StartState
                state={state.state}
                userId={user._id}
                gameId={gameId}
                username={user.username}
                socket={socket}/>;
            break;
        case "INTRO":
            return <div style={{
                width: "100%",
                height: "100%",
                marginTop: "5vh",
                marginBottom: "40vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <StateIntro
                    state={state.state}
                    userId={user._id}
                    gameId={gameId}
                    username={user.username}
                    socket={socket}
                />
            </div>;
        default:
            comp = <div>{JSON.stringify(state.state)}</div>;
    }

    function clickOnPlay() {
        if (state.state.actions[user._id] && state.state.actions[user._id].length) {
            setState(state => ({...state, play: true}));
        }
    }

    return <div style={{width: "100%", minHeight: "90vh", marginTop: "-1px"}}>
        <div className="board-game-home-top-bar" style={{zIndex: 99}}>
            <div
                className={"board-game-home-top-button board-game-home-top-button-" + (state.play ? "active" : "inactive")}
                onClick={clickOnPlay}>
                {state.state.actions[user._id] && state.state.actions[user._id].length ?
                    "JOUER" :
                    "EN ATTENTE"
                }
            </div>
            <div
                className={"board-game-home-top-button board-game-home-top-button-" + (!state.play ? "active" : "inactive")}
                onClick={() => setState(state => ({...state, play: false}))}>
                CONSULTER
            </div>
        </div>
        {
            state.play ?
                <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
                    {
                        comp || JSON.stringify(state.state)
                    }
                </Box>
                : <ConsultScreen
                        state={state.state}
                        userId={user._id}
                    />
        }
    </div>;
};

export default HeliopolisHome;
