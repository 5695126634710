import {RESET_GAMES, ADD_GAME, SET_GAME_PROPERTY} from "./actions";

export default (state = {games : []}, action = {}) => {
    switch (action.type) {
        case RESET_GAMES:
            return {
                ...state,
                games: []
            };
        case ADD_GAME:
            return {
                ...state,
                games : [...(state.games), action.game]
            };
        case SET_GAME_PROPERTY:
            var games = []
            state.games.forEach((game) => {
                let g = {...game}
                if(g['_id']==action.id){
                    g[action.key] = action.value;
                }
                games.push(g);
            });
            return {
                ...state,
                games
            }
        default:
            return state;
    }
};
