import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {callApi} from "../../../store/api/actions";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


const getProperDate = (score) => {
    let date = "";
    date += (score.day < 10 ? "0" : "") + score.day + "/";
    date += (score.month < 10 ? "0" : "") + (score.month) + "/";
    date += (score.year % 1000);
    return date;
};


export const PreviousGamePage = () => {
    const [players, setPlayers] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const gamekey = location.pathname.split("/")[3];
    const gameId = location.pathname.split("/")[4];


    useEffect(() => {
        const path = "/scores/" + gamekey + "/" + gameId;
        const responseTransform = (data) => () => {
            setPlayers(data);
            console.log(data)
        };
        dispatch(callApi(path, responseTransform));
    }, []);

    return (
        <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
            <h2>
                Game details
            </h2>
            <div>
                <div style={{textAlign: "center"}}>
                    <img style={{
                        width: "100%",
                        marginBottom: 15,
                        textAlign: "center",
                        maxWidth: "400px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                         src={"../../../public/images/" + gamekey + ".png"}
                         alt={gamekey}/>
                </div>
                <br/>
                <div style={{width: "100%", textAlign: "center", marginBottom: 15}}>
                    Date : {players.length ? getProperDate(players[0]) : ""}
                </div>
            </div>
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="col-xs-4">Player</TableCell>
                                <TableCell className="col-xs-3">Position</TableCell>
                                <TableCell className="col-xs-3">Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="bodyPlayers">
                            {
                                players.length ?
                                    players.sort((e, f) => e.position > f.position ? 1 : -1).map((player, index) => (
                                        <TableRow key={"table-game-" + index}>
                                            <TableCell className="col-xs-4"
                                                       style={{"verticalAlign": "middle"}}>{player.username}</TableCell>
                                            <TableCell className="col-xs-3"
                                                       style={{"verticalAlign": "middle"}}>{player.position + "/" + player.nbPlayers}</TableCell>
                                            <TableCell className="col-xs-3"
                                                       style={{"verticalAlign": "middle"}}>{player.score}</TableCell>
                                        </TableRow>
                                    )) :
                                    <TableRow>
                                        <TableCell colSpan="4" style={{"verticalAlign": "middle"}}>
                                            ...
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{width: "100%", textAlign: "center", marginTop: "5vh", marginBottom: "15vh"}}>
                    <Button variant="contained" onClick={() => history.push("/board-games")}>
                        Back
                    </Button>
                </div>
            </div>
        </Box>
    )
};


export default PreviousGamePage;
