import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";
import {BrowserView, MobileView} from "react-device-detect";
import Box from "@mui/material/Box";

export default class ActionPlaceTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: undefined,
            row: undefined,
            column: undefined
        };
        this.getAction = this.getAction.bind(this);
        this.isLineOk = this.isLineOk.bind(this);
        this.isColumnOk = this.isColumnOk.bind(this);
    }

    componentDidMount() {

    }

    data = {
        CITY: "Cité",
        FOREST: "Forêt",
        OCEAN: "Océan",
        CARRIERE: "Carrière",
        DROIT_MINIER: "Droit Minier",
        QUARTIER_COMMERCIAL: "Quartier Commercial",
        CENTRE_INDUSTRIEL: "Centre Industriel",
        ZONE_NUCLEAIRE: "Zone Nucléaire",
        ZONE_DE_MOHOLE: "Zone de Mohole",
        ZONE_ECOLOGIQUE: "Zone Ecologique",
        VOLCANO: "Volcan",
        CAPITAL: "Capitale",
        ZONE_PRESERVEE: "Zone Preservée",
        POSTE_DE_RECHERCHE: "Ville",
        PHOBOS: "Ville",
        GANYMEDE: "Ville",
        FOREST_ON_WATER: "Forêt",
        OCEAN_ON_EARTH: "Océan",
        ZONE_INTERDITE: "Zone Interdite",
        ZONE_URBANISEE: "Ville",
        NOCTIS_VILLE: "Ville"
    };

    getAction(row, column) {
        return this.props.actions[this.props.userId].filter(action =>
            action.ok &&
            action.rowNumber === "" + "ABCDEFGHI".indexOf(row) &&
            action.columnNumber === "" + column)[0];
    }

    isLineOk(row) {
        return this.props.actions[this.props.userId].filter(action =>
            action.ok &&
            action.rowNumber === "" + "ABCDEFGHI".indexOf(row)).length > 0;
    }

    isColumnOk(column) {
        return this.props.actions[this.props.userId].filter(action =>
            action.ok &&
            action.rowNumber === "" + "ABCDEFGHI".indexOf(this.state.row) &&
            action.columnNumber === "" + column).length > 0;
    }

    styleButton = {
        backgroundColor: "#444",
        padding: "5px",
        borderRadius: "3px",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "25%",
        width: "50%"
    };

    styleTouch = {
        margin: "10% 10%",
        padding: "4vh 0",
        border: "1px solid #444",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    render() {
        let {action, row, column} = this.state;
        let modal = <ConfirmModal
            action={action}
            text={"Voulez vous placez la tuile en " + row + ", " + column + " ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({action: undefined})}
            open={!!action}
        />;

        const ligneComponent = <div style={{display: "flex", flexDirection: "column", width: '100%'}}>
            {
                [["A", "B", "C"], ["D", "E", "F"], ["G", "H", "I"]].map(ligne =>
                    <div key={ligne}
                         style={{display: "flex", flex: 1, flexDirection: "row", width: "100%"}}>
                        {
                            ligne.map(value =>
                                <div key={value} style={{flex: 1, height: "100%"}}>
                                    <Box
                                        xs={{width: {xs: '20vw', md: '10vw'}, height: {xs: '20vw', md: '10vh'}}}
                                        onClick={() =>
                                            this.setState({
                                                row: this.isLineOk(value) ? value : undefined,
                                                column: undefined
                                            })}
                                        style={{
                                            ...this.styleTouch,
                                            backgroundColor: this.state.row === value ? "#1f70ea" :
                                                (this.isLineOk(value) ? "#10346f" : "#622")
                                        }}>
                                        {value}
                                    </Box>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>;

        const colonneComponent = <div style={{display: "flex", flexDirection: "column", width: '100%'}}>
            {
                this.state.row ?
                    [[0, 1, 2], [3, 4, 5], [6, 7, 8]].map(ligne =>
                        <div key={ligne}
                             style={{display: "flex", flex: 1, flexDirection: "row", width: "100%"}}>
                            {
                                ligne.map(value =>
                                    <div key={value} style={{flex: 1, height: "100%"}}>
                                        <Box
                                            xs={{width: {xs: '20vw', md: '10vw'}, height: {xs: '20vw', md: '10vh'}}}
                                            onClick={() =>
                                                this.setState({column: this.isColumnOk(value) ? value : undefined})}
                                            style={{
                                                ...this.styleTouch,
                                                backgroundColor: this.state.column === value ? "#1f70ea" :
                                                    (this.isColumnOk(value) ? "#10346f" : "#622")
                                            }}>
                                            {value}
                                        </Box>
                                    </div>
                                )
                            }
                        </div>
                    ) :
                    undefined
            }
        </div>;

        return (
            <div style={{
                margin: "0", width: "100%", height: "100%"
            }}>
                {modal}
                <div style={{fontSize: "18px", width: "100%", textAlign: "center"}}>
                    Où placer la tuile {this.data[this.props.actions[this.props.userId][0].tileType]} ?
                </div>
                <div style={{fontSize: "20px", width: "100%", textAlign: "center"}}>
                    {row ? row : '?'}, {(typeof column !== 'undefined') ? column : '?'}
                </div>
                <BrowserView style={{display: "flex", flexDirection: "row", justifyContent: 'center'}}>
                    {ligneComponent}
                    <div style={{flex: 1}}/>
                    {colonneComponent}
                </BrowserView>
                <MobileView>
                    <h4>
                        Ligne
                    </h4>
                    {ligneComponent}
                </MobileView>
                <MobileView>
                    <h4>
                        Colonne
                    </h4>
                    {colonneComponent}
                </MobileView>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Button
                        variant={"contained"}
                        onClick={() => this.setState({action: this.getAction(this.state.row, this.state.column)})}
                        disabled={!this.state.row || (typeof this.state.column === 'undefined')}>
                        Valider
                    </Button>
                </div>
            </div>
        )
    }
}
