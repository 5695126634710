import React from "react";
import {Modal} from "react-bootstrap";
import ConfirmModal from "../utils/Modal";
import Button from "@mui/material/Button";

export default class StandardProjects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: undefined
        }
    }

    componentDidMount() {

    }

    data = {
        STANDARD_PROJECT_POWER_PLANT: {
            cost: 11,
            img: "/public/terraforming/icons/centrale.png",
            name: "CENTRALE"
        },
        STANDARD_PROJECT_ASTEROID: {
            cost: 14,
            img: "/public/terraforming/icons/asteroid.png",
            name: "ASTEROIDE"
        },
        STANDARD_PROJECT_AQUIFER: {
            cost: 18,
            img: "/public/terraforming/icons/ocean.png",
            name: "AQUIFERE"
        },
        STANDARD_PROJECT_PURIFICATION: {
            cost: 15,
            img: "/public/terraforming/icons/venus.png",
            name: "VENUS"
        },
        STANDARD_PROJECT_GREENERY: {
            cost: 23,
            img: "/public/terraforming/icons/forest.png",
            name: "FORÊT"
        },
        STANDARD_PROJECT_CITY: {
            cost: 25,
            img: "/public/terraforming/icons/city.png",
            name: "VILLE"
        },
    }

    styleButton = {
        backgroundColor: "#337ab7",
        padding: "5px",
        borderRadius: "3px",
        width: "90%",
        border: "1px solid #FFF"
    };

    render() {
        let text = "Voulez vous faire le projet ";
        if(this.state.action){
            text += this.data[this.state.action.actionNames].name + " ?";
        }
        let modal = <ConfirmModal
            action={this.state.action}
            text={text}
            socket={this.props.socket}
            onDismiss={() => this.setState({action: undefined})}
            open={!!this.state.action}
        />;
        return <div style={{
            width: "100%", height: "100%", display: "flex", flexDirection: "column", padding: "10px"
        }}>
            {modal}
            {
                this.props.actions.map(action => {
                    return <div key={action.actionNames}
                                style={{
                                    width: "98%",
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderRadius: "5px",
                            border: "1px solid #444",
                            backgroundColor: "#1f2136",
                            padding: '0.5vh 0',
                            marginBottom: '2.5vh'
                        }}>
                            <div style={{flex: 1, height: "80%", margin: "auto"}}>
                                <img width="80%" height="80%" style={{width: "80%", height: "auto", marginLeft: '5px'}} src={this.data[action.actionNames].img}
                                     alt={'?'}/>
                            </div>
                            <div style={{flex: 1, height: "100%"}}>
                                {this.data[action.actionNames].cost + "$"}
                            </div>
                            <div style={{flex: 3, height: "100%", fontWeight: "bold"}}>
                                {this.data[action.actionNames].name}
                            </div>
                            <div style={{flex: 2, height: "100%"}}>
                                    <Button variant={"contained"}
                                            disabled={!action.ok}
                                            onClick={() => this.setState({action: action})}>
                                        Jouer
                                    </Button>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    }

}
