import React from "react";
import "./main.css";
import {ConfirmModal} from "./utils/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const dicCorpo = {
    TERACTOR: 'TERACTOR',
    PRISTAR: 'PRISTAR',
    TERRALABS: 'TERRALABS',
    THORGATE: 'THORGATE',
    UNITED_NATIONS_MARS_INITIATIVE: 'UNITED NATIONS MARS INITIATIVE',
    LAKEFRONT_RESORTS: 'LAKEFRONT RESORTS',
    PHOBLOG: 'PHOBLOG',
    SATURN_SYSTEMS: 'SATURN SYSTEMS',
    ECOLINE: 'ECOLINE',
    MINING_GUILD: 'MINING GUILD',
    INTERPLANETARY_CINEMATICS: 'CINEMATIQUES INTERPLANETAIRES',
    INVENTRIX: 'INVENTRIX',
    CREDICOR: 'CREDICOR',
    THARSIS_REPUBLIC: 'REPUBLIQUE DE THARSIS',
    HELION: 'HELION',
    UTOPIA: 'UTOPIA',
    CHEUNG_SHING: "CHEUNG SHING",
    POINT_LUNA: "POINT LUNA",
    ROBINSON_INDUSTRIES: "ROBINSON INDUSTRIES",
    ARKLIGHT: "ARKLIGHT",
    POLYPHEMOS: "POLYPHEMOS",
    RECYCLON: "RECYCLON",
    MANUTECH: "MANUTECH",
    VIRON: "VIRON",
    VITOR: "VITOR",
    VALLEY_TRUST: "VALLEY TRUST",
    PHARMACY_UNION: "PHARMACY_UNION",
    CELESTIC: "CELESTIC",
    MORNING_STAR: "MORNING_STAR",
    APHRODITE: "APHRODITE"
}

export default class CorpoChoiceComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actionCorpo: undefined,
            show: false
        };
        if (this.props.blocked) {
            this.state.actionCorpo = this.props.state.availableCorpos[0];
            this.state.viewCorpo = this.props.state.availableCorpos[0];
        } else if (props.state.actions && props.state.actions[props.userId]) {
            this.state.actionCorpo = props.state.actions[props.userId][0];
            this.state.viewCorpo = props.state.actions[props.userId][0].corpoName;
        }
        this.viewCorpo = this.viewCorpo.bind(this);
    }

    componentDidMount() {

    }

    viewCorpo(corpoName) {
        let action = undefined;
        if (this.props.state.actions && !this.props.blocked) {
            for (let a of this.props.state.actions[this.props.userId]) {
                if (a.corpoName === corpoName) {
                    action = a;
                    break;
                }
            }
        }
        console.log("view Corpo " + corpoName + " - " + action);
        this.setState({
            viewCorpo: corpoName,
            actionCorpo: action
        });
    }

    render() {
        let gameState = this.props.state;
        let corpo = this.state.viewCorpo;
        let actionCorpo = this.state.actionCorpo;
        if (!corpo) {
            return <div/>;
        }
        let modal = undefined;
        if (!this.props.blocked) {
            modal = <ConfirmModal action={this.state.show ? this.state.actionCorpo : undefined}
                                  text={"Voulez-vous prendre " + dicCorpo[this.state.actionCorpo.corpoName] + " ?"}
                                  socket={this.props.socket}
                                  onDismiss={() => this.setState({show: false})}
                                  open={this.state.show}
            />;
        }
        return <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
            {modal}
            <div style={{width: "100%"}}>
                <h2>
                    {this.props.blocked ? "Corpos disponibles" : "Choix de la Corporation"}
                </h2>
                <h4>

                    {
                        this.props.blocked ?
                            "" :
                            (gameState.actions[this.props.userId] ? "Merci de choisir une corpo" : "Vous avez déjà choisi")
                    }
                </h4>
            </div>
            <Box style={{margin: "2vh 0", width: "100%", maxWidth: '80vw', display: 'inline-flex', flexWrap: 'wrap'}}>
                {
                    (this.props.blocked ? this.props.state.availableCorpos : this.props.state.actions[this.props.userId].map(a => a.corpoName)).map(corpoName =>
                        <div key={'button-' + corpoName}>
                            <Button
                                variant={corpo === corpoName ? 'contained' : 'outlined'}
                                style={{margin: "0.5vh 1vw"}}
                                onClick={() => this.viewCorpo(corpoName)}>
                                {dicCorpo[corpoName]}
                            </Button>
                        </div>
                    )
                }
            </Box>
            <div style={{width: "100%"}}>
                <img src={'/public/terraforming/corpos/corpo_card_' + corpo.toLowerCase() + '.png'}
                     alt={"corpo"} style={{width: "100%", margin: "auto", height: 'auto'}}/>
            </div>
            <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
                {
                    this.props.blocked ?
                        <div></div> :
                        <Button variant={"contained"}
                                onClick={() => this.setState({show: true})}>
                            Choisir
                        </Button>
                }
            </div>
        </Box>

    }
};
