
export const selectSession = (state) => state.session;

export const selectSessionUser = (state) => (selectSession(state).user);

export const selectSessionUsername = (state) => (selectSession(state).user.username);

export const selectSessionUserScores = (state) => (selectSession(state).user.games) ? (selectSession(state).user.games) : [];

export const selectSessionError = (state) => (selectSession(state).error);

export const selectIsLoggedIn = (state) => (Object.keys(selectSession(state).user).length > 0);

export const selectCanPlayBoardGames = (state) => (selectSession(state).user && selectSession(state).user.canPlay7wonders);

export const selectUsers = (state) => (selectSession(state).users);
