import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {getCountryFlagName} from "./utils";

export const BuyersBlock = (props) => {

    const getSellerCountries = () => {
        return Object.keys(props.world.countries).filter(country => props.world.countries[country].rawMaterial1 === props.rawMaterial || props.world.countries[country].rawMaterial2 === props.rawMaterial);
    }
    const getBuyersCountries = () => {
        return Object.keys(props.world.countries).filter(country => props.world.countries[country].neededRawMaterial1 === props.rawMaterial || props.world.countries[country].neededRawMaterial2 === props.rawMaterial);
    }

    const getNbProduced = (countryName) => {
        const country = props.world.countries[countryName];
        if (country.rawMaterial1 === props.rawMaterial) {
            return country.nbRawMaterialProducted1;
        } else if (country.rawMaterial2 === props.rawMaterial) {
            return country.nbRawMaterialProducted2;
        }
    }

    const getPrice = (countryName) => {
        const country = props.world.countries[countryName];
        if (country.rawMaterial1 === props.rawMaterial) {
            return country.rawMaterial1Price;
        } else if (country.rawMaterial2 === props.rawMaterial) {
            return country.rawMaterial2Price;
        }
    }

    const getActualRawMaterial = (buyer, seller) => {
        const country = props.world.countries[seller];
        if(country.rawMaterial1 === props.rawMaterial) {
            return country.countryBuyRawMaterial1[buyer] || '';
        } else {
            return country.countryBuyRawMaterial2[buyer] || '';
        }
    }

    const getWantRawMaterial = (buyer, seller) => {
        const country = props.world.countries[seller];
        if(country.rawMaterial1 === props.rawMaterial) {
            return country.countryTargetBuyRawMaterial1[buyer] || '';
        } else {
            return country.countryTargetBuyRawMaterial2[buyer] || '';
        }
    }



    return <div style={{width: '100%', display: 'block'}}>
        <div>
            Le marché actuel pour {props.rawMaterial} :
        </div>
        <div className={"buyers-row"}>
            <div className={"buyers-header buyers-first-cell"}></div>
            {
                getSellerCountries().map(country =>
                <div key={"selletr " + country} className={"buyers-header buyers-cell"}>
                    <img
                        style={{height: '30%', width: 'auto'}}
                        src={"/public/anniv/2024/flags/" + getCountryFlagName(country) + ".png"}/>
                    <div style={{
                        color: country === props.country ? '#00aa00': '#ffffff'
                    }}
                    >{country.substring(0, 3)}.</div>
                    <div>x{getNbProduced(country)}</div>
                    <div>{getPrice(country)}€</div>
                </div>
                )
            }
        </div>
        {
            getBuyersCountries().map(buyer =>
                <div className={"buyers-row black-row"} key={"buyer " + buyer}
                     style={{
                         background: buyer === props.country ? '#0a53be': 'rgba(0, 0, 0, 0.5)',
                         color: buyer === props.country ? '#00aa00': '#ffffff'
                }}>
                    <div className={"buyers-content buyers-first-cell"}>
                        <img
                            style={{height: '60%', width: 'auto', margin: '0 2vw'}}
                            src={"/public/anniv/2024/flags/" + getCountryFlagName(buyer) + ".png"}/>
                        {buyer.substring(0, 3)}.
                    </div>
                    {
                        getSellerCountries().map(seller =>
                            <div key={"combo " + seller + " " + buyer}
                                 style={{
                                     background: seller === props.country ? '#0a53be': 'none',
                                     color: buyer === props.country ? '#00aa00': '#ffffff'
                            }}
                                 className={"buyers-content buyers-cell"}>
                                <div>{getActualRawMaterial(buyer, seller)}</div>
                                {
                                    Math.abs(getActualRawMaterial(buyer, seller) - getWantRawMaterial(buyer, seller)) > 0.1 &&
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <img style={{height: '20px', width: 'auto'}} src={"/public/anniv/2024/icon_target.png"}/>
                                        {getWantRawMaterial(buyer, seller)}
                                    </div>
                                }
                            </div>
                        )
                    }
                </div>
            )
        }
    </div>
}
