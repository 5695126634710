import React from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";

export default class StateRemovePlant extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            action: false
        };
        this.getPlayer = this.getPlayer.bind(this);
        this.getActions = this.getActions.bind(this);
        this.getCentrales = this.getCentrales.bind(this);
    }


    componentDidMount() {
        this.setState({action: this.getActions()[0]})
    }

    getPlayer() {
        return this.props.state.networkPlayers.filter(np => np.userId === this.props.userId)[0];
    }

    getActions() {
        return this.props.state.actions[this.props.userId];
    }

    getCentrales() {
        return this.getActions().map(a => a.powerPlantPrice);
    }


    render() {
        let modal = ConfirmModal(this.state.show ? this.state.action : undefined,
            "Etes-vous sûr de vouloir retirer cette centrale ?",
            this.props.socket, () => this.setState({show: false}));
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            {modal}
            <div style={{
                width: "100%",
                paddingTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <div style={{
                    marginBottom: "20px",
                    color: "white",
                    fontSize: "20px"
                }}>
                    Vous devez retirer une centrale
                </div>
                {
                    this.getActions().map(a =>
                        <div
                            onClick={() => this.setState({action: a, show: true})}
                            key={a.powerPlantPrice}
                            style={{
                                width: "70%",
                                marginLeft: "15%",
                                marginTop: "25px",
                                display: "flex",
                                backgroundColor: "gray",
                                flexDirection: "column",
                                justifyContent: "center",
                                border: "1px solid gray",
                                marginBottom: "25px",
                                alignItems: "center"
                            }}>
                            <img
                                style={{
                                    width: "80%",
                                    maxWidth: "220px",
                                    height: "auto"
                                }}
                                alt={"centrale " + a.powerPlantPrice}
                                src={"../../../../public/megawatt/centrales/smalls/centrale_small_" + a.powerPlantPrice + ".png"}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    }
}
