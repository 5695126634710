import callApi from "../api/actions";
import buildURLQueryParams from "../utils/buildURLQueryParams";


export const RESET_DATA_COLLECTION = 'RESET_DATA_COLLECTION';
export const ADD_TO_DATA_COLLECTION = 'ADD_TO_DATA_COLLECTION';

export function resetDataCollection(collection) {
    return {type: RESET_DATA_COLLECTION, collection};
}

export function addToDataCollection(collection, dataContent) {
    return {type: ADD_TO_DATA_COLLECTION, collection, dataContent};
}

export const getDataCollection = (gamekey, collection) => {
    return dispatch => {
        let call = buildURLQueryParams('/data', {
            gamekey,
            collection
        });
        return dispatch(
            callApi(call, (dataContents) => dispatch => {
                dispatch(resetDataCollection(collection));
                dataContents.forEach(dataContent => {
                    dispatch(addToDataCollection(collection, dataContent));
                });
            })
        );
    };
};
