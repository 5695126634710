import React from "react";
import {ConfirmModal} from "../../terraforming/utils/Modal";

export default class StateAuction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            action: false,
            currentAction: false
        };
        this.getPlayer = this.getPlayer.bind(this);
        this.getActions = this.getActions.bind(this);
    }

    componentDidMount(){
        if(this.getMinPriceAuction()){
            this.setState({currentAction: this.getMinPriceAuction()});
        }
    }

    getPlayer() {
        return this.props.state.networkPlayers.filter(np => np.userId === this.props.userId)[0];
    }

    getActions() {
        return this.props.state.actions[this.props.userId];
    }

    getMinPriceAuction() {
        return this.getActions().filter(a => a.class === "action.auction.AuctionAction")[0];
    }

    getMaxPriceAuction() {
        return this.getActions().filter(a => a.class === "action.auction.AuctionAction")[
            this.getActions().filter(a => a.class === "action.auction.AuctionAction").length - 1];
    }

    getModalText(action) {
        switch (action.class) {
            case "action.auction.NewAuctionAction":
                return "Êtes-vous sûr de lancer les enchères sur la centrale " + action.auctionPowerPlant + " pour " + action.auctionPowerPlant + " ?";
            case "action.auction.AuctionAction":
                return "Êtes-vous sûr de faire monter les enchères à " + action.auctionValue + " pour la centrale " + action.auctionPowerPlant + " ?";
            case "action.auction.ActionAuctionFold":
                return "Êtes-vous sûr de ne pas faire d'enchère ? (Vous ne pourrez plus participer aux enchères ce tour-ci !)"
            case "action.auction.ActionAuctionPass":
                return "Êtes-vous sûr de passer pour cette enchère ?"
        }
        return "Etes-vous sûr ?";
    }

    render() {
        let modal = ConfirmModal(this.state.show ? this.state.action : undefined,
            this.getModalText(this.state.action),
            this.props.socket, () => this.setState({show: false}));
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            {modal}
            <div style={{
                width: "100%",
                marginTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <h3 style={{
                    marginBottom: "20px"
                }}>
                    C'est à vous d'enchérir
                </h3>
                {
                    this.getActions().filter(a => a.class === "action.auction.NewAuctionAction").map(a =>
                        <div
                            style={{
                                display: "flex",
                                height: "75px",
                                marginBottom: "12px",
                                width: "100%",
                                flexDirection: "row"
                            }}
                            key={a.auctionPowerPlant}
                        >
                            <div style={{
                                flex: 4,
                                textAlign: "center"
                            }}>
                                <img
                                    alt={"centrale"}
                                    src={"../../../../public/megawatt/centrales/smalls/centrale_small_" + a.auctionPowerPlant + ".png"}
                                    style={{
                                        height: "100%",
                                        width: "auto",
                                        flex: 4
                                    }}>
                                </img>
                            </div>
                            <div style={{
                                flex: 1,
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <div
                                    onClick={() => {
                                        this.setState({
                                            action: a,
                                            show: true
                                        })
                                    }}
                                    style={{
                                        borderRadius: "5px",
                                        backgroundColor: "#5bc0de",
                                        color: "white",
                                        display: "flex",
                                        cursor: "pointer",
                                        margin: "20% 5%",
                                        width: "90%",
                                        height: "60%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                    Enchérir
                                </div>
                            </div>

                        </div>)
                }
                {
                    this.getMinPriceAuction() && this.state.currentAction ?
                        <div style={{
                            width: "100%",
                        }}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img src={"../../../../public/megawatt/centrales/smalls/centrale_small_" + this.getMinPriceAuction().auctionPowerPlant + ".png"}>
                                </img>
                            </div>
                            <div style={{
                                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",
                                height: "75px",
                                color: "white",
                                marginBottom: "12px"}}>
                                <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div
                                        onClick={() => {
                                            if(this.state.currentAction.auctionValue > this.getMinPriceAuction().auctionValue) {
                                                let action = {...this.state.currentAction};
                                                action.auctionValue = this.getMinPriceAuction().auctionValue;
                                                this.setState({currentAction: action});
                                            }
                                        }}
                                        style={{
                                            width: "80%",
                                            height: "46px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            backgroundColor: this.state.currentAction.auctionValue > this.getMinPriceAuction().auctionValue ? "#5bc0de" : "#777",
                                            color: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                        MIN
                                    </div>
                                </div>
                                <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div
                                        onClick={() => {
                                            if(this.state.currentAction.auctionValue > this.getMinPriceAuction().auctionValue) {
                                                let action = {...this.state.currentAction};
                                                action.auctionValue--;
                                                this.setState({currentAction: action});
                                            }
                                        }}
                                        style={{
                                        width: "80%",
                                            height: "46px",
                                        borderRadius: "5px",
                                            cursor: "pointer",
                                        backgroundColor: this.state.currentAction.auctionValue > this.getMinPriceAuction().auctionValue ? "#5bc0de" : "#777",
                                        color: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        -
                                    </div>
                                </div>
                                <div style={{flex: 2, display: "flex", justifyContent: "center", alignItems: "center", color: "white"}}>
                                    {this.state.currentAction.auctionValue}
                                </div>
                                <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div
                                        onClick={() => {
                                            if(this.state.currentAction.auctionValue < this.getMaxPriceAuction().auctionValue) {
                                                let action = {...this.state.currentAction};
                                                action.auctionValue++;
                                                this.setState({currentAction: action});
                                            }
                                        }} style={{
                                        width: "80%",
                                        height: "46px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        backgroundColor: this.state.currentAction.auctionValue < this.getMaxPriceAuction().auctionValue ? "#5bc0de" : "#777",
                                        color: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        +
                                    </div>
                                </div>
                                <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div
                                        onClick={() => {
                                            if(this.state.currentAction.auctionValue < this.getMaxPriceAuction().auctionValue) {
                                                let action = {...this.state.currentAction};
                                                action.auctionValue = this.getMaxPriceAuction().auctionValue;
                                                this.setState({currentAction: action});
                                            }
                                        }} style={{
                                        width: "80%",
                                        height: "46px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        backgroundColor: this.state.currentAction.auctionValue < this.getMaxPriceAuction().auctionValue ? "#5bc0de" : "#777",
                                        color: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        MAX
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    height: "75px",
                                    marginBottom: "12px",
                                    width: "100%",
                                    flexDirection: "row"
                                }}
                            >
                                <div style={{
                                    flex: 1,
                                    textAlign: "center"
                                }}>
                                </div>
                                <div style={{
                                    flex: 3,
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <div
                                        onClick={() => {
                                            this.setState({
                                                show: true,
                                                action: this.state.currentAction
                                            });
                                        }}
                                        style={{
                                            borderRadius: "5px",
                                            backgroundColor: "#777",
                                            color: "white",
                                            display: "flex",
                                            cursor: "pointer",
                                            margin: "20% 5%",
                                            width: "90%",
                                            height: "60%",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                        Faire monter l'enchère
                                    </div>
                                </div>
                                <div style={{
                                    flex: 1,
                                    textAlign: "center"
                                }}>
                                </div>

                            </div>
                        </div>
                        : undefined
                }
                {
                    this.getActions().filter(a => a.class === "action.auction.ActionAuctionFold" ||
                        a.class === "action.auction.ActionAuctionPass").map(a =>
                        <div
                            style={{
                                display: "flex",
                                height: "75px",
                                marginBottom: "12px",
                                width: "100%",
                                flexDirection: "row"
                            }}
                        >
                            <div style={{
                                flex: 1,
                                textAlign: "center"
                            }}>
                            </div>
                            <div style={{
                                flex: 3,
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <div
                                    onClick={() => {
                                        this.setState({
                                            action: a,
                                            show: true
                                        })
                                    }}
                                    style={{
                                        borderRadius: "5px",
                                        backgroundColor: "#777",
                                        color: "white",
                                        display: "flex",
                                        cursor: "pointer",
                                        margin: "20% 5%",
                                        width: "90%",
                                        height: "60%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                    {
                                        a.class === "action.auction.ActionAuctionFold" ?
                                            "Abandonner les enchères" :
                                            "Passer pour cette enchère"
                                    }
                                </div>
                            </div>
                            <div style={{
                                flex: 1,
                                textAlign: "center"
                            }}>
                            </div>

                        </div>
                    )
                }
            </div>
        </div>
    }
}
