import {selectSessionUser} from "../../../../../store/authentication/selectors";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {useSelector} from "react-redux";
import constants from "../../../../../config/constants";
import * as io from "socket.io-client";
import WonderFaceChoice from "./screens/WonderFaceChoice";
import LeaderChoice from "./screens/LeaderChoice";
import CardChoice from "./screens/CardChoice";
import Box from "@mui/material/Box";

import "../BoardGame.css";
import RemoveGoldChoice from "./screens/RemoveGoldChoice";
import PlayFromDiscard from "./screens/PlayFromDiscard";
import BuryLeader from "./screens/BuryLeader";
import ConsultScreen from "./screens/ConsultScreen";
import PlayLeader from "./screens/PlayLeader";
import Button from "@mui/material/Button";


export const SevenWondersHome = () => {

    const [state, setState] = useState({state: undefined, play: true, checkNewState: false});

    const [connected, setConnected] = useState(false);

    const user = useSelector(selectSessionUser);

    const history = useHistory();
    const location = useLocation();

    const gameId = location.pathname.split("/")[2];

    let [socket, setSocket] = useState(undefined);

    function updateState(newState) {
        setState(state => {
            if (newState && (newState.state !== "INTRO" || !state.state)) {
                let {play, checkNewState} = state;
                if (play && (!newState.actions || !newState.actions[user._id] || !newState.actions[user._id].length)) {
                    play = false;
                    checkNewState = false;
                } else if (!play && newState.actions && newState.actions[user._id] && newState.actions[user._id].length && !checkNewState) {
                    play = true;
                    checkNewState = true;
                }
                return {...state, state: newState, play, checkNewState};
            }
            return state;
        });
    }

    function updateStateIntro(newState) {
        setState(state => !state.state || state.state.state === "INTRO" ? {...state, state: newState} : state);
    }

    useEffect(() => {
        const tempSocket = io.connect(constants.api.socketPath);
        tempSocket.on('disconnect', function () {
            history.push("/board-games");
        });
        tempSocket.on('state', function (data) {
            console.log('new state');
            console.log(data);
            updateState(data);
        });
        tempSocket.on('state_intro', function (data) {
            console.log("received state intro");
            updateStateIntro(data);
        });
        setSocket(tempSocket);
        setTimeout(() => {
            if (!socket.connected) {
                history.push("/board-games");
            }
        }, 3000);
        return () => {
            tempSocket.close();
        }
    }, []);

    useEffect(() => {
        if (user && !!socket && !connected) {
            socket.emit("id", {
                type: "PLAYER",
                gameId,
                userId: "" + user._id,
                username: user.username,
                color: {r: 0.750, g: 0.75, b: 0.75, a: 1}
            });
            setConnected(true);
        }
    }, [user, socket]);


    let comp = undefined;

    if (!state.state || !socket) {
        return <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            marginTop: "15vh",
            marginBottom: "80vh",
            color: "#CCC"
        }}>
            Veuillez patienter
            <div style={{width: "100%", marginTop: "5vh", textAlign: 'center'}}>
                <Button variant={"contained"} onClick={() => history.push("/board-games")}>
                    Retour
                </Button>
            </div>
        </div>
    }
    switch (state.state.state) {
        case "INTRO":
            return <div style={{
                width: "100%",
                height: "100%",
                marginTop: "15vh",
                marginBottom: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                Veuillez attendre que la partie démarre
            </div>;
        case "WONDER_FACE_CHOICE":
            comp = <WonderFaceChoice
                state={state.state}
                userId={user._id}
                gameId={gameId}
                socket={socket}
            />;
            break;
        case "LEADER_CHOICE":
            comp = <LeaderChoice
                state={state.state}
                userId={user._id}
                gameId={gameId}
                socket={socket}
            />;
            break;
        case "MAIN_TURN":
            comp = <CardChoice
                state={state.state}
                userId={user._id}
                gameId={gameId}
                socket={socket}
            />;
            break;
        case "REMOVE_GOLD":
            comp = <RemoveGoldChoice
                state={state.state}
                userId={user._id}
                gameId={gameId}
                socket={socket}
            />;
            break;
        case "BURY_LEADER":
            comp = <BuryLeader
                state={state.state}
                userId={user._id}
                gameId={gameId}
                socket={socket}
            />;
            break;
        case "PLAY_FROM_DISCARD":
            comp = <PlayFromDiscard
                state={state.state}
                userId={user._id}
                gameId={gameId}
                socket={socket}
            />;
            break;
        case "PLAY_LEADER":
            comp = <PlayLeader
                state={state.state}
                userId={user._id}
                gameId={gameId}
                socket={socket}
            />;
            break;
        default:
            comp = <div>{JSON.stringify(state.state)}</div>;
    }

    function clickOnPlay() {
        if (state.state.actions[user._id] && state.state.actions[user._id].length) {
            setState(state => ({...state, play: true}));
        }
    }

    return <div style={{width: "100%", minHeight: "90vh", marginTop: "-1px"}}>
        <div className="board-game-home-top-bar" style={{zIndex: 99}}>
            <div
                className={"board-game-home-top-button board-game-home-top-button-" + (state.play ? "active" : "inactive")}
                onClick={clickOnPlay}>
                {state.state.actions[user._id] && state.state.actions[user._id].length ?
                    "JOUER" :
                    "EN ATTENTE"
                }
            </div>
            <div
                className={"board-game-home-top-button board-game-home-top-button-" + (!state.play ? "active" : "inactive")}
                onClick={() => setState(state => ({...state, play: false}))}>
                CONSULTER
            </div>
        </div>
        {
            state.play ?
                <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
                    {
                        comp || JSON.stringify(state.state)
                    }
                </Box>
                : <ConsultScreen
                        state={state.state}
                        userId={user._id}
                    />
        }
    </div>;
};

export default SevenWondersHome;
