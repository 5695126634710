import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {resetError} from "../../../store/authentication/actions";
import axios from "axios";
import constants from "../../../config/constants";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";


export const ResetPasswordPage = () => {

    const dispatch = useDispatch();
    const theme = useTheme();

    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [response, setResponse] = useState(false);
    const [error, setError] = useState(false);
    const mobile = window.innerWidth < 700;

    function handleChange(event) {
        const {value} = event.target;
        setEmail(value);
        setError(undefined);
        dispatch(resetError());
    }

    function handleResetPassword(event) {
        event.preventDefault();
        setSubmitted(true);
        if (email) {
            axios.post(constants.api.basePath + "/userinfo/resetPassword", {
                email
            }).then(
                response => {
                    setResponse(response.data);
                }
            ).catch(error => {
                if (error.response) {
                    setError(error.response.data.err);
                }
            });
        }
    }

    let emailError = undefined;
    if (submitted) {
        if (!email) {
            emailError = "Email is required";
        } else if (error === "UserNotFound") {
            emailError = "No account linked with this email";
        }
    }
    return (
        <div>
            {
                submitted && response ?
                    <div style={{}}>
                        <Card style={{
                            margin: "2vh",
                        }}>
                            <CardContent>
                                A reset password link has been send by email
                            </CardContent>
                            <CardActions>
                                <Button size="small" href="/login" variant="contained">
                                    Back
                                </Button>
                            </CardActions>
                        </Card>
                        <div style={{
                            width: "100%",
                            height: "90vh"
                        }}/>

                    </div> :
                    <Box sx={{width: {xs: "80%", md: "500px"}, margin: "auto"}}>
                        <div className={"auth-form"}>
                            <h2>Forgotten password</h2>
                            <div style={{fontSize: 15, margin: "15px", textAlign: "center"}}>
                                If you forgot your password, don't worry. <br/>
                                We'll send you a link via email to reset it.
                            </div>
                            <form name="form">
                                <div className={'form-group' + (emailError ? ' has-error' : '')}>
                                    <label htmlFor="email">Email</label>
                                    <TextField type="text" name="email"
                                               hiddenLabel
                                               size="small"
                                               style={mobile ? {width: "100%"} : {}}
                                               variant="outlined" value={email}
                                               onChange={handleChange}/>
                                </div>
                                <Box className="error-holder" style={{color: theme.palette.error.main}}>
                                    {
                                        emailError && <div className="help-block">{emailError}</div>
                                    }
                                </Box>
                                <div style={{textAlign: "center", marginTop: "3vh"}}>
                                    <Button
                                        variant="contained"
                                        disabled={emailError}
                                        onClick={handleResetPassword}>
                                        Reset
                                    </Button>

                                </div>
                            </form>
                        </div>
                        <div style={{
                            width: "100%",
                            height: "40vh"
                        }}/>
                    </Box>
            }
        </div>
    );
};

export default ResetPasswordPage;
