import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import * as io from "socket.io-client";
import "./Anniv2024.css";
import constants from "../../../config/constants";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import {PlayerHomePage} from "./PlayerHomePage";
import {selectSessionUser} from "../../../store/authentication/selectors";


export const Anniv2024Page = () => {


    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [world, setWorld] = useState(undefined);
    const [player, setPlayer] = useState(undefined);
    const [socketAnniv, setSocketAnniv] = useState(undefined);

    const user = useSelector(selectSessionUser);

    useEffect(() => {
        console.log(constants.api.socketPath);
        const tempSocketAnniv = io.connect(constants.api.socketPath, {transports: ['websocket']});
        tempSocketAnniv.on('anniv2024-world', function (data) {
            console.log("jai recu un world");
            const dataWorld = JSON.parse(data);
            console.log(dataWorld);
            setWorld(dataWorld);
        });
        tempSocketAnniv.on("anniv2024-order-country", function (data) {
            const orderReponse = JSON.parse(data);
            console.log("order country reponse");
            console.log(orderReponse);
            if (orderReponse.orderCountry) {
                setWorld(world => ({
                    ...world,
                    countries: {
                        ...world.countries,
                        [orderReponse.orderCountry.countryName]: {
                            ...world.countries[orderReponse.orderCountry.countryName],
                            ...orderReponse.orderCountry
                        }
                    },
                    players: {
                        ...world.players,
                        [orderReponse.playerName]: {
                            ...world.players[orderReponse.playerName],
                            targetBudget: orderReponse.targetBudget,
                            targetIncome: orderReponse.targetIncome,
                            targetExpenses: orderReponse.targetExpenses,
                            events: orderReponse.events
                        }
                    }
                }));
            }
        });
        tempSocketAnniv.on("anniv2024-order-buy", function (data) {
            const orderReponse = JSON.parse(data);
            console.log("order buy reponse");
            console.log(orderReponse);
            if (orderReponse.orderBuy) {
                setWorld(world => {
                    const countryWantRawMaterialKey = orderReponse.orderBuy.rawMaterialName === world.countries[orderReponse.orderBuy.countrySellerName].rawMaterial1 ? "countryTargetBuyRawMaterial1" : "countryTargetBuyRawMaterial2";
                    const countryTargetConsumptionKey = orderReponse.orderBuy.rawMaterialName === world.countries[orderReponse.orderBuy.countryBuyerName].neededRawMaterial1 ? "targetConsumptionRawMaterial1" : "targetConsumptionRawMaterial2";
                    return {
                        ...world,
                        countries: {
                            ...world.countries,
                            [orderReponse.orderBuy.countrySellerName]: {
                                ...world.countries[orderReponse.orderBuy.countrySellerName],
                                [countryWantRawMaterialKey]: {
                                    ...world.countries[orderReponse.orderBuy.countrySellerName][countryWantRawMaterialKey],
                                    [orderReponse.orderBuy.countryBuyerName]: orderReponse.orderBuy.quantity
                                },
                            },
                            [orderReponse.orderBuy.countryBuyerName]: {
                                ...world.countries[orderReponse.orderBuy.countryBuyerName],
                                [countryTargetConsumptionKey]: orderReponse[countryTargetConsumptionKey][orderReponse.orderBuy.countryBuyerName]
                            }
                        },
                        players: {
                            ...world.players,
                            [orderReponse.playerName]: {
                                ...world.players[orderReponse.playerName],
                                targetBudget: orderReponse.targetBudget,
                                targetIncome: orderReponse.targetIncome,
                                targetExpenses: orderReponse.targetExpenses,
                                events: orderReponse.events
                            }
                        }
                    }
                })
            }
        });
        tempSocketAnniv.connect();
        tempSocketAnniv.emit("anniv2024-join", JSON.stringify({playerId: user.username}));
        setSocketAnniv(tempSocketAnniv);
        setPlayer(user.username);
        return () => {
            tempSocketAnniv.close();
            console.log("closing socket");
        }
    }, []);

    const [debounceOrderCountry, setDebounceOrderCountry] = useState({});
    useEffect(() => {
        const handler = setTimeout(() => {
            if (Object.keys(debounceOrderCountry).length > 0) {
                console.log("emitting order country at top level");
                console.log(debounceOrderCountry);
                socketAnniv.emit("anniv2024-order-country", JSON.stringify(debounceOrderCountry));
                setDebounceOrderCountry({});
            }
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [debounceOrderCountry]);

    function emitOrderCountry(orderCountry) {
        if (socketAnniv) {
            console.log("emitting order country");
            console.log(orderCountry);
            socketAnniv.emit("anniv2024-order-country", JSON.stringify(orderCountry));
        }
    }

    function emitOrderBuy(orderBuy) {
        if (socketAnniv) {
            console.log("emitting order buy at top level");
            socketAnniv.emit("anniv2024-order-buy", JSON.stringify(orderBuy));
        }
    }

    return (
        <div>
            {
                world && player && <PlayerHomePage
                    emitOrderBuy={emitOrderBuy}
                    emitOrderCountry={emitOrderCountry}
                    world={world}
                    player={player}/>
            }
            {
                !world && player &&
                <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
                    <h4>
                        Connecté en tant que {user.username}
                    </h4>
                    En attente du démarrage de la partie

                </Box>
            }
        </div>
    )
};


export default Anniv2024Page;
