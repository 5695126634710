import React from "react";
import Button from "@mui/material/Button";

export const WonderFacePresentation = (props) => {

    const {wonder, face, action, switchWonderFace} = props;

    if (!wonder) {
        return undefined;
    }

    return <div style={{width: "100%"}}>
        <div style={{
            display: "flex",
            flexDirection: "row",
            padding: "0 10%",
            justifyContent: "space-evenly",
            alignItems: "center"
        }}>
            <Button variant={face=== "A" ? "contained" : "outlined"}
                    onClick={() => switchWonderFace ? switchWonderFace("A") : undefined}>
                Face A
            </Button>
            <Button variant={face=== "B" ? "contained" : "outlined"}
                    onClick={() => switchWonderFace ? switchWonderFace("B") : undefined}>
                Face B
            </Button>
        </div>
        <div style={{width: "100%"}}>
            <h4>
                {wonder["face" + face].text}
            </h4>
            {
                wonder["face" + face].floors.map((floor, index) => (
                    <div key={"wonderFloor" + index} style={{width: "100%", textAlign: "center"}}>
                        <img
                            src={"../../../public/7wonders/images/wonders/" + wonder.idname + face + (index + 1) + ".png"}
                            style={{height: "15vh", width: "auto", margin: "auto"}}/>
                    </div>
                ))
            }
            {
                action ?
                    <div style={{width: "100%", textAlign: "center"}}>
                        <Button variant={"contained"} onClick={action}
                                style={{marginTop: 15}}>
                            Select
                        </Button>
                    </div> :
                    undefined
            }
        </div>
    </div>
};

export default WonderFacePresentation;
