import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export default class ActionSelectFromDeck extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: undefined,
            cardId: undefined,
            cards: {},
            show: false
        };
        for (let action of props.actions[props.userId]) {
            for (let cardId of action.cardKept) {
                this.state.cards[cardId] = false;
            }
        }
        this.confirmChoice = this.confirmChoice.bind(this);
    }

    componentDidMount() {

    }

    confirmChoice() {
        let action = this.props.actions[this.props.userId].filter(a => {
            for (let i of a.cardKept) {
                if (!this.state.cards[i]) {
                    return false;
                }
            }
            return true;
        })[0];
        this.setState({show: true, action});
    }


    render() {
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={"Voulez-vous conserver ces cartes ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        let s = this.props.actions[this.props.userId][0].cardKept.length > 1;

        if(this.state.cardId){
            return <div onClick={() => this.setState({cardId: undefined})} style={{width: '100%', textAlign: 'center'}}>
                <img src={"/public/terraforming/cards/img_" + this.state.cardId + ".png"}
                     alt={"img " + this.state.cardId}
                     style={{width: "80vw", height: "auto"}}/>
            </div>
        }
        return <div style={{
            marginTop: "1%", marginLeft: "5%", marginRight: "5%",
            overflow: "hidden", width: "90%", height: "90%", color: "#DDD"
        }}>
            <h4>
                Vous pouvez conserver {this.props.actions[this.props.userId][0].cardKept.length} carte{s ? 's' : ''}
            </h4>
            {modal}
            <div style={{
                width: "100%",
                marginTop: "5%",
                display: 'inline-flex',
                flexWrap: 'wrap'
            }}>
                {
                    Object.keys(this.state.cards).map(cardId =>
                        <div key={cardId}
                             style={{height: "100%", display: "flex", flexDirection: "column", alignItems: 'center', marginLeft: "12px"}}>
                            <img
                                onClick={() => this.setState({cardId})}
                                src={"/public/terraforming/cards/img_" + cardId + ".png"}
                                 alt={"img " + cardId}
                                 style={{width: "20vw", height: "auto"}}>
                            </img>
                            <br/>
                            {
                                this.state.cards[cardId] ?
                                    <Button variant={"contained"}
                                            onClick={() => this.setState((prevState) => {
                                                const cards = {...prevState.cards};
                                                cards[cardId] = false;
                                                return {...prevState, cards};
                                            })}>
                                        <TaskAltIcon/>
                                    </Button> :
                                    <Button variant={"outlined"}
                                            onClick={() => this.setState((prevState) => {
                                                const cards = {...prevState.cards};
                                                if (this.props.actions[this.props.userId][0].cardKept.length > Object.values(this.state.cards).filter(a => a).length) {
                                                    cards[cardId] = true;
                                                }
                                                return {...prevState, cards};
                                            })}>
                                        <RadioButtonUncheckedIcon/>
                                    </Button>
                            }
                        </div>
                    )
                }
            </div>
            <div style={{width: '100%', textAlign: 'center', marginTop: '3vh'}}>
                    <Button variant={"contained"}
                            disabled={Object.values(this.state.cards).filter(a => a).length !== this.props.actions[this.props.userId][0].cardKept.length}
                            onClick={() => {
                                this.confirmChoice()
                            }}>
                        Garder {this.props.actions[this.props.userId][0].cardKept.length === 1 ? 'cette carte' : 'ces ' + this.props.actions[this.props.userId][0].cardKept.length + ' cartes'}
                    </Button>
            </div>
        </div>
    }
}
