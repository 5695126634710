import React, {useEffect, useState} from "react";


import {CardCarousel} from "../components/CardCarousel";
import {ConfirmModal} from "../../terraforming/utils/Modal";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


export const PlayFromDiscard = (props) => {

    const [state, setState] = useState({
        showPopup: false,
        init: false,
        show: false,
        card: false,
        action: false
    });

    const player = props.state.players[props.userId];
    const actions = props.state.actions[props.userId];
    const cards = [];
    for (let action of actions) {
        if (cards.map(c => c.building.idname).indexOf(action.card.building.idname) === -1) {
            cards.push(action.card);
        }
    }


    useEffect(() => {
        if (!state.action || !state.card) {
            handleChangeActiveCard(0);
        }
    }, [state]);


    function handleChangeActiveCard(index) {
        setState(state => ({
            ...state,
            action: actions.filter(a => a.card.building.idname === cards[index].building.idname && a.class === (state.wonder ? "action.mainturn.ActionBuildWonderFloorWithCard" : "action.mainturn.ActionBuildCard"))[0],
            card: cards[index],
            init: true
        }));
    }

    function getBuildComponent(action) {
        if (!action.ok) {
            if (action.reasonNotPossible === "ALREADY_BUILT") {
                return <div>Vous possédez déjà ce bâtiment</div>
            }
            return <div>Vous ne pouvez pas construire {state.wonder ? "cet étage" : "ce bâtiment"}</div>
        }
        return <div>Vous pouvez construire ce bâtiment <b>gratuitement</b></div>
    }

    if (!state.card) {
        return <div></div>;
    }
    let currentActionName = "?";
    if (state.action.class === "action.mainturn.ActionBuildCard") {
        currentActionName = "Êtes-vous sûr de vouloir construire la carte " + state.card.building.name + " ?";
    }
    const buildingActions = actions.filter(action => action.class === "action.mainturn.ActionBuildCard" && action.card.building.idname === state.card.building.idname);
    return (
        <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <ConfirmModal
                open={state.show}
                action={state.action}
                text={currentActionName}
                socket={props.socket}
                onDismiss={() => setState(state => ({...state, show: false}))}
            />
            <h2>
                Tour principal
            </h2>
            <div style={{height: "40vh"}}>
                <CardCarousel
                    activeIndex={cards.map(c => c.building.idname).indexOf(state.card.building.idname)}
                    cards={cards.map(c => c.building)}
                    onSelect={handleChangeActiveCard} key={"carouselCards"}/>
            </div>
            <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
                {
                    buildingActions.map((a, i) => <Box
                        className={"board-game-build-action"}
                        sx={{width: {xs: "120%", md: "100%"}, marginLeft: {xs: "-13%", md: "-3%"}}}
                        key={i}>
                        <div className="board-game-build-action-container">
                            {getBuildComponent(a)}
                        </div>
                        <Button variant="contained" disabled={!a.ok}
                                onClick={() => setState(state => ({...state, action: a, show: true}))}>
                            Construire
                        </Button>
                    </Box>)
                }

            </div>
            <Card className="panel panel-primary" style={{marginTop: 10, marginBottom: 20}}>
                <CardContent className="panel-heading">
                    <div className={"card-title"}>
                        Description : {state.card.building.name}
                    </div>
                    <div className="panel-body">
                        <p>{state.card.building.description}</p>
                    </div>
                </CardContent>
            </Card>
            <div style={{height: "25vh"}}>

            </div>
        </div>
    )

};

export default PlayFromDiscard;
