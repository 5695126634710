import React, {useEffect, useState} from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import {CardCarousel} from "../7wonders/components/CardCarousel";
import {TMCardCarousel} from "./utils/TMCardCarousel";
import Button from "@mui/material/Button";

export const DraftComponent = (props) => {

    const [state, setState] = useState({cardId: ""});

    useEffect(() => {
        if(!state.cardId && !state.init){
            handleChangeActiveCard(0);
        }
    }, [state]);

    function handleChangeActiveCard(index) {
        setState(state => ({
            ...state,
            cardId: cards[index],
            init: true
        }));
    }

    const cards = props.state.actions[props.userId].map(action => action.draftedCardId);

    let gameState = props.state;
    return <div style={{
        marginTop: "0", marginLeft: "5%", marginRight: "5%",
        overflow: "auto", width: "90%", height: "96%", color: "#DDD"
    }}>
        <div style={{width: "100%"}}>
            {/*<div style={{float: "left"}}>*/}
            <h3>
                Phase de Draft
            </h3>
            <h4>
                {gameState.actions[props.userId] ? "Merci de choisir une carte" : "Vous avez déjà choisi"}
            </h4>
            <h4>
                {gameState.actions[props.userId] ? "Vous passerez vos cartes à " + gameState.actions[props.userId][0].playerToName : "Vous avez déjà choisi"}
            </h4>
            {/*</div>*/}
        </div>
        {
            gameState.actions[props.userId] ?
                    <TMCardCarousel
                        activeIndex={cards.indexOf(state.cardId)}
                        cards={cards}
                        onSelect={handleChangeActiveCard} key={"carouselCards"}/>
                :
                undefined
        }
        <div style={{width: "100%", textAlign: "center", margin: "2vh 0"}}>
            <Button variant="contained" onClick={() => props.draftCard(state.cardId)}>
                Choisir
            </Button>
        </div>
    </div>
}

export default DraftComponent;
