import React, {useState} from "react";
import StandardProjects from "./mainScreens/StandardProjects";
import Others from "./mainScreens/Others";
import CardsComponent from "./mainScreens/CardsComponent";
import ActionVenteBrevet from "./mainScreens/ActionVenteBrevet";
import Button from "@mui/material/Button";
import ConfirmModal from "./utils/Modal";
import Box from "@mui/material/Box";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default (props) => {


    const [state, setState] = useState({
        view: undefined,
        action: undefined,
        showPass: false
    });

    function setView(view) {
        setState(state => ({...state, view}));
    }


    function getStandardProjectActions() {
        return props.state.actions[props.userId]
            .filter(action => action.class === "model.actions.main.ActionStandardProject")
            .filter(action => action.actionNames !== "ACTION_FOLD");
    }

    function getCards() {
        return props.state.actions[props.userId]
            .filter(action => action.class === "model.actions.main.ActionCard");
    }

    function getCardActions() {
        return props.state.actions[props.userId]
            .filter(action => action.class === "model.actions.main.ActionCardAction");
    }

    function getOtherActions() {
        return props.state.actions[props.userId]
            .filter(action => [
                "model.actions.main.ActionObjective",
                "model.actions.main.ActionRecompense"].indexOf(action.class) > -1)
    }

    function getHeatAction() {
        return props.state.actions[props.userId]
            .filter(action => ["model.actions.main.ActionRaiseTemperatureByHeat"].indexOf(action.class) > -1)[0]
    }

    function getPlantAction() {
        return props.state.actions[props.userId]
            .filter(action => ["model.actions.main.ActionPlaceForestByPlant"].indexOf(action.class) > -1)[0]
    }

    function getFoldAction() {
        return props.state.actions[props.userId].filter(action => action.class === "model.actions.main.ActionFold")[0];
    }

    function getComponent() {
        switch (state.view) {
            case "STANDARD_PROJECT":
                return <StandardProjects
                    actions={getStandardProjectActions()} userId={props.userId} socket={props.socket}/>;
            case "CARDS":
                return <CardsComponent
                    actions={getCards()} userId={props.userId} socket={props.socket} actionName={"Jouer"}/>;
            case "CARD_ACTIONS":
                return <CardsComponent
                    actions={getCardActions()} userId={props.userId} socket={props.socket} actionName={"Activer"}/>;
            case "DISCARD_CARDS":
                return <ActionVenteBrevet
                    cards={props.state.hands[props.userId]} userId={props.userId} socket={props.socket}/>;
            case "OTHERS":
                return <Others
                    actions={getOtherActions()} userId={props.userId} socket={props.socket}
                />
        }
        return <div/>;
    }

    const dictViewName = {
        "CARDS": "Jouer une carte",
        "STANDARD_PROJECT": "Projets standards",
        "DISCARD_CARDS": "Vendre des cartes",
        "CARD_ACTIONS": "Activer une carte",
        "OTHERS": "Obj. & Récompenses"
    };

    if (!props.state.actions[props.userId]) {
        return <div style={{color: "#FFF", fontSize: "28", marginTop: "24px", marginLeft: "36px"}}>
            A priori ce n'est pas encore votre tour!
            (mais y'a p'tet un bug, on sait jamais...)
        </div>
    }
    let buttonKeys = [
        {
            name: "Jouer une carte",
            description: "Jouer une carte de votre main",
            onClick: () => setView("CARDS"),
            get: getCards,
            width: "90%",
            hue: 0,
            showPossible: true
        },
        {
            name: "Actions",
            description: "Activer une de vos cartes",
            onClick: () => setView("CARD_ACTIONS"),
            get: getCardActions,
            width: "90%",
            hue: 0,
            showPossible: false
        },
        {
            name: "Chauffer",
            description: "Dépenser de la chaleur",
            get: () => [getHeatAction()],
            width: "36%",
            hue: 166,
            showPossible: false,
            onClick: () => {
                setState(state => ({...state, action: getHeatAction(), text: "Voulez-vous monter la température ?", show: true}))
            }
        },
        {
            name: "Planter",
            description: "Dépenser des plantes",
            get: () => [getPlantAction()],
            width: "36%",
            hue: 272,
            showPossible: false,
            onClick: () => {
                setState(state => ({...state, action: getPlantAction(), text: "Voulez-vous planter une forêt ?", show: true}))
            }
        },
        {
            name: "Projets",
            description: "Payer un projet standard",
            onClick: () => setView("STANDARD_PROJECT"),
            get: getStandardProjectActions,
            width: "36%",
            hue: 0,
            showPossible: false
        },
        {
            name: "Vendre",
            onClick: () => setView("DISCARD_CARDS"),
            get: () => {
                return getCards().map(() => ({ok: true}))
            },
            description: "Vendre des cartes",
            hue: 0,
            width: "36%", showPossible: false
        },
        {
            name: "Obj/Rec",
            onClick: () => setView("OTHERS"),
            get: getOtherActions,
            width: "90%",
            hue: 0,
            showPossible: false,
            description: "Objectifs & Récompenses"
        },
    ];
    let modal = <ConfirmModal
        action={state.action}
        text={state.text}
        socket={props.socket}
        onDismiss={() => setState({show: false})}
        open={!!state.show}
    />;
    return <Box className="board-game" sx={{width: {xs: "100%", md: "50%"}}}>
        {modal}
        {
            state.view ?
                <div>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '60px', alignItems: 'center'}}>
                        <Button variant={"contained"}
                                size="small"
                                style={{minWidth: "unset"}}
                                onClick={() => setState(state => ({...state, view: undefined}))}>
                            <ArrowBackIosNewIcon fontSize="small"/>
                        </Button>
                        <div style={{textAlign: 'left', marginLeft: '14px', fontSize: '1.4em'}}>
                            {dictViewName[state.view]}
                        </div>
                    </div>
                    {getComponent()}
                </div> :
                <div>
                    <h2 style={{width: "100%", textAlign: "center"}}>
                        À vous de jouer !
                    </h2>
                    <div style={{display: "inline-flex", flexWrap: "wrap", width: '100%'}}>
                        {
                            buttonKeys.map(k =>
                                <div key={k.name} style={{
                                    backgroundColor: k.get().filter(a => a.ok).length ? "#10346f" : "#1f2136",
                                    width: k.width,
                                    filter: 'hue-rotate(' + k.hue + 'deg)',
                                    padding: '1vh 5%',
                                    borderRadius: '15px',
                                    margin: '1vh 2%'
                                }}>
                                    <div style={{marginBottom: '2vh'}}>
                                        {k.description}
                                        {
                                            k.showPossible ?
                                                <span><br/>({k.get().filter(a => a.ok).length} possible{getCards().filter(a => a.ok).length > 1 ? 's' : ''})</span> :
                                                undefined
                                        }
                                    </div>
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <Button variant={"contained"} disabled={k.get().filter(a => a.ok).length === 0}
                                                onClick={k.onClick}>
                                            {k.name}
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div style={{width: "100%", padding: "auto", margin: "2vh 0", height: "14vh", textAlign: "right"}}>
                        <Button variant={"contained"}
                                onClick={() => setState(state => ({...state, show: true, text: "Voulez vous passer ?", action: getFoldAction()}))}>
                            Passer
                        </Button>
                    </div>
                </div>
        }
    </Box>

}
