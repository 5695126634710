import callApi from "../api/actions";
import buildURLQueryParams from "../utils/buildURLQueryParams";

import {getPlayer} from "../player/actions";

export const RESET_GAMES = 'RESET_GAMES';
export const ADD_GAME = 'ADD_GAME';
export const SET_GAME_PROPERTY = 'SET_GAME_PROPERTY';

export function resetGames() {
    return {type: RESET_GAMES};
}
export function addGame(game) {
    return {type: ADD_GAME, game};
}
export function setGameProperty(id, key, value) {
    return {type: SET_GAME_PROPERTY, id, key, value};
}

export const getGames = (params = {}) => {
    return dispatch => {
        params.status = "lobby";
        let call = buildURLQueryParams('/gameslist', params);
        return dispatch(
            callApi(call, (games) => dispatch => {
                    dispatch(resetGames());
                    games.forEach((game) => {
                        dispatch(addGame(game));
                        dispatch(getGameNumberPlayer(game));
                    });
                }
            )
        );
    };
};

export const getGameNumberPlayer = (game) => {
    return dispatch => {
        const {gamekey, gameId} = game;
        let call = buildURLQueryParams('/connectedPlayers', {
            gamekey : game.gamekey,
            gameId : ""+game._id});
        return dispatch(
            callApi(call, (players) => dispatch => {
                    dispatch(setGameProperty(game["_id"], "numberPlayer", players.length));
                }
            )
        );
    }
}

export const connectGame = (game, user) => {
    return dispatch => {
        let call = buildURLQueryParams('/connectedPlayers');
        let body = {
            gamekey : game.gamekey,
            gameId : ""+game._id,
            userId : user._id
        };
        return dispatch(
            callApi(call,
                ((data) => () => {
                    return dispatch(getPlayer(user));
                }),
                {
                    method : 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
        )
    }
}

export const endGame = (gamekey, gameId) => {
    return dispatch => {
        let call = "/game/"+gamekey+"/"+gameId;
        return dispatch(
            callApi(call,
                (() => () => {}),
                {
                    method : 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({})
                }
            )
        )
    }
}

export const launchGame = (gamekey, user, maxPlayers) => {
    return dispatch => {
        let callNewGame = "/gameslist";
        let callNewUser = "/connectedPlayers";
        let bodyNewGame = {
            gameowner : ""+user._id,
            gamekey,
            maxPlayers
        }
        return dispatch(
            callApi(callNewGame,
                ((newGame) => () => {
                    if(newGame.err){
                        return;
                    }
                    let bodyNewUser = {
                        userId : ""+user._id,
                        gameId : ""+newGame._id,
                        gamekey
                    };
                    return dispatch(
                        callApi(callNewUser,
                            (() => () => {return dispatch(getPlayer(user));}),
                            {
                                method : 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(bodyNewUser)
                            }
                        )
                    );
                }),
                {
                    method : 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyNewGame)
                }
            )
        )
    }
}
