import React, {useEffect, useState} from "react";
import {getCountryFlagName} from "./utils";
import {RawMaterialBlock} from "./RawMaterialBlock";
import {BuyersBlock} from "./BuyersBock";
import {ConsumptionPanel} from "./ConsumptionPanel";

export const CountryConsoPage = (props) => {

    const [selectedConsommationResource, setSelectedConsommationResource] = useState(props.world.countries[props.country].neededRawMaterial1);

    function emitOrderDistributionMoneyPoints(modification) {
        const newOrderDistributionMoneyPoints = Math.max(0, Math.min(100, props.world.countries[props.country].orderDistributionMoneyPoints + modification));
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderDistributionMoneyPoints: newOrderDistributionMoneyPoints
        });
        props.world.countries[props.country].orderDistributionMoneyPoints = newOrderDistributionMoneyPoints;
    }

    return <div>
        <h2 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img
                style={{height: '34px', width: 'auto', marginRight: '24px'}}
                src={"/public/anniv/2024/flags/" + getCountryFlagName(props.country) + ".png"}/>
            {props.country}
        </h2>
        <div>
            <h2 style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span>
                                {props.editable ? "Votre consommation" : "Le pays consomme"}
                            </span>
                <span>
                                {props.world.countries[props.country].actualConsumption}
                            </span>
            </h2>
            <div>
                Ratio consommation:
                <br/>
                <div className={"distribution-bar"}>
                    <div>
                        <img style={{height: '48px', width: 'auto', marginRight: '24px'}}
                             src={"/public/anniv/2024/money.png"}/>
                        Argent - {100 - props.world.countries[props.country].distributionMoneyPoints}%
                    </div>
                    <div>
                        {props.world.countries[props.country].distributionMoneyPoints}% - Points
                        <img style={{height: '48px', width: 'auto', marginLeft: '24px'}}
                             src={"/public/anniv/2024/points.png"}/>
                    </div>
                    <div style={{
                        position: "absolute",
                        height: '48px',
                        width: '3px',
                        backgroundColor: 'white',
                        left: (25 + props.world.countries[props.country].distributionMoneyPoints / 2) + "%"
                    }}>

                    </div>
                </div>
                <br/>
                {
                    props.editable && <div className={"top-line"}>
                        <div>
                            Ratio souhaité : {props.world.countries[props.country].orderDistributionMoneyPoints}%
                        </div>
                        {
                            [-5, -1, 1, 5].map(modification =>
                                <button
                                    disabled={(modification < 0 && props.world.countries[props.country].orderDistributionMoneyPoints <= 0) || (modification > 0 && props.world.countries[props.country].orderDistributionMoneyPoints >= 100)}
                                    onClick={() => emitOrderDistributionMoneyPoints(modification)}
                                    key={modification}
                                    style={{marginLeft: '12px'}}
                                    className={"small-button"}>
                                    {modification}
                                </button>
                            )
                        }
                    </div>
                }
            </div>
            <div className={"production-bar"}>
                <div className={"consumption-item"}
                     onClick={() => setSelectedConsommationResource(props.world.countries[props.country].neededRawMaterial1)}
                     style={{
                         background: selectedConsommationResource === props.world.countries[props.country].neededRawMaterial1 ?
                             'linear-gradient(180deg, #0a53be 0%, #191b31 100%' : 'rgba(0,0,0,0.3)'
                     }}>
                    <img style={{height: '60px', width: 'auto'}}
                         src={"/public/anniv/2024/resources/" + props.world.countries[props.country].neededRawMaterial1.toLowerCase() + ".png"}/>
                    <div style={{
                        marginRight: "2px",
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }}>
                        <div>{props.world.countries[props.country].neededRawMaterial1}</div>
                        <div
                            style={{fontSize: '18px'}}>
                            {props.world.countries[props.country].actualConsumptionRawMaterial1 || 0} / {props.world.countries[props.country].maximalConsumption}
                        </div>
                        <div
                            style={{fontSize: '13px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img style={{height: '13px', width: 'auto'}} src={"/public/anniv/2024/money_target.png"}/>
                            {props.world.countries[props.country].targetConsumptionRawMaterial1 || 0} / {props.world.countries[props.country].maximalConsumption}
                        </div>
                    </div>
                </div>
                <div className={"consumption-item"}
                     onClick={() => setSelectedConsommationResource(props.world.countries[props.country].neededRawMaterial2)}
                     style={{
                         background: selectedConsommationResource === props.world.countries[props.country].neededRawMaterial2 ?
                             'linear-gradient(180deg, #0a53be 0%, #191b31 100%' : 'rgba(0,0,0,0.3)'
                     }}>
                    <img style={{height: '60px', width: 'auto'}}
                         src={"/public/anniv/2024/resources/" + props.world.countries[props.country].neededRawMaterial2.toLowerCase() + ".png"}/>
                    <div style={{
                        marginRight: "2px",
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }}>
                        <div>{props.world.countries[props.country].neededRawMaterial2}</div>
                        <div
                            style={{fontSize: '18px'}}>
                            {props.world.countries[props.country].actualConsumptionRawMaterial2 || 0} / {props.world.countries[props.country].maximalConsumption}
                        </div>
                        <div
                            style={{fontSize: '13px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img style={{height: '13px', width: 'auto'}} src={"/public/anniv/2024/money_target.png"}/>
                            {props.world.countries[props.country].targetConsumptionRawMaterial2 || 0} / {props.world.countries[props.country].maximalConsumption}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <BuyersBlock world={props.world} rawMaterial={selectedConsommationResource} country={props.country}/>
            </div>
            {
                selectedConsommationResource ?
                    <ConsumptionPanel emitOrderBuy={props.emitOrderBuy} world={props.world}
                                      country={props.country}
                                      editable={props.editable}
                                      neededRawMaterial={selectedConsommationResource}/>
                    : <div style={{width: '12px', height: '40vh'}}></div>
            }
        </div>
    </div>
}
