export const selectApi = (state) => state.api;

export const isLoadingForKey = (state, key) => {
    if(selectApi(state) && selectApi(state).status && selectApi(state).status[key])
        return selectApi(state).status[key].isLoading;

    return false;
};

export const getErrorForKey = (state, key) => {
    if(selectApi(state).status[key])
        return selectApi(state).status[key].error;

    return undefined;
};
