import callApi from "../api/actions";
import buildURLQueryParams from "../utils/buildURLQueryParams";

import * as TokenManager from "../utils/tokenManager";
import constants from "../../config/constants";
import axios from "axios";


export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const RESET_ERROR = "RESET_ERROR";
export const SET_CAN_PLAY_7_WONDERS = "SET_CAN_PLAY_7_WONDERS";
export const SET_EMAIL_VERIFIED = "SET_EMAIL_VERIFIED";

export function signinError(error) {
    return {type: SIGNIN_ERROR, error};
}

export function setUser(user) {
    return {type: SET_USER, user};
}

export function setUsers(users) {
    return {type: SET_USERS, users};
}

export function resetError() {
    return {type: RESET_ERROR};
}

export function setVerifiedEmail() {
    return {type: SET_EMAIL_VERIFIED};
}

export function setCanPlay7wonders(userId, canPlay7wonders) {
    return {type: SET_CAN_PLAY_7_WONDERS, userId, canPlay7wonders};
}

export const signin = (email, password, callbackError) => {
    return dispatch => {
        axios.post(constants.api.basePath + "/userinfo/signin", {
            email,
            password
        }).then(
            response => {
                let user = response.data;
                TokenManager.setToken({
                    username: user.username,
                    email: user.email,
                    password
                });
                dispatch(setUser(user));
            }
        ).catch(error => {
            callbackError(error);
        });
    };
};

export const signup = (credentials) => {
    return dispatch => {
        const {email, password} = credentials;
        return dispatch(
            callApi(buildURLQueryParams('/userinfo/signup'),
                (response) => dispatch => {
                    if (response.err) {
                        dispatch(signinError(response.err));
                    } else {
                        dispatch(signin(credentials));
                    }
                },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                }
            )
        );
    };
};

export const signinGetUserInfosFromStorage = () => {
    return dispatch => {
        let token = TokenManager.getToken();
        if (token.email && token.email !== "a" && token.email !== "b") {
            return dispatch(signin(token.email, token.password, () => {}));
        }
    };
};

export const logout = () => {
    TokenManager.removeToken();
    return setUser();
};

export const fetchUsers = () => dispatch => dispatch(callApi(buildURLQueryParams("/users"), (response) => dispatch => dispatch(setUsers(response.users))));

export const patchCanPlay7wonders = (caller, user, canPlay7wonders) => dispatch => dispatch(
    callApi(buildURLQueryParams("/users/canPlay7wonders"), () => {}, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({callerId: caller._id, password: TokenManager.getToken().password, userId: user._id, canPlay7wonders})
    })
);
