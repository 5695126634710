import React from "react";

export default class ConsultCentrale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }

    render() {
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <div style={{
                width: "100%",
                marginTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <h4>Centrales disponibles</h4>
                {
                    this.props.availablePlants.map(p => <div key={p.price}
                                                             style={{width: "100%", padding: "1% 5%"}}
                    >
                        <img alt={"centrale " + p.price}
                             src={"../../../../public/megawatt/centrales/smalls/centrale_small_" + p.price + ".png"}/>
                    </div>)
                }
                <h4>Centrales futures</h4>
                {
                    this.props.futurePlants.map(p => <div key={p.price}
                                                             style={{width: "100%", padding: "1% 5%"}}
                    >
                        <img alt={"centrale " + p.price}
                             src={"../../../../public/megawatt/centrales/smalls/centrale_small_" + p.price + ".png"}/>
                    </div>)
                }
            </div>
        </div>
    }
}
