import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default class ActionNavetteTitan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: props.actions[props.userId][1],
            show: false
        };
        this.canIncreaseValue = this.canIncreaseValue.bind(this);
        this.canDecreaseValue = this.canDecreaseValue.bind(this);
        this.increaseValue = this.increaseValue.bind(this);
        this.decreaseValue = this.decreaseValue.bind(this);
    }

    componentDidMount() {
    }

    canIncreaseValue(){
        let value = this.state.action.value;
        return this.props.actions[this.props.userId].some(a => a.value === value + 1);
    }

    canDecreaseValue(){
        let value = this.state.action.value;
        return this.props.actions[this.props.userId].some(a => a.value === value - 1);
    }

    increaseValue(){
        let value = this.state.action.value;
        let newAction = this.props.actions[this.props.userId].filter(a => a.value === value + 1)[0];
        if(newAction){
            this.setState({action: newAction});
        }
    }

    decreaseValue(){
        let value = this.state.action.value;
        let newAction = this.props.actions[this.props.userId].filter(a => a.value === value - 1)[0];
        if(newAction){
            this.setState({action: newAction});
        }
    }

    styleButton = {
        backgroundColor: "#444",
        padding: "10px",
        borderRadius: "8px",
        marginTop: "6px",
        marginBottom: "12px",
        width: "60%"
    };

    render() {

        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={ "Voulez-vous échanger " + this.state.action.value + " flotteurs ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        let modal2 = <ConfirmModal
            action={this.state.show2 ? this.props.actions[this.props.userId][0] : undefined}
            text={ "Voulez-vous ajouter deux flotteurs à une carte jovienne?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show2: false})}
            open={this.state.show2}
        />;


        return <div style={{margin: "0", overflow: "hidden", width: "100%", height: "100%"}}>
            {modal}
            {modal2}
            <div style={{margin: "3vh 0", fontSize: "20px"}}>
                Souhaitez-vous échanger des flotteurs contre du titane ?
            </div>
            <div style={{width: "100%", display: "flex", margin: "2vh 0", flexDirection: "row"}}>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <Button style={{minWidth: "unset"}}
                            variant={"outlined"}
                            disabled={!this.canDecreaseValue()}
                            onClick={() => this.decreaseValue()}>
                        <RemoveIcon/>
                    </Button>
                </div>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center", height: "100%"}}>
                    {this.state.action.value}
                    <img
                        style={{height: "auto", width: "50%", marginLeft: "5px"}}
                        src={"/public/terraforming/icons/resource_flotteur.png"} alt="card"/>
                </div>
                <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <Button style={{minWidth: "unset"}}
                            variant={"outlined"}
                            disabled={!this.canIncreaseValue()}
                            onClick={() => this.increaseValue()}>
                        <AddIcon/>
                    </Button>
                </div>
            </div>
            <div style={{fontSize: "20px", width: "100%", textAlign: "center", margin: "3vh 0"}}>
                <Button onClick={() => this.setState({show: true})}
                        variant={"contained"}
                >
                    Echanger
                </Button>
            </div>
            <div style={{fontSize: "20px", margin: "9vh 0 3vh"}}>
                Ou ajouter deux flotteurs à une carte jovienne ?
            </div>
            <div style={{margin: "3vh 0", fontSize: "20px", width: "100%", textAlign: 'center'}}>
                <Button onClick={() => this.setState({show2: true})}
                        variant={"contained"}
                >
                    Ajouter deux flotteurs
                </Button>
            </div>
        </div>
    }
}
