import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default class CardsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: undefined
        };
    }

    render() {
        return <Box sx={{width: {xs: "100%", md: "50%"}}}>
            {
                this.state.action ?
                    <div onClick={() => this.setState({action: undefined})}
                         style={{width: "100%", textAlign: "center"}}>
                        <img src={
                            this.state.action.cardId ? "/public/terraforming/cards/img_" + this.state.action.cardId + ".png" :
                                "/public/terraforming/corpos/corpo_card_" + this.state.action.corpoName.toLowerCase() + "_up.png"
                        }
                             alt={"img " + this.state.action.cardId}
                             style={{width: "auto", height: "50vh"}}>
                        </img>
                        <br/>
                        <Button variant={"contained"}
                                style={{marginTop: '2vh'}}
                                disabled={!this.state.action.ok}
                                onClick={() => this.props.socket.emit('action', this.state.action)}>
                            {this.state.action.ok ? this.props.actionName : "Indisp."}
                        </Button>
                    </div> :
                    <div style={{display: 'inline-flex', flexWrap: "wrap", justifyContent: 'center'}}>
                        {
                            this.props.actions.map(action =>
                                <div key={action.cardId}
                                     onClick={() => this.setState({action})}
                                     style={{display: "flex", margin: "2vh 5px"}}>
                                    <img src={
                                        action.cardId ? "/public/terraforming/cards/img_" + action.cardId + ".png" :
                                            "/public/terraforming/corpos/corpo_card_" + action.corpoName.toLowerCase() + "_up.png"
                                    }
                                         alt={"img " + action.cardId}
                                         style={{width: "20vw", height: "auto", opacity: action.ok ? '100%' : '50%'}}>
                                    </img>
                                </div>)
                        }
                    </div>
            }
        </Box>
    }
}
