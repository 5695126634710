import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";

export default class ActionRemoveAny extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: undefined,
            userId: undefined,
            show: false
        };
    }

    componentDidMount() {
    }

    styleButton = {
        backgroundColor: "#444",
        padding: "10px",
        height: "23%",
        borderRadius: "8px",
        marginTop: "6px",
        marginBottom: "12px",
        marginLeft: "20%",
        width: "60%"
    };

    render() {
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={"Confirmez-vous votre choix ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        let playerIds = [];
        for (let action of this.props.actions[this.props.userId]) {
            if (playerIds.indexOf(action.victimId) === -1) {
                playerIds.push(action.victimId);
            }
        }
        console.log(this.props.actions[this.props.userId]);
        return <div>
            {modal}
            <h2>
                Enlever des ressources
            </h2>
            <div style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD",
                display: "flex", flexDirection: "row"
            }}>
            <div style={{flex: 1, display: "block"}}>
                {
                    playerIds.map(playerId => <Button
                        variant={this.state.userId === playerId ? "contained": "outlined"}
                        key={playerId}
                        style={{marginBottom: "2vh"}}
                        onClick={() => this.setState({userId: playerId})}>
                        {this.props.actions[this.props.userId].filter(a => a.victimId === playerId)[0].victimName}
                    </Button>)
                }
            </div>
            <div style={{flex: 1, display: "block"}}>
                {
                    this.props.actions[this.props.userId].filter(a => a.victimId === this.state.userId).map(action =>
                        <Button
                            key={action.victimId + " " + action.resources}

                            onClick={() => this.setState({action, show: true})}
                            variant={"contained"}>
                            {
                                action.isProd ?
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        - {action.nbResources} <img
                                        src={"/public/terraforming/icons/prod_" + action.resources + ".png"}
                                        style={{height: "20px", width: "auto", marginLeft: '8px'}}
                                        alt={action.resources}/></div>
                                    :
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        - {action.nbResources} <img
                                        src={"/public/terraforming/icons/resource_" + action.resources + ".png"}
                                        style={{height: "20px", width: "auto", marginLeft: '8px'}}
                                        alt={action.resources}/></div>

                            }
                        </Button>)
                }
            </div>
            </div>
        </div>
    }

}

