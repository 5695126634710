import React, {useEffect, useState} from "react";

export const PartyComponent = (props) => {

    const [selectedParty, setSelectedParty] = useState("LIBERALISM");
    const parties = [
        "COMMUNISM",
        "SOCIALISM",
        "LIBERALISM",
        "CONSERVATISM",
        "NATIONALISM",
    ]

    const isInvestingInParty = (party) => {
        return props.world.countries[props.country].orderPoliticalInvestment[party] > 0;
    }

    return <div>
        <div className={"investment-box-title"}>
            Les partis politiques d'{props.world.continents[props.world.countries[props.country].continentName].name}
        </div>
        <div className={"investment-box-title"}>
            Parti au pouvoir
            : {props.world.continents[props.world.countries[props.country].continentName].politicalLeaderParty}
        </div>
        <div className={"political-top-line"}>
            {
                parties.map(party => {
                    return <div key={party}
                                className={(selectedParty === party ? "selected" : "") +
                                    (isInvestingInParty(party) ? " investing" : "") +
                                    " party-button"}
                                onClick={() => setSelectedParty(party)}>
                        <img src={"/public/anniv/2024/parties/" + party.toLowerCase() + ".png"}/>
                        {party.substring(0, 1) + party.substring(1, 3).toLowerCase()}.
                    </div>
                })
            }
        </div>
        <div className={"invest-box"}>
            <div className={"invest-box-left"}>
                <div></div>
                <img src={"/public/anniv/2024/parties/" + selectedParty.toLowerCase() + ".png"}/>
            </div>
            <div className={"invest-box-right"}>
                <div className={"invest-first-row"}>
                    {selectedParty.substring(0, 1) + selectedParty.substring(1).toLowerCase()}e
                </div>
                <div className={"invest-row"}>
                    <span>
                        Votre investissement:
                    </span>
                    <span>
                        {props.world.countries[props.country].politicalInvestment[selectedParty]}
                    </span>
                </div>
                <div className={"invest-row"}>
                    <span>
                        Investissment cible:
                    </span>
                    <span>
                        {props.world.countries[props.country].orderPoliticalInvestment[selectedParty]}
                    </span>
                </div>
                {
                    props.editable && <div className={"invest-row"}>
                        {
                            [-5, -1, 1, 5].map(modification =>
                                <button
                                    disabled={(modification < 0 && props.world.countries[props.country].orderPoliticalInvestment[selectedParty] <= 0)}
                                    onClick={() => props.emitOrderPoliticalInvestment(selectedParty, modification)}
                                    key={modification}
                                    className={"small-button"}>
                                    {modification}
                                </button>
                            )
                        }
                    </div>
                }
                <br/>
            </div>
        </div>
        <div>
            {
                selectedParty === "COMMUNISM" && <div>
                    <b>Parti Communiste</b> : <br/>
                    Lorsqu'il passe au pouvoir, chaque pays redescend à 1 usine.<br/>
                    Construire des usines est interdit, les niveaux d'infrastructure coûtent 50% moins cher.
                </div>
            }
            {
                selectedParty === "SOCIALISM" && <div>
                    <b>Parti Socialiste</b> : <br/>
                    Construire des usines est plus 25% cher.<br/>
                    Les niveaux d'infrastructure coûtent 25% moins cher.
                </div>
            }
            {
                selectedParty === "LIBERALISM" && <div>
                    <b>Parti Libéral</b> : <br/>
                    Aucun effet particulier.
                </div>
            }
            {
                selectedParty === "CONSERVATISM" && <div>
                    <b>Parti Conservateur</b> : <br/>
                    Constuire des usines est 25% moins cher.<br/>
                    Investir dans la population est 25% moins cher.<br/>
                    Investir dans les infrastructures est 50% plus cher
                </div>
            }
            {
                selectedParty === "NATIONALISM" && <div>
                    <b>Parti Nationaliste</b> : <br/>
                    Lorsqu'il passe au pouvoir: tous les joueurs avec le plus d'usine doublent leur nombre d'usines.<br/>
                    Construire des usines est 50% moins cher.<br/>
                    Investir dans les infrastructures est 50% moins cher.<br/>
                    Investir dans les infrastructures est interdit.
                </div>
            }

        </div>
    </div>
}
