import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

const style = {
    height: "200px",
    width: "400px",
    maxWidth: "80vw"
};

export const ConfirmModal = (props) => {
    const {action, text, socket, onDismiss, open} = props;
    return <Dialog open={open}
                   // style={style}
                   onClose={onDismiss}>
        <DialogTitle id="alert-dialog-title">
            Confirmation
        </DialogTitle>
        <DialogContent>
            {text}
        </DialogContent>
        <DialogActions>
            <Button onClick={onDismiss} variant="outlined">Non</Button>
            <Button autoFocus variant="contained" onClick={() => {
                socket.emit("action", action);
                onDismiss();
            }}>
                Oui
            </Button>
        </DialogActions>
    </Dialog>;
};

export default ConfirmModal;
