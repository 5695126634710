import React from "react";

export default class StateIntroTTA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            position: -1,
            takenPosition: [],
            color: {r: 0.75, g: 0.75, b: 0.75, a: 1}
        };
        this.getBackgroundColor = this.getBackgroundColor.bind(this);
        this.getPlayerNameByColor = this.getPlayerNameByColor.bind(this);
        this.getHTMLColorBorder = this.getHTMLColorBorder.bind(this);
        this.getPlayerNameAtPosition = this.getPlayerNameAtPosition.bind(this);
        this.emitState = this.emitState.bind(this);
        this.colors = [
            {r: 0.65, g: 0.05, b: 0.05, a: 1},
            {r: 0.05, g: 0.65, b: 0.05, a: 1},
            {r: 0.05, g: 0.05, b: 0.65, a: 1},
            {r: 0.65, g: 0.65, b: 0.05, a: 1},
            {r: 0.65, g: 0.05, b: 0.65, a: 1},
            {r: 0.05, g: 0.65, b: 0.65, a: 1},
            {r: 0.75, g: 0.55, b: 0.05, a: 1},
            {r: 0.75, g: 0.75, b: 0.75, a: 1}
        ]
    }

    componentDidMount() {

    }

    getPlayer() {
        return this.props.state.networkPlayers.filter(np => np.userId === this.props.userId)[0];
    }

    render() {
        return <div style={{
            width: "100%", height: "100%", di1splay: "flex", flexDirection: "column", padding: "10px"
        }}>
            <h3>{this.props.username}</h3>
            <h4>Choisissez votre place</h4>
            <div style={{
                width: "80%",
                marginLeft: "10%",
                height: "80px",
                display: "inline-block",
                marginBottom: "10px"
            }}>
                <div
                    onClick={() => {
                        if (this.props.state.takenPosition.indexOf(0) === -1) {
                            this.setState({position: 0}, () => this.emitState());
                        }
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "left",
                        width: "40%",
                        marginRight: "5%",
                        height: "100%",
                        borderRadius: "5px",
                        backgroundColor: this.getBackgroundColor(0),
                        color: "#FFF"
                    }}>
                    {this.getPlayerNameAtPosition(0)}
                </div>
                <div
                    onClick={() => {
                        if (this.props.state.takenPosition.indexOf(1) === -1) {
                            this.setState({position: 1}, () => this.emitState());
                        }
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "left",
                        width: "40%",
                        height: "100%",
                        borderRadius: "5px",
                        backgroundColor: this.getBackgroundColor(1),
                        color: "#FFF"
                    }}>
                    {this.getPlayerNameAtPosition(1)}
                </div>
            </div>
            <div style={{width: "80%", marginLeft: "10%", height: "80px", display: "inline-block"}}>
                <div
                    onClick={() => {
                        if (this.props.state.takenPosition.indexOf(2) === -1) {
                            this.setState({position: 2}, () => this.emitState());
                        }
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "left",
                        width: "40%",
                        marginRight: "5%",
                        height: "100%",
                        borderRadius: "5px",
                        backgroundColor: this.getBackgroundColor(2),
                        color: "#FFF"
                    }}>
                    {this.getPlayerNameAtPosition(2)}
                </div>
                <div
                    onClick={() => {
                        if (this.props.state.takenPosition.indexOf(3) === -1) {
                            this.setState({position: 3}, () => this.emitState());
                        }
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "left",
                        width: "40%",
                        height: "100%",
                        borderRadius: "5px",
                        backgroundColor: this.getBackgroundColor(3),
                        color: "#FFF"
                    }}>
                    {this.getPlayerNameAtPosition(3)}
                </div>
            </div>
            <h4>Choisissez votre couleur</h4>
            <div style={{
                width: "80%",
                marginLeft: "10%",
                display: "flex",
                marginBottom: "10px"
            }}>
                <div style={{
                    flex: 1,
                    display: "block"
                }}>
                    {
                        [0, 2, 4, 6].map(n =>
                            <div
                                key={n}
                                onClick={() => {
                                    if (!this.isColorTaken(this.colors[n])) {
                                        this.setState({color: this.colors[n]}, () => this.emitState());
                                    }
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    float: "left",
                                    marginBottom: "12px",
                                    width: "90%",
                                    marginRight: "5%",
                                    padding: "12px",
                                    borderRadius: "5px",
                                    border: this.getHTMLColorBorder(n),
                                    backgroundColor: this.getHTMLcolor(n),
                                    color: "#FFF"
                                }}>
                                {this.getPlayerNameByColor(n)}
                            </div>
                        )
                    }
                </div>
                <div style={{
                    flex: 1,
                    display: "block"
                }}>
                    {
                        [1, 3, 5, 7].map(n =>
                            <div
                                key={n}
                                onClick={() => {
                                    if (!this.isColorTaken(this.colors[n])) {
                                        this.setState({color: this.colors[n]}, () => this.emitState());
                                    }
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    float: "left",
                                    width: "90%",
                                    marginRight: "5%",
                                    padding: "12px",
                                    marginBottom: "12px",
                                    borderRadius: "5px",
                                    border: this.getHTMLColorBorder(n),
                                    backgroundColor: this.getHTMLcolor(n),
                                    color: "#FFF"
                                }}>
                                {this.getPlayerNameByColor(n)}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    }

    getBackgroundColor(position) {
        return (this.getPlayer() && this.getPlayer().position === position ? "#149" : this.props.state.takenPosition.indexOf(position) > -1 ? "#999" : "#222");
    }


    emitState() {
        this.props.socket.emit("id", {
            type: "PLAYER",
            gameId: this.props.gameId,
            userId: "" + this.props.userId,
            username: this.props.username,
            color: this.state.color,
            position: this.state.position
        })
    }

    getHTMLcolor(index) {
        return "rgb(" + 255 * this.colors[index].r + "," + 255 * this.colors[index].g + "," + 255 * this.colors[index].b + ")";
    }

    getPlayerNameByColor(index) {
        for (let player of this.props.state.networkPlayers) {
            if (this.areColorEquals(player.color, this.colors[index])) {
                return player.username;
            }
        }
        return ["RED", "GREEN", "BLUE", "YELLOW", "MAGENTA", "CYAN", "ORANGE", "WHITE"][index];
    }

    getHTMLColorBorder(index) {
        return "solid 5px " + (this.getPlayer() && this.areColorEquals(this.getPlayer().color, this.colors[index]) ? "#FFF" : (this.isColorTaken(this.colors[index]) ? "#999" : "#222"));
    }

    areColorEquals(color1, color2) {
        if (!color1 || !color2) {
            return false;
        }
        return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b;
    }

    isColorTaken(color) {
        for (let c of this.props.state.takenColor) {
            if (this.areColorEquals(c, color)) {
                return true;
            }
        }
        return false;
    }

    isPositionTaken(position) {
        for (let p of this.props.state.takenPosition) {
            if (p === position) {
                return true;
            }
        }
        return false;
    }

    getPlayerNameAtPosition(position) {
        for (let player of this.props.state.networkPlayers) {
            if (player.position === position) {
                return player.username;
            }
        }
        return "ABCD"[position];
    }

}
