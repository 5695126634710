import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import * as utils from "../../SevenWonders/utils";

export const CardCarousel = (props) => {

    const {cards, onSelect} = props;
    return (
        <div style={{width: "100%", height: "100%"}}>
            <Carousel
                selectedItem={props.activeIndex}
                onChange={(index,) => {
                    onSelect(index);
                }}
            >
                {
                    cards.map((card, index) => {
                        return (
                                <img key={index} style={{width: "auto", height: "90%"}} alt={card.idname}
                                     src={"../../../public/7wonders/images/cartes/" + card.idname + ".png"}/>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}
