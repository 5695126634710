import React, {useEffect, useState} from "react";
import {getCountryFlagName} from "./utils";
import {RawMaterialBlock} from "./RawMaterialBlock";
import {BuyersBlock} from "./BuyersBock";

export const CountryProdPage = (props) => {

    const [selectedProductionResource, setSelectedProductionResource] = useState(props.world.countries[props.country].rawMaterial1);

    function emitOrderDistributionRawMaterial(modification) {
        const newOrderDistributionRawMaterial = Math.max(0, Math.min(100, props.world.countries[props.country].orderDistributionRawMaterial + modification));
        props.emitOrderCountry({
            countryName: props.country,
            playerName: props.player,
            orderDistributionRawMaterial: newOrderDistributionRawMaterial
        })
        props.world.countries[props.country].orderDistributionRawMaterial = newOrderDistributionRawMaterial;
    }

    return <div>
        <h2 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img
                style={{height: '34px', width: 'auto', marginRight: '24px'}}
                src={"/public/anniv/2024/flags/" + getCountryFlagName(props.country) + ".png"}/>
            {props.country}
        </h2>
        <div>
            <h2 style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <span>
                    {props.editable ? "Vous produisez" : "Le pays produit"}
                </span>
                <span>
                    {props.world.countries[props.country].nbRawMaterialProducted1 + props.world.countries[props.country].nbRawMaterialProducted2}
                </span>
            </h2>
            <div>
                Ratio actuel entre resources:
                <br/>
                <div className={"distribution-bar"}>
                    <div>
                        <img style={{height: '48px', width: 'auto', marginRight: '24px'}}
                             src={"/public/anniv/2024/resources/" + props.world.countries[props.country].rawMaterial1.toLowerCase() + ".png"}/>
                        {props.world.countries[props.country].rawMaterial1} - {props.world.countries[props.country].distributionRawMaterial}%
                    </div>
                    <div>
                        {100 - props.world.countries[props.country].distributionRawMaterial}%
                        - {props.world.countries[props.country].rawMaterial2}
                        <img style={{height: '48px', width: 'auto', marginLeft: '24px'}}
                             src={"/public/anniv/2024/resources/" + props.world.countries[props.country].rawMaterial2.toLowerCase() + ".png"}/>
                    </div>
                    <div style={{
                        position: "absolute",
                        height: '48px',
                        width: '3px',
                        backgroundColor: 'white',
                        left: (25 + props.world.countries[props.country].distributionRawMaterial / 2) + "%"
                    }}>

                    </div>
                </div>
                <br/>
                <div>
                    Ratio cible : {props.world.countries[props.country].orderDistributionRawMaterial}%
                </div>
                {
                    props.editable && <div className={"top-line"}>
                        {
                            [-5, -1, 1, 5].map(modification =>
                                <button
                                    disabled={(modification < 0 && props.world.countries[props.country].orderDistributionRawMaterial <= 0) || (modification > 0 && props.world.countries[props.country].orderDistributionRawMaterial >= 100)}
                                    onClick={() => emitOrderDistributionRawMaterial(modification)}
                                    key={modification}
                                    style={{marginLeft: '24px'}}
                                    className={"small-button"}>
                                    {modification > 0 ? '+' : ''}{modification}
                                </button>
                            )
                        }
                    </div>
                }
            </div>
            <div className={"production-bar"}>
                <div className={"consumption-item"}
                     onClick={() => setSelectedProductionResource(props.world.countries[props.country].rawMaterial1)}
                     style={{
                         background: selectedProductionResource === props.world.countries[props.country].rawMaterial1 ?
                             'linear-gradient(180deg, #0a53be 0%, #191b31 100%' : 'rgba(0,0,0,0.3)'
                     }}>
                    <img style={{height: '50px', width: 'auto'}}
                         src={"/public/anniv/2024/resources/" + props.world.countries[props.country].rawMaterial1.toLowerCase() + ".png"}/>
                    <div style={{
                        marginRight: "25px",
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }}>
                        <div>{props.world.countries[props.country].rawMaterial1}</div>
                        <div
                            style={{fontSize: '24px'}}>{props.world.countries[props.country].nbRawMaterialProducted1}</div>
                    </div>
                </div>
                <div className={"consumption-item"}
                     onClick={() => setSelectedProductionResource(props.world.countries[props.country].rawMaterial2)}
                     style={{
                         background: selectedProductionResource === props.world.countries[props.country].rawMaterial2 ?
                             'linear-gradient(180deg, #0a53be 0%, #191b31 100%' : 'rgba(0,0,0,0.3)'
                     }}>
                    <img style={{height: '50px', width: 'auto'}}
                         src={"/public/anniv/2024/resources/" + props.world.countries[props.country].rawMaterial2.toLowerCase() + ".png"}/>
                    <div style={{
                        marginRight: "25px",
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }}>
                        <div>{props.world.countries[props.country].rawMaterial2}</div>
                        <div
                            style={{fontSize: '24px'}}>{props.world.countries[props.country].nbRawMaterialProducted2}</div>
                    </div>
                </div>
            </div>

            <div className={"production-bar"}>
                <RawMaterialBlock
                    world={props.world}
                    editable={props.editable}
                    emitOrderCountry={(newPrice) => {
                        const newOrder = {
                            countryName: props.country,
                            playerName: props.player
                        };
                        const key = props.world.countries[props.country].rawMaterial1 === selectedProductionResource ? 'orderRawMaterial1Price' : 'orderRawMaterial2Price';
                        newOrder[key] = newPrice;
                        props.emitOrderCountry(newOrder)
                    }}
                    setNewPrice={(newPrice) => {
                        const key = props.world.countries[props.country].rawMaterial1 === selectedProductionResource ? 'orderRawMaterial1Price' : 'orderRawMaterial2Price';
                        props.world.countries[props.country][key] = newPrice;
                    }}
                    rawMaterial={selectedProductionResource}
                    rawMaterialPrice={props.world.countries[props.country][selectedProductionResource === props.world.countries[props.country].rawMaterial1 ? 'rawMaterial1Price' : 'rawMaterial2Price']}
                    orderRawMaterialPrice={props.world.countries[props.country][props.world.countries[props.country].rawMaterial1 === selectedProductionResource ? 'orderRawMaterial1Price' : 'orderRawMaterial2Price']}
                >

                </RawMaterialBlock>
            </div>
            <BuyersBlock world={props.world} rawMaterial={selectedProductionResource}
                         country={props.country}/>

        </div>
    </div>
}
