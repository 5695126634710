import React, {useState} from "react";
import {selectIsInGame, selectPlayer} from "../../../store/player/selectors";
import {selectIsLoggedIn, selectSessionUser} from "../../../store/authentication/selectors";
import axios from "axios";
import constants from "../../../config/constants";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

import "./LandingPage.css";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions/CardActions";
import Card from "@mui/material/Card";
import {getAlertInGame} from "../../utils/AlertInGame";

export const LandingPage = () => {

        const [showAlertCheck, setShowAlertCheck] = useState(true);
        const isLoggedIn = useSelector(selectIsLoggedIn);
        const user = useSelector(selectSessionUser);
        const isInGame = useSelector(selectIsInGame);
        const player = useSelector(selectPlayer);

        let history = useHistory();

        function resendCheckEmail() {
            axios.post(constants.api.basePath + "/userinfo/resentVerificationEmail", {
                email: user.email,
            }).then(
                response => {
                }
            ).catch(error => {
                console.log("what ?");
                console.log(error);
            });
            setShowAlertCheck(false);
        }

        function isIOS() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
        }

        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const isWindows = navigator.platform.toUpperCase().indexOf('WIN') >= 0;

        let mobile = window.innerWidth < 700;
        let fontSize = (window.innerWidth / (window.innerWidth > 1600 ? 1000 : 700) + (mobile ? window.innerWidth / 1300 : 0)) + "em";
        return (
            <div style={{color: "#FFF", fontSize}} className={"host"}>
                {
                    user.email && !user.verifiedEmail && showAlertCheck &&
                    (!user.lastVerifyEmail || ((new Date() - user.lastVerifyEmail) > 1000 * 3600 * 24)) ?
                        <Card style={{
                            margin: "2vh",
                        }}>
                            <CardContent>
                                Your email is still not verified
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={resendCheckEmail} variant="contained">
                                    Resend
                                </Button>
                            </CardActions>
                        </Card> :
                        undefined
                }
                {
                    getAlertInGame(isInGame, history)
                }
                <div className={"interlude-text"}>
                    <h1>Welcome Games</h1>
                    <div>
                        Crafting exquisite gaming experiences for players worldwide
                        <br/>
                        <img style={{
                            height: mobile ? "15vh" : "20vh",
                            margin: "1vh 0"
                        }} src="../../public/images/icons/large_icon.png"/>
                        <br/>
                        Explore our independent video games, where creativity meets innovation. Dive into captivating worlds
                        and immersive gameplay designed by people with passion.
                    </div>
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        style={{
                            height: "auto",
                            width: "100%"
                        }}
                        alt={"RTS Ultra Myth"}
                        src={"../../public/images/wallpaper-kz.png"}/>
                    <div style={{
                        position: "absolute",
                        top: mobile ? "40%" : "35%",
                        right: "10%",
                        textAlign: "right"
                    }}>
                        <h1 style={{
                            fontSize: mobile ? "120%" : "150%"
                        }}>
                            DEFY THE GODS
                        </h1>
                        <div style={{
                            fontSize: mobile ? "80%" : "100%",
                            marginTop: mobile ? "0.5vh" : "2vh",
                            marginBottom: mobile ? "2vh" : "3vh"
                        }}>
                            {
                                !mobile ?
                                    <span>
                                        Face Greek monsters and gods in this<br/>
                                        tactical deck-building game!
                                    </span> :
                                    <span>
                                    Face Greek monsters<br/>
                                    in this epic tactical<br/>
                                    deck-building game!</span>
                            }
                        </div>
                        {
                            !mobile ?
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyItem: "center",
                                    justifyContent: "space-between",
                                    fontSize: "100%"
                                }}>
                                    <div style={{flex: 1}}/>
                                    <div style={{flex: 1}}/>
                                    <Button variant="contained"
                                            onClick={() => history.push("/killing-zeus")}>
                                        Discover
                                    </Button>
                                    <div style={{flex: 1}}/>
                                </div> : undefined
                        }
                    </div>
                </div>
                <div className={"interlude-text"}>
                    <h1>Killing Zeus</h1>
                    <div>
                        Home-made mobile tactical rogue-like deck-building game involving 1 year of work.
                        <br/>
                        Build your deck and unleash powerful combos harnessing the powers of the 12 Olympians Gods against
                        horde of enemies.
                        <br/>
                        4 game modes and an infinite number of possibilities in this hard but super-fun game!
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItem: "center",
                        justifyContent: "space-between",
                        paddingTop: "3vh",
                        paddingBottom: "3vh"
                    }}>
                        <div style={{flex: 1}}/>
                        <Button variant="contained"
                                onClick={() => history.push("/killing-zeus")}>
                            Discover
                        </Button>
                        <div style={{flex: 1}}/>
                        {
                            isIOS() ?
                                <Button variant="contained"
                                        href="https://apps.apple.com/fr/app/killing-zeus/id6445866258">
                                    App Store
                                </Button> :
                                <Button variant="contained"
                                        href="https://play.google.com/store/apps/details?id=com.welcomegames.main&hl=en&gl=US">
                                    Google Play
                                </Button>
                        }
                        <div style={{flex: 1}}/>
                    </div>
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        style={{
                            height: "auto",
                            width: "100%"
                        }}
                        alt={"RTS Ultra Myth"}
                        src={"../../public/images/wallpaper-rts.png"}/>
                    <div style={{
                        position: "absolute",
                        top: mobile ? "40%" : "35%",
                        paddingLeft: "10%"
                    }}>
                        <h1 style={{
                            fontSize: mobile ? "120%" : "150%"
                        }}>
                            JOIN THE FIGHT
                        </h1>
                        <div style={{
                            fontSize: mobile ? "80%" : "100%",
                            marginTop: mobile ? "0.5vh" : "2vh",
                            marginBottom: mobile ? "2vh" : "3vh"
                        }}>
                            {
                                !mobile ?
                                    <span>
                                        Play RTS Ultra Myth, our indie Fast-RTS,<br/>
                                        against players from all over the world!
                                    </span> :
                                    <span>
                                    Play RTS Ultra Myth, our indie<br/>
                                    Fast-RTS, against players from<br/>
                                    all over the world!</span>
                            }
                        </div>
                        {
                            !mobile ?
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyItem: "center",
                                    justifyContent: "space-between",
                                    fontSize: "100%"
                                }}>
                                    <div style={{flex: 1}}/>
                                    <Button variant="contained"
                                            onClick={() => history.push("/ultra-myth")}>
                                        Discover
                                    </Button>
                                    <div style={{flex: 1}}/>
                                    {
                                        isMac ?
                                            <Button variant="contained"
                                                    href="https://welcomegames.fr/downloads/WelcomeGames.dmg"
                                                    download>
                                                Download
                                            </Button> : undefined
                                    }
                                    {
                                        isWindows ?
                                            <Button variant="contained"
                                                    href="https://welcomegames.fr/downloads/WelcomeGames.msi"
                                                    download>
                                                Download
                                            </Button> : undefined
                                    }
                                    <div style={{flex: 1}}/>
                                </div> : undefined
                        }
                    </div>
                </div>
                <div className={"interlude-text"}>
                    <h1>RTS Ultra Myth</h1>
                    <div>
                        Computer-based game introducing a new genre : the Fast-RTS
                        <br/>
                        Micro-manage up to 5 units, casting spells and taking objectives on the map.
                        Create new units as the battle goes to take the advantage.
                        <br/>
                        Fight with friends on the online network in ELO-ranked matches
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItem: "center",
                        justifyContent: "space-between",
                        paddingTop: "3vh",
                        paddingBottom: "3vh"
                    }}>
                        <div style={{flex: 1}}/>
                        <Button variant="contained"
                                onClick={() => history.push("/ultra-myth")}>
                            Discover
                        </Button>
                        {
                            isMac || isWindows ?
                                <div style={{flex: 1}}/> : undefined
                        }
                        {
                            isMac ?
                                <Button variant="contained"
                                        href="https://welcomegames.fr/downloads/WelcomeGames.dmg"
                                        download>
                                    Download
                                </Button> : undefined
                        }
                        {
                            isWindows ?
                                <Button variant="contained"
                                        href="https://welcomegames.fr/downloads/WelcomeGames.msi"
                                        download>
                                    Download
                                </Button> : undefined
                        }
                        <div style={{flex: 1}}/>
                    </div>
                </div>
            </div>
        );

    }
;

export default LandingPage;
