import React from "react";

export const PayMoney = (props) => {

    function getMoneyComponent(name, amount) {
        return <div style={{
            display: "flex", height: '90%', width: '40px',
            margin: '5% 0 5% 18px',
            padding: '0 12px',
            flexDirection: "column",
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '12px',
            justifyContent: 'center', alignItems: 'center'
        }}>
            <div style={{flex: 1, width: '100%', height: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {amount}&nbsp;<img src={"../../../public/7wonders/images/piece.png"} style={{width: "80%", height: "auto"}}/>
            </div>
            <div style={{flex: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {name}
            </div>
        </div>
    }

    return <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            Payer
        </div>
        {
            props.payMoney.nbCoinLeft ?
                getMoneyComponent(props.payMoney.usernameLeft, props.payMoney.nbCoinLeft) :
                undefined
        }
        {
            props.payMoney.nbCoinBank ?
                getMoneyComponent("Banque", props.payMoney.nbCoinBank) :
                undefined
        }
        {
            props.payMoney.nbCoinRight ?
                getMoneyComponent(props.payMoney.usernameRight, props.payMoney.nbCoinRight):
                undefined
        }
    </div>
}
