import {
    CALLING_API,
    CALLING_API_FAILED,
    CALLING_API_SUCCEDED
} from './actions';


export const api = (state = {status:{}}, action = {}) => {
    const key = action.key;
    var nextStatus = {
        ...state.status
    };
    switch (action.type) {
        case CALLING_API:
            nextStatus[key] = {isLoading : true};
            break;
        case CALLING_API_FAILED:
            nextStatus[key] = {
                isLoading : false,
                error: action.error
            };
            break;
        case CALLING_API_SUCCEDED:
            delete nextStatus[key];
            break;
        default:
            return state;

    }
    return {
        ...state,
        status:nextStatus
    };
};

export default api;
