import React from "react";

import "./KillingZeusPage.css";

export const KillingZeusPage = () => {


        let mobile = window.innerWidth < 700;
        let fontSize = (window.innerWidth / (window.innerWidth > 1600 ? 1000 : 700) + (mobile ? window.innerWidth / 1300 : 0)) + "em";
        const imageStyle = {
            width: mobile ? "100%" : "70%",
            padding: mobile ? "0" : "0 15%"
        };
        const textStyles = {
            padding: mobile ? "3vh 10%" : "10vh 10%"
        };

        const links = <div className={"button-holders"} style={{marginBottom: mobile ? "5vh" : "5vh"}}>
            <a className={"button-holder"}
               style={{
                   width: mobile ? "40vw" : "20vw",
                   height: mobile ? "15vh" : "35vh",
               }}
               href="https://apps.apple.com/fr/app/killing-zeus/id6445866258">
                <img src={"../../public/devices/link_apple.png"} alt={"apple store link"}/>
                App Store
            </a>
            <a className={"button-holder"}
               style={{
                   width: mobile ? "40vw" : "20vw",
                   height: mobile ? "15vh" : "35vh",
               }}
               href="https://play.google.com/store/apps/details?id=com.welcomegames.main&hl=en&gl=US">
                <img src={"../../public/devices/link_android.png"} alt={"google play link"}/>
                Google Play
            </a>
        </div>;


        return (
            <div style={{color: "#FFF", fontSize}} className={"host"}>
                <div className={"interlude-text"}>
                    <h1>Killing Zeus</h1>
                    <div>
                        <img style={{
                            height: mobile ? "15vh" : "20vh",
                            margin: "1vh 0"
                        }} src="../../public/killing-zeus/logo.png"/>
                        <br/>
                        Embark on a Mythical Odyssey with 'Killing Zeus'
                        <br/>
                        <br/>
                        A thrilling ancient Greek adventure on Apple and Play Stores! Immerse yourself in an epic
                        journey through the realms of gods and monsters, where strategic
                        prowess meets ancient mythology. Craft your destiny through deck-building
                        rogue-like strategies and engage in tactical gameplay.
                    </div>
                </div>
                {links}
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/killing-zeus/screenshot_1.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Join the Pantheon: Dive into a world steeped in Ancient Greek lore,
                    where the gods reign supreme and mythical creatures roam. Unravel the
                    rich tapestry of legends as you confront legendary beasts and encounter
                    divine beings.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/killing-zeus/screenshot_2.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Strategic Deck-Building: Forge your destiny through strategic deck-building
                    mechanics, meticulously selecting cards to shape your battles.
                    Every decision counts as you assemble your arsenal to conquer the
                    challenges laid out by the gods.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/killing-zeus/screenshot_3.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Rogue-like Adventure: Traverse treacherous landscapes filled with surprises
                    and perils at every turn. Embrace the unpredictability of a rogue-like
                    experience where each playthrough brings new trials and opportunities.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/killing-zeus/screenshot_4.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Tactical Gameplay: Engage in tactical battles that demand cunning strategies
                    and quick thinking. Master the art of combat as you confront formidable foes
                    and unravel the mysteries of ancient Greece.
                </div>
                <div style={{
                    width: "100%",
                    position: "relative"
                }}>
                    <img
                        className={"images"}
                        style={imageStyle}
                        alt={"Killing Zeus"}
                        src={"../../public/killing-zeus/screenshot_5.png"}/>
                </div>
                <div className={"interlude-text pause-text"} style={textStyles}>
                    Experience 'Killing Zeus,' where the realms of ancient Greece, gods, monsters,
                    and deck-building strategies intertwine to create a truly immersive and challenging
                    gameplay experience. Get ready to rewrite history and carve your name
                    among the legends!
                    <br/>
                    <br/>
                    {links}
                    <br/>
                    <img style={{
                        height: mobile ? "15vh" : "20vh",
                        margin: "1vh 0"
                    }} src="../../public/killing-zeus/logo.png"/>
                </div>
            </div>
        );

    }
;

export default KillingZeusPage;
