import React from "react";
import {dicCities} from "../Utils";

export default class ConsultCities extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }

    getKeys(){
        return Object.keys(this.props.cities).sort((c1, c2) => this.props.cities[c1].value < this.props.cities[c2].value ? -1: 1);
    }

    render() {
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <h4>Vous pouvez relier ces villes à votre réseau</h4>

            <div style={{
                width: "100%",
                marginTop: "25px",
                display: "flex",
                flexDirection: "row",
            }}>
                <div style={{flex: 1}}>
                    <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                        {
                            this.getKeys().slice(0, Math.floor(Object.keys(this.props.cities).length / 2)).map(c => <div
                                key={c}
                                style={{
                                    height: "40px",
                                    borderRadius: "5px",
                                    backgroundColor: dicCities[c].color,
                                    color: "white",
                                    marginBottom: "5px",
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <div style={{
                                    flex: 4,
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    paddingLeft: "12px"
                                }}>
                                    {dicCities[c].name}
                                </div>
                                <div style={{
                                    flex: 1,
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    paddingRight: "12px"
                                }}>
                                    {this.props.cities[c].value}
                                </div>

                            </div>)
                        }
                    </div>
                </div>
                <div style={{flex: 1}}>
                    <div style={{paddingLeft: "5%", paddingRight: "5%"}}>
                        {
                            this.getKeys().slice(Math.floor(Object.keys(this.props.cities).length / 2), Object.keys(this.props.cities).length).map(c =>
                                <div
                                    key={c}
                                    style={{
                                        height: "40px",
                                        borderRadius: "5px",
                                        backgroundColor: dicCities[c].color,
                                        color: "white",
                                        marginBottom: "5px",
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    <div style={{
                                        flex: 4,
                                        display: "flex",
                                        height: "100%",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        paddingLeft: "12px"
                                    }}>
                                        {dicCities[c].name}
                                    </div>
                                    <div style={{
                                        flex: 1,
                                        display: "flex",
                                        height: "100%",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        paddingRight: "12px"
                                    }}>
                                        {this.props.cities[c].value}
                                    </div>

                                </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}
