import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

export const StateIntro = (props) => {

    const [state, setState] = useState({color: {r: 1, g: 1, b: 1, a: 1}, position: -1});

    const colors = [
        {r: 0.65, g: 0.05, b: 0.05, a: 1},
        {r: 0.05, g: 0.65, b: 0.05, a: 1},
        {r: 0.15, g: 0.35, b: 0.95, a: 1},
        {r: 0.65, g: 0.65, b: 0.05, a: 1},
        {r: 0.65, g: 0.05, b: 0.65, a: 1},
        {r: 0.05, g: 0.65, b: 0.65, a: 1}
    ];

    useEffect((args) => {
        console.log("effect");
        emitState()
    }, [state]);


    function getPlayer() {
        return props.state.networkPlayers.filter(np => np.userId === props.userId)[0];
    }


    function emitState() {
        props.socket.emit("id", {
            type: "PLAYER",
            gameId: props.gameId,
            userId: "" + props.userId,
            username: props.username,
            color: state.color,
            position: state.position
        })
    }

    function getHTMLcolor(index) {
        return "rgb(" + 255 * colors[index].r + "," + 255 * colors[index].g + "," + 255 * colors[index].b + ")";
    }

    function getPlayerNameByColor(index) {
        for (let player of props.state.networkPlayers) {
            if (areColorEquals(player.color, colors[index])) {
                return player.username;
            }
        }
        return ["RED", "GREEN", "BLUE", "YELLOW", "MAGENTA", "CYAN"][index];
    }

    function getPlayerNameByPosition(index) {
        for (let player of props.state.networkPlayers) {
            if (player.position === index) {
                return player.username;
            }
        }
        return index;
    }

    function getHTMLColorBorder(index) {
        return "solid 5px " + (getPlayer() && areColorEquals(getPlayer().color, colors[index]) ? "#FFF" : (isColorTaken(colors[index]) ? "#999" : "#222"));
    }

    function areColorEquals(color1, color2) {
        if (!color1 || !color2) {
            return false;
        }
        return color1.r === color2.r && color1.g === color2.g && color1.b === color2.b;
    }

    function isColorTaken(color) {
        for (let c of props.state.takenColor) {
            if (areColorEquals(c, color)) {
                return true;
            }
        }
        return false;
    }

    function isPositionTaken(position) {
        for (let p of props.state.takenPosition) {
            if (position === p) {
                return true;
            }
        }
        return false;
    }

    return <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
        <div style={{
            width: "100%", height: "100px", di1splay: "flex", flexDirection: "column", padding: "10px"
        }}>
            <h3>{props.username}</h3>
            <h4>Choisissez votre position</h4>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                {
                    [0, 1, 2, 3, 4, 5].map(i => <div
                        key={"position " + i}
                        onClick={() => {
                            if (!isPositionTaken(i)) {
                                setState(state => ({...state, position: i}));
                            }
                        }}
                        style={{
                            height: '50px',
                            width: '10%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: "5px",
                            border: "solid 5px " + (getPlayer() && getPlayer().position === i) ? "#FFF" : (isPositionTaken(i) ? "#999" : "#222"),
                            backgroundColor: (getPlayer() && getPlayer().position === i) ? "#33F" : (isPositionTaken(i) ? "#999" : "#222"),
                            color: "#FFF"
                        }}>
                        {getPlayerNameByPosition(i)}
                    </div>)
                }
            </div>
            <h4>Choisissez votre couleur</h4>
            {
                colors.map((color, i) => <div
                    key={color.r + " " + color.g + " " + color.b}
                    onClick={() => {
                        if (!isColorTaken(colors[i])) {
                            setState(state => ({...state, color: colors[i]}));
                        }
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "left",
                        width: "40%",
                        height: "100%",
                        borderRadius: "5px",
                        border: getHTMLColorBorder(i),
                        backgroundColor: getHTMLcolor(i),
                        color: "#FFF"
                    }}>
                    {getPlayerNameByColor(i)}
                </div>)
            }
        </div>
    </Box>


}
