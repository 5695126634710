import React from "react";

export default class ConsultIncome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return <div style={{
            marginTop: "0", marginLeft: "5%", marginRight: "5%", display: "block",
            width: "90%", color: "#DDD"
        }}>
            <h4>Revenus en fonction du nombre de maisons alimentées</h4>
            <div style={{
                width: "80%",
                height: "50vh",
                marginLeft: "10%",
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                marginTop: "5%",
                whiteSpace: "nowrap"
            }}>
                <div style={{
                    flex: 1,
                    display: "flex",
                    backgroundColor: "black",
                    flexDirection: "column",
                    height: "100%"
                }}>
                    <div style={{
                        flex: 1,
                        border: "1px solid gray",
                        display: "flex",
                        fontWieght: "bold",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <img
                            style={{height: "5vh", width: "auto"}}
                            alt={"maison"} src={"../../../../public/megawatt/images/house.png"}/>
                    </div>
                    {
                        [...Array(Object.keys(this.props.income).length).keys()].map(nb =>
                            <div style={{
                                flex: 1,
                                border: "1px solid gray",
                                display: "flex",
                                backgroundColor: "black",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                {nb}
                            </div>
                        )
                    }
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%"
                    }}>
                    <div style={{
                        flex: 1,
                        border: "1px solid gray",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <img
                            style={{height: "5vh", width: "auto"}}
                            alt={"money"} src={"../../../../public/megawatt/images/money.png"}/>
                    </div>
                    {
                        [...Array(Object.keys(this.props.income).length).keys()].map(nb =>
                            <div style={{
                                flex: 1,
                                border: "1px solid gray",
                                display: "flex",
                                backgroundColor: "black",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                {this.props.income[nb]}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    }
}
