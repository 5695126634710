
export const getToken = () => {
    return {
        email: window.localStorage.getItem("email"),
        password: window.localStorage.getItem("password")
    }
};

export const setToken = (credentials) => {
    window.localStorage.setItem("email", credentials.email)
    window.localStorage.setItem("password", credentials.password);
};

export const removeToken = () => {
    window.localStorage.removeItem("email")
    window.localStorage.removeItem("password");
};

export const isTokenPresent = () => {
    let token = getToken();
    return (token.email ? true : false);
};
